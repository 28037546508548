import React from "react";
import { Link } from "react-router-dom";
import { Typography, Grid, Button } from "@mui/material";
import { ButtonText, StandardButton } from "../styles/StandardButton";

export const ErrorPage = ({ error }) => {
  const { message, code } = error;
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      textAlign="center"
      style={{ height: "100vh" }}
      rowSpacing={7}
    >
      <Grid item xs={12}>
        <Typography variant="h1" component="h1" fontWeight="bolder">
          {code}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" fontWeight="bolder">
          {message}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StandardButton
          variant="contained"
          component={Link}
          to={"http://yas.ymph.net/yas/home.php"}
        >
         <ButtonText>Go to Home Page</ButtonText> 
        </StandardButton>
      </Grid>
    </Grid>
  );
};
