/* eslint-disable no-throw-literal */
import { checkUserFormAccess, getFormDesign, updateFormFavorite } from "../api";
import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  RESET_INITIAL_STATE,
} from "../constants";

export const formInitialState = {
  loading: false,
  error: false,
  errorType: "",
  errorMessage: "",
  success: false,
  successMessage: "",
  pageForms: [],
  result: {},
  resultType: "",
};

export const formActions = {
  updateFavorite: (data) => async (dispatch) => {
    dispatch({ type: API_PENDING });
    const payload = {
      formId: data.id,
      userId: localStorage.getItem("id"),
      favorite: !data.favorite,
    };

    try {
      await updateFormFavorite(payload).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Succesfully Updated Form Favorite`,
            data: response?.data?.object,
            type: "UPDATE_FORM_FAVORITE",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  checkFormDesign: (data) => async (dispatch) => {
    try {
      await getFormDesign(data).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
        dispatch({
          type: API_SUCCESS,
          payload: {
            message: `Succesfully Get Form Design`,
            data: response?.data?.object,
            type: "GET_FORM_DESIGN",
          },
        });
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  checkFormAccess: (formDesignId, checkFormDesign) => async (dispatch) => {
    try {
      const params = {
        formDesignId: formDesignId,
      };
      await checkUserFormAccess(params).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.message === "HAS ACCESS") {
          checkFormDesign(formDesignId);
        } else if (response.data.message === "FORBIDDEN ACCESS") {
          dispatch({
            type: API_FAILURE,
            payload: {
              message: `User has no access to this form.`,
              data: response?.data?.object,
              type: "GET_FORM_DESIGN",
            },
          });
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
};

export const formReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case API_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorType: "",
        errorMessage: "",
        success: false,
        successMessage: "",
        result: {},
      };
    case API_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorType: payload.type,
        errorMessage: payload.message,
        result: {},
      };
    case API_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        successMessage: payload.message,
        result: payload.data,
        resultType: payload.type,
      };
    case RESET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        successMessage: "",
        errorMessage: "",
        result: payload?.data,
        resultType: payload?.type,
      };
    default:
      return state;
  }
};
