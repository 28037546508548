import { useFormSubmitAnswerContext } from "../../../../context";
import "antd/dist/antd.less";
import React, { useEffect, useMemo, useState } from "react";
import { createForm } from "@formily/core";
import { Form, FormItem, DatePicker, FormButtonGroup } from "@formily/antd";
import { Alert } from "antd";
import { useSchemaField } from "../../../../utilities";
import { MessageDialog, PageComponent } from "../../../../components";
import { Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import { RESET_INITIAL_STATE } from "../../../../constants";
import { useAlert } from "../../../../utilities";
import { StandardButton, ButtonText } from "../../../../components";
import { onFieldValueChange, onFieldReact } from "@formily/core";

const formatDateTimeToYMDHM = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};
export const FormSubmitNewAnswer = () => {
  const { SchemaField } = useSchemaField();
  const { handleAlertMessage, ...alertRest } = useAlert();

  const form = useMemo(
    () =>
      createForm({
        effects: () => {
          onFieldValueChange("working_status", (field) => {
            handleWorkStatusChange(field);
          });
          onFieldReact("datePicker", (field) => {
            field.required = showDatePicker;
          });
        },
      }),
    []
  );

  const {
    formSchema,
    formDesignData,
    dispatchFormSubmitAnswer,
    actions: { submitNewFormAnswer },
    formAnswerState,
  } = useFormSubmitAnswerContext();

  const [showDialog, setDialog] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [workingStatus, setWorkingStatus] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userId = localStorage.getItem("id");
  const { formDesignId } = useParams();

  const formProps = formDesignData?.schema?.form;
  const handleGoBack = () => {
    window.history.back();
  };
  const formName = formDesignData?.form?.name;

  const [selectedDateTime, setSelectedDateTime] = useState();
  const [alertMessage, setAlertMessage] = useState(null);

  const handleSuccess = () => {
    handleShowDialog();
    dispatchFormSubmitAnswer({ type: RESET_INITIAL_STATE });
    setIsSubmitting(false);
  };

  const handleError = () => {
    handleAlertMessage(formAnswerState.errorMessage, "error");
    dispatchFormSubmitAnswer({ type: RESET_INITIAL_STATE });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (formAnswerState.successMessage) {
      handleSuccess();
    } else if (formAnswerState.errorMessage) {
      handleError();
    }
  }, [formAnswerState.successMessage, formAnswerState.errorMessage]);

  useEffect(() => {
    form.setEffects(() => {
      onFieldValueChange("working_status", (field) => {
        handleWorkStatusChange(field);
      });

      onFieldReact("datePicker", (field) => {
        field.required = showDatePicker;
      });
    });
  }, [form]);

  const handleWorkStatusChange = (field) => {
    setShowDatePicker(field.value === "W");
    setWorkingStatus(field.value);
    setAlertMessage(null);
    if (field.value !== "W") {
      form.setValues({ datePicker: undefined });
    }
  };

  const onFinish = (values) => {
    let currentDateTime;
    if (workingStatus === "W") {
      if (!selectedDateTime) {
        setIsSubmitting(false);
        setAlertMessage("The date field is required");
        return;
      } else {
        currentDateTime = new Date(selectedDateTime).toISOString();
      }
    } else if (workingStatus === "NW") {
      currentDateTime = new Date(values.date).toISOString();
    } else {
      if (!selectedDateTime) {
        setIsSubmitting(false);
        setAlertMessage("The date field is required");
        return;
      } else {
        currentDateTime = new Date(selectedDateTime).toISOString();
      }
    }

    const newValues = { ...values, date: currentDateTime };
    submitNewFormAnswer({
      formDesignId,
      userId,
      answer: newValues,
      dateCreated: currentDateTime,
    });
  };

  const onFinishFailed = (errorInfo) => {
    setAlertMessage("Please fill in the required fields");
    setIsSubmitting(false);
    console.log("Failed:", errorInfo);
  };

  const handleDateTimeChange = (value) => {
    setSelectedDateTime(value);
    setAlertMessage(null);
  };

  const handleShowDialog = () => {
    setDialog(true);
  };

  const handleCloseDialog = () => {
    setDialog(false);
    window.location.reload(false);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    await onFinish(values);
  };

  return (
    <PageComponent isLoading={false}>
      <Box sx={{ width: "100%", height: "100%", marginTop: "2%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "50px", md: "100px", lg: "150px" },
            marginBottom: "75px",
          }}
        >
          <ArrowBackIcon onClick={handleGoBack} sx={{ cursor: "pointer" }} />
          <Typography
            variant="h5"
            sx={{
              marginLeft: "10px",
              fontWeight: "bold",
            }}
          >
            {formName}
          </Typography>
        </Box>

        {alertMessage && (
          <Alert
            message={alertMessage}
            type="error"
            showIcon
            style={{ marginBottom: "0px", width: "50%", margin: "0 auto" }}
          />
        )}

        {formSchema != null ? (
          <Form
            {...formProps}
            form={form}
            onAutoSubmitFailed={onFinishFailed}
            onAutoSubmit={handleSubmit}
            style={{ flex: 1 }}
          >
            {showDatePicker && (
              <FormItem
                label={
                  <span>
                    <span style={{ color: "red" }}>*</span> Date and Time
                  </span>
                }
                {...formProps}
                name="datePicker"
                required
              >
                <DatePicker
                  value={selectedDateTime}
                  showTime
                  onChange={handleDateTimeChange}
                />
              </FormItem>
            )}

            <SchemaField schema={formSchema} />

            <FormButtonGroup.FormItem>
              <StandardButton type="submit" disabled={isSubmitting}>
                <ButtonText>Submit</ButtonText>
              </StandardButton>
            </FormButtonGroup.FormItem>
          </Form>
        ) : (
          <Box>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Form is Currently Empty
            </Typography>
            <br></br>
            <Typography variant="body1">
              It looks like this form has no content. Please coordinate with the
              admin to create or edit the form in the Forms Builder app before
              proceeding.
            </Typography>
          </Box>
        )}
      </Box>
      <MessageDialog
        open={showDialog}
        onClose={handleCloseDialog}
        title="Successfully submitted form answer"
      />
    </PageComponent>
  );
};
