import { Autocomplete, TextField, styled } from "@mui/material";
import { useState } from "react";

const AutoCompleteFilter = styled(Autocomplete)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  maxWidth: "150px",
  "@media (max-width: 600px)": {
    maxWidth: "100%",
  },
}));

export const CustomVersion = ({ data, defaultValue, handleChange }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChangeFormVersion = (event, value) => {
    setValue(value);
    handleChange(value);
  };

  return (
    <AutoCompleteFilter
      disablePortal
      disableClearable={true}
      value={value}
      id="combo-box-demo"
      options={data}
      size="small"
      getOptionLabel={(option) => option.version}
      onChange={handleChangeFormVersion}
      renderInput={(params) => <TextField {...params} label="Version" />}
    />
  );
};
