import { Upload as FormilyUpload } from "@formily/antd";
import { createBehavior, createResource } from "@designable/core";
import { newDeleteFile, uploadFile } from "../../../../api";
import { createSchemaField } from "@formily/react";
import { AllSchemas } from "../../schemas";
import { AllLocales } from "../../locales";
import { useEffect, useState } from "react";

const BACKEND_DEV_URL = process.env.REACT_APP_FORMS_BUILDER_SERVICE;

export const Upload = (props) => {
  const [fileList, setFileList] = useState(props.value ?? []);

  const handleUploadFile = async (file) => {
    if (file[file.length - 1]?.status === "uploading") {
      const data = new FormData();

      data.append("file", file[file.length - 1].originFileObj);
      const response = await uploadFile(props.action, data);

      if (response) {
        const newFile = {
          uid: response?.data?.object.id,
          url: response?.data?.object?.url,
          thumbUrl: response?.data?.object?.thumbUrl,
          name: response?.data?.object?.name,
        };
        // Create a new array to avoid mutating state directly
        const newFileList = [...fileList, newFile];
        props.onChange(newFileList); // Call the prop to notify of changes
        setFileList(newFileList);
      }
    }
  };

  const handleRemoveFile = async (file) => {
    const updatedFileList = fileList.filter((data) => data.uid !== file.uid);

    const response = await newDeleteFile(props.delete, file.name);
    if (response) {
      props.onChange(updatedFileList);
      setFileList(updatedFileList);
    }
  };
  useEffect(() => {
    setFileList(props.value ?? []);
  }, [props.value]);
  return (
    <FormilyUpload
      {...props}
      name="file"
      fileList={fileList}
      onChange={handleUploadFile}
      onRemove={handleRemoveFile}
    />
  );
};

Upload.Behavior = createBehavior({
  name: "Upload",
  extends: ["Field"],
  selector: (node) => node.props["x-component"] === "Upload",
  designerProps: {
    propsSchema: createSchemaField(AllSchemas.Upload),
  },
  designerLocales: AllLocales.Upload,
});

Upload.Resource = createResource({
  icon: "UploadSource",
  elements: [
    {
      componentName: "Field",
      props: {
        type: "Array<object>",
        title: "Upload",
        "x-decorator": "FormItem",
        "x-component": "Upload",
        "x-component-props": {
          textContent: "Upload",
          action: `${BACKEND_DEV_URL}/files/upload`,
          delete: `${BACKEND_DEV_URL}/files/delete-file`,
        },
      },
    },
  ],
});
