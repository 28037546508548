import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Typography,
  styled,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StandardButton, ButtonText } from "../../../components";

// Styled Components
const ModalContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 570,
  height: 410,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "14.5%",
    backgroundColor: "#379F86",
    zIndex: -1,
  },
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  padding: theme.spacing(0, 0, 2),
  color: "#ffffff",
  width: "100%",
  textAlign: "left",
}));

const CloseIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#379F86",
  borderRadius: "50%",
  width: 36,
  height: 36,
  cursor: "pointer",
}));

const UpdateUserMaintenance = ({ open, handleClose, userData }) => {
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (open) {
      setStatus(userData.status || "");
    }
  }, [open, userData]);

  const handleSubmit = () => {
    console.log("Status updated to:", status);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer>
        <ModalTitle>Update User</ModalTitle>
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon style={{ fontSize: 24, cursor: "pointer", color: "#ffffff" }} />
        </CloseIconWrapper>
        <TextField
          label="Employee ID"
          // value={userData.employeeID || ""} // Ensuring value is not null
          disabled
          fullWidth
          margin="normal"
          InputProps={{ style: { borderColor: "#379F86" } }}
        />
        <TextField
          label="Employee Name"
          // value={userData.employeeName || ""} // Ensuring value is not null
          disabled
          fullWidth
          margin="normal"
          InputProps={{ style: { borderColor: "#379F86" } }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status *</InputLabel>
          <Select
            labelId="status-label"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
        <StandardButton variant="contained" onClick={handleSubmit}>
          <ButtonText>Submit</ButtonText>
        </StandardButton>
      </ModalContainer>
    </Modal>
  );
};

export default UpdateUserMaintenance;
