import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useReducer,
} from "react";
import {
  formApproverActions,
  formApproverInitialState,
  formApproverReducer,
} from "../reducer/FormMaintenanceReducer";
import {
  useFormsMaintenanceData,
  useMaintenanceFormReportsData,
  useUserListData,
} from "../utilities";
import { RESET_INITIAL_STATE } from "../constants";

const FormMaintenanceContext = createContext();

export const useFormMaintenanceContext = () => {
  return useContext(FormMaintenanceContext);
};

export const FormMaintenanceContextProvider = ({ children }) => {
  const { usersData } = useUserListData("users/painting-forms-dropdown");

  const usersList = usersData
    ?.map(({ id, employeeId, fullname }) => ({
      id,
      employeeId,
      fullName: fullname,
    }))
    ?.sort((a, b) => a.fullName.localeCompare(b.fullName));
  const {
    formsData,
    formsKeyword,
    formsPageNumber,
    formsPageSize,
    optimizedSearchForms,
    mutateForms,
  } = useFormsMaintenanceData("painting-portal/search-forms-approver");

  const {
    maintenanceReportsData,
    maintenanceFormReports,
    searchKeyword,
    reportsPageNumber,
    reportsPageSize,
    optimizedSearchFormReports,
    sort,
    mutateMaintenanceFormReports,
  } = useMaintenanceFormReportsData("report/search-form-reports");

  useEffect(() => {
    mutateForms();
    dispatchFormApprover({ type: RESET_INITIAL_STATE });
  }, [formsKeyword, formsPageNumber, formsPageSize]);

  useEffect(() => {
    mutateMaintenanceFormReports();
    dispatchFormApprover({ type: RESET_INITIAL_STATE });
  }, [reportsPageNumber, reportsPageSize, searchKeyword]);

  const [formApproverState, dispatchFormApprover] = useReducer(
    formApproverReducer,
    {
      ...formApproverInitialState,
    }
  );

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    Object.keys(formApproverActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          formApproverActions[key](...args)(
            dispatchFormApprover,
            stateRef.current
          );
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = formApproverState;
  }, [formApproverState]);

  const value = useMemo(
    () => ({
      formApproverState,
      actions,
      dispatchFormApprover,
      formsData,
      formsKeyword,
      formsPageNumber,
      formsPageSize,
      optimizedSearchForms,
      mutateForms,
      maintenanceReportsData,
      maintenanceFormReports,
      mutateMaintenanceFormReports,
      reportsPageNumber,
      searchKeyword,
      reportsPageSize,
      optimizedSearchFormReports,
      sort,
      usersList,
    }),
    [
      formApproverState,
      actions,
      dispatchFormApprover,
      formsData,
      formsKeyword,
      formsPageNumber,
      formsPageSize,
      optimizedSearchForms,
      mutateForms,
      maintenanceReportsData,
      maintenanceFormReports,
      mutateMaintenanceFormReports,
      reportsPageNumber,
      searchKeyword,
      reportsPageSize,
      optimizedSearchFormReports,
      sort,
      usersList,
    ]
  );

  return (
    <FormMaintenanceContext.Provider value={value}>
      {children}
    </FormMaintenanceContext.Provider>
  );
};
