export const FormsColumnSchema = [
  { id: "tf.name", label: "Form Name", hasSorting: false },
  { id: "tags ", label: "Tags ", hasSorting: false },
  { id: "forApproval", label: "For Approval", hasSorting: false },
  { id: "action ", label: "Action ", hasSorting: false },
];

export const ReportsColumnSchema = [
  { id: 1, label: "Form Name", hasSorting: false },
  { id: 2, label: "Description", hasSorting: false },
  { id: 3, label: "Action", hasSorting: false },
];

export const FormMaintenanceColumnSchema = [
  { id: 1, label: "Form Name", hasSorting: true },
  { id: 2, label: "Tags", hasSorting: true },
  { id: 3, label: "Approver", hasSorting: true },
  { id: 4, label: "Action", hasSorting: false },
];

export const UserMaintenanceColumnSchema = [
  { id: "employee_id", label: "Employee ID", hasSorting: true },
  { id: "employee_name", label: "Employee Name", hasSorting: true },
  { id: "date_created", label: "Date Created", hasSorting: true },
  { id: "status", label: "Status", hasSorting: true },
  { id: "action", label: "Action", hasSorting: false },
];

export const FormMaintenanceReportColumnSchema = [
  { id: 1, label: "Form Name", hasSorting: false },
  { id: 2, label: "Description", hasSorting: false },
  { id: 3, label: "Checked By", hasSorting: false },
  { id: 4, label: "Approved By", hasSorting: false },
  { id: 5, label: "Action", hasSorting: false },
];

export const FormReportColumnSchema = [
  { id: 1, label: "Report Date", hasSorting: false },
  { id: 2, label: "Remarks", hasSorting: false },
  { id: 3, label: "Prepared By", hasSorting: false },
  { id: 4, label: "Status", hasSorting: false },
  { id: 5, label: "Date Registered", hasSorting: false },
  { id: 6, label: "Action", hasSorting: false },
];
