import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Tooltip,
  Grid,
} from "@mui/material";
import { Typography } from "antd";
import { CustomPagination } from "../pagination/CustomPagination";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

const TableCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "left",
  },
  fontSize: "0.90rem",
  padding: "20px 30px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:last-child": {
    width: "150px",
  },
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "left",
    width: "100px",
  },
  fontSize: ".95rem",
  fontWeight: "bold",
  height: "5px",
  textTransform: "capitalize",
  padding: "20px 30px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  "&:last-child": {
    width: "150px",
  },
}));

export const FormSubmissionTable = ({
  data,
  columnSchema,
  loading,
  pageNumber,
  handleChangePage,
  handleView,
  onRowStatusChange,
  filteredData,
  selectedRowsStatus,
  handleAlertMessage,
  formApproverAccess,
  pagination,
}) => {
  const handleNextPage = () => {
    handleChangePage(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(pageNumber - 1);
  };

  const toProperCase = (str) => {
    if (typeof str !== "string") {
      return str;
    }
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const reorderedColumnSchema = [
    ...columnSchema.filter((col) => col.id === "working_status"),
    ...columnSchema.filter((col) => col.id !== "working_status"),
  ];

  const totalRange = () => {
    const start =
      data?.content?.length > 0
        ? pagination?.pageable?.pageNumber * pagination?.size + 1
        : 0;
    const end =
      data?.content?.length > 0
        ? Math.min(
            pagination?.pageable?.pageNumber * pagination?.size +
              pagination?.numberOfElements,
            pagination?.totalElements
          )
        : 0;
    const total = data?.content?.length > 0 ? pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  return (
    <>
      <TableContainer
        sx={{
          height: "100%",
          margin: "0% 2%",
          width: "100%",
          overflowX: "auto",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {formApproverAccess && (
                <TableColumnCellStyled>Approve</TableColumnCellStyled>
              )}
              <TableColumnCellStyled>Submission Date</TableColumnCellStyled>
              <TableColumnCellStyled>Submitted By</TableColumnCellStyled>
              {reorderedColumnSchema.map((column) => (
                <TableColumnCellStyled key={column.id}>
                  {toProperCase(column.label)}
                </TableColumnCellStyled>
              ))}
              <TableColumnCellStyled>Action</TableColumnCellStyled>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData?.map((submission, index) => (
              <TableRow key={index}>
                {formApproverAccess && (
                  <TableCellStyled>
                    <Checkbox
                      checked={submission.isApprove === 2}
                      onChange={(e) =>
                        onRowStatusChange(
                          submission.id,
                          e.target.checked ? 2 : 1
                        )
                      }
                    />
                  </TableCellStyled>
                )}
                <TableCellStyled>
                  {submission.answer.working_status === "NW"
                    ? moment(submission.answer.date).format(
                        "YYYY-MM-DD hh:mm A"
                      )
                    : moment(submission.answer.date).format(
                        "YYYY-MM-DD hh:mm A"
                      )}
                </TableCellStyled>
                <TableCellStyled>{submission?.user?.fullname}</TableCellStyled>
                {reorderedColumnSchema.map((column) => (
                  <TableCellStyled key={column.id}>
                    {typeof submission.answer[column.id] === "object" ? (
                      <Grid container gap={1} justifyContent={"center"}>
                        {submission.answer[column.id]?.map((item, index) => (
                          <Grid item xs={12}>
                            <a
                              key={index}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={item.url}
                            >
                              {toProperCase(item.name)}
                            </a>
                            <br />
                          </Grid>
                        ))}
                      </Grid>
                    ) : column.id === "time" || column.id === "working_status" ? (
                      submission.answer[column.id]?.toUpperCase()
                    ) : (
                      toProperCase(submission.answer[column.id])
                    )}
                  </TableCellStyled>
                ))}
                <TableCellStyled>
                  {submission.isApprove !== 2 ? (
                    <Tooltip title="Edit Form Submission" placement="top">
                      <IconButton
                        onClick={() => {
                          const selectedRowsArray = Array.from(
                            selectedRowsStatus.values()
                          );
                          if (
                            selectedRowsArray
                              .map((item) => item.formAnswerId)
                              .includes(submission.id)
                          ) {
                            handleAlertMessage(
                              "Please submit your changes first before continuing.",
                              "error"
                            );
                          } else {
                            handleView(submission);
                          }
                        }}
                      >
                        <EditIcon sx={{ color: "#444444", fontSize: "25px" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Edit Form Submission" placement="top">
                      <IconButton
                        disabled
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        <EditIcon
                          sx={{
                            color: "rgba(0, 0, 0, 0.38)",
                            fontSize: "25px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!loading && data?.content?.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
        margin="0% 2%"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={data?.pageable?.pageNumber + 1}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              disableFirst={data?.first}
              disableNext={data?.last || loading}
              pagination={data}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
