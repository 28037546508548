import { useParams } from "react-router-dom";
import { MainView } from "../../components";
import { FormSubmissionContextProvider } from "../../context";
import { useAlert } from "../../utilities";
import { FormAnswer } from "./form-answer/FormAnswer";

export const FormSubmission = () => {
  const { handleAlertMessage, ...alertRest } = useAlert();
  const { formDesignId, formName, formVersion } = useParams();
  const form = JSON.parse(localStorage.getItem("form-submission-schema"));

  return (
    <MainView>
      <FormSubmissionContextProvider
        designId={formDesignId}
        formVersion={formVersion}
        formId={form.id}
      >
        <FormAnswer
          formName={decodeURIComponent(formName)}
          formId={form.id}
          designId={formDesignId}
        />
      </FormSubmissionContextProvider>
    </MainView>
  );
};
