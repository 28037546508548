import useAxios from "../utilities/useAxios";

const FB_SERVICE = `${process.env.REACT_APP_FORMS_BUILDER_SERVICE}`;

export const updateFormFavorite = async (data) => {
  return useAxios
    .post(`${FB_SERVICE}/forms/toggle-favorite`, data)
    .catch((error) => console.error(error));
};

export const getFormDesign = async (id) => {
  return useAxios
    .get(`${FB_SERVICE}/form-designs/form-schema/${id}`)
    .catch((error) => console.error(error));
};

export const checkUserFormAccess = async (params) => {
  return useAxios
    .get(`${FB_SERVICE}/form-designs/check-form-schema-access`, {
      params: params,
    })
    .catch((error) => console.error(error));
};
