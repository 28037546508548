import { Formik } from "formik";
import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useRef } from "react";
import { MainDialog } from "../dialog/MainDialog";
import { FormikTouched } from "../../utilities";
import { FormReportValidationSchema } from "../../constants";
import { StandardButton, ButtonText } from "../styles/StandardButton";

export const GenerateReportModal = ({
  open,
  onClose,
  handleSubmit,
  innerRef,
  values,
  handleAlertMessage,
  validateRef,
  setTouchedRef,
}) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from({ length: 8 }, (_, i) => 2023 + i);

  const remarksRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  return (
    <Formik
      innerRef={innerRef}
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={FormReportValidationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isValid,
        touched,
        setTouched,
        handleBlur,
        validateForm,
      }) => {
        if (validateRef) validateRef.current = validateForm;
        if (setTouchedRef) setTouchedRef.current = setTouched;
        return (
          <MainDialog
            title="Generate Report"
            showDialog={open}
            handleClose={onClose}
            widthType={"sm"}
          >
            <FormikTouched />
            <Grid container rowSpacing={1.8} paddingX={2}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="month"
                  name="month"
                  value={values.month}
                  onChange={(e, val) => {
                    setFieldValue("month", val);
                  }}
                  options={months}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return false;
                    }
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={monthRef}
                      required
                      helperText={touched.month && errors.month}
                      error={Boolean(errors.month && touched.month)}
                      onBlur={handleBlur}
                      label="Month"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="year"
                  name="year"
                  value={values.year}
                  onChange={(e, val) => {
                    setFieldValue("year", val);
                  }}
                  options={years}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return false;
                    }
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={yearRef}
                      required
                      helperText={touched.year && errors.year}
                      error={Boolean(errors.year && touched.year)}
                      onBlur={handleBlur}
                      label="Year"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  type="text"
                  variant="outlined"
                  id="remarks"
                  name="remarks"
                  value={values.remarks}
                  onChange={handleChange}
                  label="Remarks"
                  size="small"
                  fullWidth
                  multiline
                  rows={4}
                  ref={remarksRef}
                />
              </Grid>
              <Grid container marginTop={"3%"} justifyContent="flex-end">
                <StandardButton
                  onClick={(e) => {
                    isValid
                      ? handleSubmit(values)
                      : handleAlertMessage(
                          "Please resolve all fields",
                          "error"
                        );
                  }}
                >
                  <ButtonText>{values?.id ? "Update" : "Submit"}</ButtonText>
                </StandardButton>
              </Grid>
            </Grid>
          </MainDialog>
        );
      }}
    </Formik>
  );
};
