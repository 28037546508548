import React from 'react';
import styled from 'styled-components';

const TemplateContainer = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  overflow-x: auto; 
`;

const MainTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 12px; 

  th {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    background-color: #f2f2f2;
    font-weight: bold;
  }

  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    font-weight: normal;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 8px;
    th, td {
      padding: 4px; 
    }
  }

  @media (max-width: 480px) {
    font-size: 6px;
    th, td {
      padding: 2px;
    }
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    tbody tr td {
      display: flex;
      justify-content: space-between;
    }
  }
`;

function transformData(reportAnswers) {
  const structuredData = reportAnswers.map((report) => {
    const { answer, dateCreated } = report;
    const date = new Date(dateCreated);
    const day = String(date.getDate()).padStart(2, "0");

    return {
      key: answer.identification,
      value: {
        answers: {
          location: answer.location,
          measuring: answer.measuring,
        },
        daily_value: {
          [day]: {
            value: answer.check_results,
            action_taken: answer.action_taken,
            remarks: answer.remarks,
            checkedBy: "Unknown", // Modify this according to your data if available
            approvedBy: "Unknown", // Modify this according to your data if available
          },
        },
      },
    };
  });

  return { structuredData };
}

export const TemplateFive = ({ reportData }) => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  const { structuredData } = transformData(reportData.reportAnswers);

  return (
    <TemplateContainer>
      <MainTable>
        <thead>
          <tr>
            <th colSpan="10" rowSpan="3" style={{ textAlign: "center" }}>
              Identification
            </th>
            <th colSpan="7" rowSpan="3" style={{ textAlign: "center" }}>
              Location
            </th>
            <th colSpan="10" rowSpan="3" style={{ textAlign: "center" }}>
              Jigs and Measuring
            </th>
            <th colSpan="10" rowSpan="3" style={{ textAlign: "center" }}>
              Check Item
            </th>
            <th colSpan="10" rowSpan="3" style={{ textAlign: "center" }}>
              Illustration
            </th>
          </tr>
          <tr>
            <th colSpan="62" style={{ textAlign: "center", backgroundColor: "#e7e7e7" }}>
              Date
            </th>
          </tr>
          <tr>
            {days.map((i) => (
              <th key={i} colSpan="2" style={{ textAlign: "center", fontSize: "x-small" }}>
                {i}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {structuredData.map((entry, entryIdx) => (
            <React.Fragment key={entryIdx}>
              <tr>
                <th
                  colSpan="10"
                  style={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "larger",
                  }}
                >
                  {entry.key}
                </th>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  {entry.value.answers.location}
                </td>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  {entry.value.answers.measuring}
                </td>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  Must be functional properly and no damaged
                </td>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  Default
                </td>
                {days.map((day) => {
                  const dayKey = day < 10 ? "0" + day : "" + day;
                  const dailyValue = entry.value.daily_value[dayKey];

                  return (
                    <td colSpan={"2"} key={dayKey}>
                      {dailyValue != null ? (
                        <span
                          style={{
                            fontSize: "x-small",
                            textTransform: "uppercase",
                          }}
                        >
                          {dailyValue.value}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </td>
                  );
                })}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </MainTable>
    </TemplateContainer>
  );
};
