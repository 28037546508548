import { Grid, styled } from "@mui/material";
import {
  MainView,
  PageComponent,
  PageSize,
  SearchField,
} from "../../components";
import { ReportsTable } from "../../components/table/ReportsTable";
import { useReportsContext } from "../../context";
import { useAlert } from "../../utilities";
import { useEffect } from "react";
import { RESET_INITIAL_STATE } from "../../constants";
import { useNavigate } from "react-router-dom";
import { handleReportNavigation } from "../../utilities/formUtils";
import { API_PENDING } from "../../constants";

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1% 3% 0 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto",
    minWidth: "100px",
  },
}));

export const Reports = () => {
  const {
    forms,
    searchKeyword,
    formsPageNumber,
    formsPageSize,
    sort,
    optimizedSearchForms,
    reportState,
    dispatchReport,
  } = useReportsContext();

  const { handleAlertMessage, ...alertRest } = useAlert();
  let navigate = useNavigate();

  useEffect(() => {
    if (forms) {
      dispatchReport({ type: RESET_INITIAL_STATE });
    }
  }, [forms]);

  const handleChangePage = (pageNumber) => {
    dispatchReport({ type: API_PENDING });
    optimizedSearchForms(searchKeyword, pageNumber, formsPageSize, sort);
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchForms(keyword, 1, formsPageSize, sort);
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchForms(searchKeyword, 1, pageSize, sort);
  };

  const handleNavigateFormReport = (row) => {
    handleReportNavigation(row, navigate);
  };

  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
        isLoading={reportState.loading}
      >
        <GridContainer container gap={2}>
          <FirstColumn gap={2}>
            <SearchField search={handleSearchKeyword} keyword={searchKeyword} />
            <PageSize
              pageSizeValue={formsPageSize}
              handleChange={handleChangePageSize}
            />
          </FirstColumn>
        </GridContainer>
        <GridContainer>
          <ReportsTable
            data={forms?.content ?? []}
            formsPageNumber={formsPageNumber}
            pagination={forms}
            loading={reportState.loading}
            handleChangePage={handleChangePage}
            handleViewFormReport={handleNavigateFormReport}
          />
        </GridContainer>
      </PageComponent>
    </MainView>
  );
};
