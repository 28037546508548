import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

export const ProtectedRoute = ({ component: Component, allowedRoles = [] }) => {
  const userRoles = JSON.parse(localStorage.getItem("role")) || [];

  if (!Array.isArray(allowedRoles)) {
    console.error("allowedRoles should be an array");
    return <Navigate to="/" replace />;
  }

  if (allowedRoles.some((role) => userRoles.includes(role))) {
    return <Component />;
  } else {
    return <Navigate to="/" replace />;
  }
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};
