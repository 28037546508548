import useSWR from "swr";
import useAxios from "../useAxios";

const FB_SERVICE = process.env.REACT_APP_FORMS_BUILDER_SERVICE;
const PAINTING_SERVICE = process.env.REACT_APP_PAINTING_PORTAL_SERVICE;

//1st Card on Dashboard (Forms Approval Card)
export const useFetchFormsApprovalData = () => {
  const fetcher = async () => {
    const url = `${FB_SERVICE}/painting-portal/dashboard-form-approvals`;

    try {
      const response = await useAxios.get(url);

      return (
        response.data.object.map((item) => ({
          title: item.formName,
          link: `/forms/${item.id}`,
          count: item.approvalCount,
          formDesignId: item.id,
          name: item.formName,
          version: item.version,
        })) ?? []
      );
    } catch (error) {
      throw error;
    }
  };

  const { data: formsApprovalData, error } = useSWR("forms-approval", fetcher);

  return {
    formsApprovalData,
    error,
  };
};

//2nd Card on Dashboard (Reports Approval Card)
export const useFetchReportsApprovalData = (userId) => {
  const fetcher = async () => {
    const url = `${PAINTING_SERVICE}/report/dashboard-report-approvals`;

    try {
      const response = await useAxios.get(url, { params: { userId } });

      return (
        response.data.object.map((item) => ({
          title: item.formName,
          formName: item.formName,
          link: `/reports/${item.id}`,
          formDesignId: item.formDesignId,
        })) ?? []
      );
    } catch (error) {
      throw error;
    }
  };

  const { data: reportsApprovalData, error } = useSWR(
    ["reports-approval", userId],
    fetcher
  );

  return {
    reportsApprovalData,
    error,
  };
};

//3rd Card on Dashboard (Latest Report Card)
export const useFetchLatestReportsData = () => {
  const fetcher = async () => {
    const url = `${PAINTING_SERVICE}/report/dashboard-latest-reports`;

    try {
      const response = await useAxios.get(url);

      return (
        response.data.object.map((item) => ({
          id: item.id,
          formDesignId: item.formDesignId,
          formName: item.formName,
          title: item.formName,
          link: `/latest-reports/${item.id}`,
        })) ?? []
      );
    } catch (error) {
      throw error;
    }
  };

  const { data: latestReportsData, error } = useSWR("latest-reports", fetcher);

  return {
    latestReportsData,
    error,
  };
};

// Filter Card
export const useFetchReportNames = () => {
  const fetcher = async () => {
    const url = `${FB_SERVICE}/painting-portal/dashboard-form-dropdown`;

    try {
      const response = await useAxios.get(url);
      return (
        response.data.object.map((item) => ({
          formDesignId: item.formDesignId,
          name: item.name,
          formId: item.formId,
        })) ?? []
      );
    } catch (error) {
      throw error;
    }
  };

  const { data: reportNamesData, error } = useSWR("report-names", fetcher);

  return {
    reportNamesData,
    error,
  };
};

//Report Template API call
export const fetchReportTemplate = async (
  formDesignId,
  month,
  year,
  reportStatus
) => {
  const url = `${PAINTING_SERVICE}/report/dashboard-report-template`;

  try {
    const response = await useAxios.get(url, {
      params: {
        formDesignId,
        month,
        year,
        reportStatus,
      },
    });
    return response.data.object ?? [];
  } catch (error) {
    throw error;
  }
};
