import React, { useState } from "react";
import {
  ListItemText,
  ListItemButton,
  Typography,
  ListItem,
  IconButton,
  Grid,
  Collapse,
  List,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { NavLink } from "react-router-dom";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { routes } from "../../constants";
import { useLocation } from "react-router-dom";
import AddHomeIcon from "@mui/icons-material/AddHome";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SendIcon from "@mui/icons-material/Send";
import CategoryIcon from "@mui/icons-material/Category";
import BarChartIcon from "@mui/icons-material/BarChart";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-end",
}));

const MenuStyles = styled(Grid)(() => ({
  display: "flex",
  marginTop: "8%",
  flexDirection: "column",
  justifyContent: "center",
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none !important",
  color: "white",
  fontSize: theme.typography.fontSize,
}));

const Title = styled(Typography)(() => ({
  paddingTop: 10,
  fontWeight: "bold",
  fontSize: 17,
  textTransform: "uppercase",
}));

const StyledListItem = styled(ListItem)(({ currentLocation }) => ({
  "&:hover": {
    background: "#2F4F4F",
  },
  background: currentLocation ? "#009688" : "none",
}));

const LinkIcon = styled(IconButton)(() => ({
  color: "white",
}));

const MaintenanceText = styled(Typography)(() => ({
  display: "flex",
  gap: 1,
  color: "#fff",
  marginLeft: "15%",
}));

export const Drawer = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const location = useLocation();
  const userRole = JSON.parse(localStorage.getItem("role"));

  const [menu, setMenu] = useState({
    maintenance: true,
  });

  const maintenanceRoutes = routes.filter((item) => {
    if (
      userRole.includes("PAINTING_PORTAL_SUPERADMIN") ||
      userRole.includes("SUPERADMIN") ||
      userRole.includes("PAINTING_PORTAL_ADMIN")
    ) {
      return (
        item.path === "/user-maintenance" || item.path === "/form-maintenance"
      );
    } else if (userRole.includes("SECTIONADMIN")) {
      return item.path === "/form-maintenance";
    }
    return false;
  });

  const handleMaintenanceMenu = (parentKey) => {
    setMenu((curr) => ({
      ...curr,
      [parentKey]: !curr[parentKey],
    }));
  };

  return (
    <MuiDrawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          background: "#222D39",
          color: "#fff",
        },
        display: "block",
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <LinkIcon onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <FormatIndentDecreaseIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </LinkIcon>
      </DrawerHeader>
      <Title>Painting Portal</Title>
      <MenuStyles component="nav">
        <StyledNavLink
          onClick={() => {
            alert("WORK IN PROGRESS");
          }}
        >
          <StyledListItem>
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <AddHomeIcon color="#fff" /> YAS Dashboard
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>

        <StyledNavLink to="/">
          <StyledListItem currentLocation={"/" === location.pathname}>
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <DashboardIcon color="#fff" /> Dashboard
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>

        <StyledNavLink to={"/forms"}>
          <StyledListItem
            currentLocation={
              "/forms" === location.pathname ||
              location.pathname.startsWith("/form-submissions/")
            }
          >
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <SendIcon color="#fff" /> Forms
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>

        <StyledNavLink to="/reports">
          <StyledListItem
            currentLocation={
              "/reports" === location.pathname ||
              location.pathname.startsWith("/form-report/")
            }
          >
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <BarChartIcon color="#fff" /> Reports
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>

        <ListItemButton onClick={() => handleMaintenanceMenu("maintenance")}>
          <ListItemText>
            <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
              <CategoryIcon color="#fff" /> Maintenance
              {menu.maintenance ? <ExpandLess /> : <ExpandMore />}
            </Typography>
          </ListItemText>
        </ListItemButton>

        <Collapse in={menu.maintenance} timeout="auto" unmountOnExit>
          <List component="div">
            {maintenanceRoutes.map((item, index) => {
              return item.role.some((role) => userRole.includes(role)) ? (
                <StyledNavLink to={item.path} key={index}>
                  <StyledListItem
                    button
                    currentLocation={item.path === location.pathname}
                  >
                    <ListItemText>
                      <MaintenanceText
                        color="#fff"
                        sx={{ display: "flex", gap: 1 }}
                      >
                        {item.icon} {item.text}
                      </MaintenanceText>
                    </ListItemText>
                  </StyledListItem>
                </StyledNavLink>
              ) : null;
            })}
          </List>
        </Collapse>

        <StyledNavLink
          onClick={() => {
            const baseUrl = window.location.origin;
            const pdfUrl = `${baseUrl}/assets/user_manual_painting_portal.pdf`;
            window.open(pdfUrl, "_blank");
          }}
        >
          <StyledListItem>
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <ImportContactsIcon color="#fff" /> User Manual
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>

        <StyledNavLink
          onClick={() => {
            alert("WORK IN PROGRESS");
          }}
        >
          <StyledListItem>
            <ListItemText>
              <Typography color="#fff" sx={{ display: "flex", gap: 1 }}>
                <LogoutIcon color="#fff" /> Logout
              </Typography>
            </ListItemText>
          </StyledListItem>
        </StyledNavLink>
      </MenuStyles>
    </MuiDrawer>
  );
};
