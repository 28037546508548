import { Grid, styled, CircularProgress } from "@mui/material";
import { Card } from "./Card";

export const CardContainer = styled(Grid)(({ name }) => ({
  marginTop: "1%",
  display: "flex",
  justifyContent: name === "Users" ? "flex-start" : "center",
}));

export const FormBuilderCard = ({ data, getColorBasedOnName, name }) => {
  return (
    <CardContainer container gap={2} name={name}>
      {data?.map((item, index) => (
        <Card
          key={index}
          color={getColorBasedOnName(item.name)}
          name={item.name}
          value={item.value !== undefined ? item.value : <CircularProgress />}
        />
      ))}
    </CardContainer>
  );
};
