import { createContext, useContext, useState, useMemo } from "react";
import { useEmployeesData, useUserListData } from "../utilities";

const GlobalContext = createContext();

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const GlobalContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [formsView, setFormsView] = useState(
    JSON.parse(localStorage.getItem("form-schema"))
  );

  const { employeesData } = useEmployeesData("users/painting-users-dropdown");
  const { usersData } = useUserListData("users/painting-forms-dropdown");

  // const usersList = usersData?.content
  //   ?.filter((user) =>
  //     user.sections.some((section) => section.name === "PAINTING")
  //   )
  //   .map(({ id, employeeId, fullname }) => ({
  //     id,
  //     employeeId,
  //     fullName: fullname,
  //   }));

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleChangeFormsView = (value) => {
    setFormsView(value);
  };

  const value = useMemo(
    () => ({
      open,
      handleDrawerOpen,
      handleDrawerClose,
      formsView,
      handleChangeFormsView,
      usersList: usersData,
      employeesData,
    }),
    [open, formsView, usersData, employeesData]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
