import React from 'react';
import { MainView } from '../../components';
import { DashboardContent } from './DashboardContent';

export const Dashboard = () => {
  return (
    <MainView>
      <DashboardContent />
    </MainView>
  );
};
