import { Grid } from "@mui/material";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  FormsTable,
  MainView,
  PageComponent,
  PageSize,
  SearchField,
} from "../../components";
import { RESET_INITIAL_STATE, API_PENDING } from "../../constants";
import { useFormContext } from "../../context";
import { useAlert } from "../../utilities";
import { useNavigate } from "react-router-dom";

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1% 3% 0 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto", 
    minWidth: "100px", 
  },
}));

export const Forms = () => {
  let navigate = useNavigate();
  const {
    forms,
    formsPageNumber,
    formsPageSize,
    searchKeyword,
    formState,
    dispatchForm,
    optimizedSearchForms,
    mutateForms,
    sort,
  } = useFormContext();

  const { handleAlertMessage, ...alertRest } = useAlert();

  const handleSuccess = () => {
    if (formState?.resultType === "UPDATE_FORM_FAVORITE") {
      mutateForms();
    }

    if (formState?.resultType === "GET_FORM_DESIGN") {
      navigate(`/forms-view/${formState?.result?.id}`);
    }
    handleAlertMessage(formState.successMessage, "success");
    dispatchForm({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    mutateForms();
    handleAlertMessage(formState.errorMessage, "error");
    dispatchForm({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (formState.successMessage) {
      handleSuccess();
    } else if (formState.errorMessage) {
      handleError();
    }
  }, [formState.success, formState.error]);

  useEffect(() => {
    if (forms) {
      dispatchForm({ type: RESET_INITIAL_STATE });
    }
  }, [forms]);

  const handleChangePage = (pageNumber) => {
    dispatchForm({ type: API_PENDING });
    optimizedSearchForms(searchKeyword, pageNumber, formsPageSize, sort);
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchForms(keyword, 1, formsPageSize, sort);
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchForms(searchKeyword, 1, pageSize, sort);
  };

  const handleFormsSubmission = (row) => {
    localStorage.setItem("form-submission-schema", JSON.stringify(row));
    navigate(
      `/form-submissions/${row.formDesignId}/${encodeURIComponent(row.name)}/${
        row.version
      }`
    );
  };
  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
        isLoading={formState.loading}
      >
        <GridContainer container gap={2}>
          <FirstColumn item gap={2}>
            <SearchField search={handleSearchKeyword} keyword={searchKeyword} />
            <PageSize
              pageSizeValue={formsPageSize}
              handleChange={handleChangePageSize}
            />
          </FirstColumn>
        </GridContainer>
        <Grid container>
          <FormsTable
            data={forms?.content ?? []}
            formsPageNumber={formsPageNumber}
            pagination={forms}
            loading={formState.loading}
            handleChangePage={handleChangePage}
            handleFormSubmission={handleFormsSubmission}
          />
        </Grid>
      </PageComponent>
    </MainView>
  );
};
