import { approveSpecificReport, generateReport } from "../api";
import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  RESET_INITIAL_STATE,
} from "../constants";

export const formReportInitialState = {
  loading: true,
  error: false,
  errorType: "",
  errorMessage: "",
  success: false,
  successMessage: "",
  result: {},
};

export const formReportActions = {
  generateReport:
    (data, formDesignId, isApproverRequired) => async (dispatch) => {
      dispatch({ type: API_PENDING });
      const { month, year } = data;
      const monthYear = `${month} ${year}`;
      const userId = localStorage.getItem("id");

      const payload = {
        id: 0,
        formDesignId: formDesignId,
        preparedById: userId,
        monthYear: monthYear,
        remarks: data?.remarks,
        isApproverRequired: isApproverRequired,
      };

      try {
        await generateReport(payload).then((response) => {
          if (!response) {
            throw {
              message: "Failed to send data. Please check your network.",
              type: "API/Network failure",
            };
          } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
            throw {
              message: "Failed to add form.",
              type: response.data.message,
            };
          } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
            throw {
              message: response.data.message,
            };
          }
          dispatch({
            type: API_SUCCESS,
            payload: "Successfully Generated Report",
          });
        });
      } catch (error) {
        dispatch({ type: API_FAILURE, payload: error });
        console.error(error);
      }
    },
  approveReport: (values) => async (dispatch) => {
    dispatch({ type: API_PENDING });
    try {
      await approveSpecificReport(values).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to approve report.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
      if (values.status === 2) {
        dispatch({
          type: API_SUCCESS,
          payload: "Successfully Approved Report",
        });
      } else {
        dispatch({
          type: API_SUCCESS,
          payload: "Successfully Denied Report",
        });
      }
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  }
};

export const formReportReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case API_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorType: "",
        errorMessage: "",
        success: false,
        successMessage: "",
      };
    case API_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorType: payload.type,
        errorMessage: payload.message,
      };
    case API_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        successMessage: payload,
      };
    case RESET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        successMessage: "",
        errorMessage: "",
      };
    default:
      return state;
  }
};
