import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import jsPDF from "jspdf";
import "./styles.css";
import { Button, CircularProgress } from "@mui/material";

HighchartsExporting(Highcharts);

// Function to extract min and max values from the specification string
const extractMinMax = (spec) => {
  // Regular expression to match numbers before the dash
  const regex = /(\d+\.\d+)-(\d+\.\d+)/;
  const match = spec.match(regex);

  if (match) {
    return {
      min: parseFloat(match[1]), // Convert the first match to a float
      max: parseFloat(match[2]), // Convert the second match to a float
    };
  }

  return {
    min: 0, // Default values if parsing fails
    max: 1,
  };
};

export const PdfTemplate = ({ data, onCancel }) => {
  const templateRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const {
    formName,
    month,
    year,
    structuredData,
    lineStation,
    specification,
    parameterName,
    verifiedBy,
    confirmedBy,
  } = data;

  const downloadPdf = async () => {
    try {
      const element = templateRef.current;

      // Initialize jsPDF
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [14, 8.5],
      });

      // Set margins (left, top, right, bottom)
      const margins = { left: 0.2, top: 0.2, right: 0.2, bottom: 0.1 };

      // Use html2canvas to create an image of the HTML content
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const imgWidth =
        pdf.internal.pageSize.getWidth() - margins.left - margins.right;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pageContentHeight =
        pdf.internal.pageSize.getHeight() - margins.top - margins.bottom;

      let heightLeft = imgHeight;
      let position = margins.top;

      // Add the first page
      pdf.addImage(
        imgData,
        "PNG",
        margins.left,
        position,
        imgWidth,
        pageContentHeight
      );
      heightLeft -= pageContentHeight;

      // Add additional pages if needed
      while (heightLeft > 0) {
        pdf.addPage();
        position = heightLeft - pageContentHeight;
        pdf.addImage(
          imgData,
          "PNG",
          margins.left,
          position,
          imgWidth,
          pageContentHeight
        );
        heightLeft -= pageContentHeight;
      }

      pdf.save(`${formName}.pdf`);
      setLoading(false);
    } catch (error) {
      console.error("Error generating PDF: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Prepare the data array for the chart

    const dailyValuesAm = Array.from({ length: 31 }, (_, i) => {
      const day = String(i + 1).padStart(2, "0"); // Format day as '01', '02', '03', etc.

      if (structuredData[day] && structuredData[day].working_status.value) {
        // Prioritize otherwise use "am"
        return parseFloat(structuredData[day]?.am?.value || "");
      }
      return null; // No data for this day
    });

    const dailyValuesPm = Array.from({ length: 31 }, (_, i) => {
      const day = String(i + 1).padStart(2, "0"); // Format day as '01', '02', '03', etc.

      if (structuredData[day]) {
        // Prioritize otherwise use "Pm"
        return parseFloat(structuredData[day]?.pm?.value || "");
      }
      return null; // No data for this day
    });

    const { min, max } = extractMinMax(specification);
    Highcharts.chart("chart-container", {
      chart: {
        type: "line",
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: Array.from({ length: 31 }, (_, i) => i + 1),
        title: {
          text: "Days of the Month",
        },
      },
      yAxis: {
        title: {
          text: parameterName,
        },
        min: min - (min > 5 ? 10 : 0.1),
        max: max + (max > 5 ? 10 : 0.1),
        labels: {
          formatter: function () {
            return this.value;
          },
        },
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let tooltip = "<strong> Day " + this.x + " </strong><br/>";
          this.points.forEach((point) => {
            tooltip += `<span style="color:${point.series.color}">${point.series.name}:</span> ${point.y}<br/>`;
          });
          return tooltip;
        },
      },
      series: [
        {
          name: `Daily ${parameterName} (AM)`,
          data: dailyValuesAm,
          color: "#000000",
          marker: {
            enabled: true,
            radius: 4,
            symbol: "circle",
          },
          connectNulls: true,
        },

        {
          name: `Daily ${parameterName} (PM)`,
          data: dailyValuesPm,
          color: "#063afe",
          marker: {
            enabled: true,
            radius: 4,
            symbol: "square",
          },
          connectNulls: true,
        },
        {
          name: `Maximum ${parameterName}`,
          data: Array(31).fill(max),
          color: "#ff2d00",
          marker: {
            enabled: false,
          },
          dashStyle: "ShortDash",
        },
        {
          name: `Minimum ${parameterName}`,
          data: Array(31).fill(min),
          color: "#ff2d00",
          marker: {
            enabled: false,
          },
          dashStyle: "ShortDash",
        },
      ],
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    });
  }, []);

  return (
    <div>
      <div
        className="pdf-template"
        ref={templateRef}
        style={{
          padding: "10px",
          width: "100%",
          minHeight: "auto",
          background: "#fff",
          marginTop: 1,
          marginBottom: 1,
        }}
      >
        <h3 style={{ textAlign: "center" }}>Yamaha Motor Philippines, Inc</h3>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            tableLayout: "fixed",
          }}
        >
          <thead>
            <tr>
              <th
                colSpan="56"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                {formName ?? "Default Form Name"}
              </th>
            </tr>
            <tr>
              <th colSpan="25">
                Manufacturing Division <br />
                Production Department <br />
                {lineStation}
              </th>
              <th
                colSpan="15"
                style={{ textAlign: "left", verticalAlign: "top" }}
              >
                Checked by:
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p>{verifiedBy}</p>
                </div>
              </th>
              <th
                colSpan="16"
                style={{ textAlign: "left", verticalAlign: "top" }}
              >
                Approved by:
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p>{confirmedBy}</p>
                </div>
              </th>
            </tr>
            <tr>
              <th colSpan={"56"}>
                <div
                  id="chart-container"
                  style={{ width: "100%", height: "400px" }}
                ></div>
              </th>
            </tr>
            <tr>
              <th colSpan="13" style={{ textAlign: "center" }}>
                Area: Station A
              </th>
              <th colSpan="12" style={{ textAlign: "center" }}>
                {month + " " + year}
              </th>
              <th colSpan="15" style={{ textAlign: "center", border: "none" }}>
                Shift: 8:00 AM - 5:00 PM
              </th>
              <th colSpan="16" style={{ textAlign: "center" }}>
                Group: Default Group
              </th>
            </tr>
            <tr>
              <th colSpan="13" rowSpan="3" style={{ textAlign: "center" }}>
                Parameter
              </th>
              <th colSpan="12" rowSpan="3" style={{ textAlign: "center" }}>
                Specification
              </th>
            </tr>
            <tr>
              <th
                colSpan="31"
                rowSpan="1"
                style={{ textAlign: "center", backgroundColor: "#e7e7e7" }}
              >
                Date
              </th>
            </tr>
            <tr>
              {/* Dates */}
              {Array.from({ length: 31 }).map((_, index) => (
                <th key={index} style={{ textAlign: "center" }}>
                  {index + 1}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Pressure (AM) values */}
            <tr>
              <th colSpan="13" style={{ textAlign: "center" }}>
                {parameterName + " (AM)"}
              </th>
              <th colSpan="12" style={{ textAlign: "center" }}>
                {specification}
              </th>
              {Array.from({ length: 31 }).map((_, index) => {
                const day = (index + 1).toString().padStart(2, "0");
                const data = structuredData[day] && structuredData[day].am;
                const workingStatus =
                  structuredData[day]?.working_status?.value;
                return (
                  <td
                    key={index}
                    style={{ textAlign: "center", fontSize: "x-small" }}
                  >
                    {data && workingStatus
                      ? data.value
                      : workingStatus == false
                      ? "NW"
                      : ""}
                  </td>
                );
              })}
            </tr>
            {/* Pressure (PM) values */}
            <tr>
              <th colSpan="13" style={{ textAlign: "center" }}>
                {parameterName + " (PM)"}
              </th>
              <th colSpan="12" style={{ textAlign: "center" }}>
                {specification}
              </th>
              {Array.from({ length: 31 }).map((_, index) => {
                const day = (index + 1).toString().padStart(2, "0");
                const data = structuredData[day] && structuredData[day].pm;
                const workingStatus =
                  structuredData[day]?.working_status?.value;
                return (
                  <td
                    key={index}
                    style={{ textAlign: "center", fontSize: "x-small" }}
                  >
                    {data && workingStatus
                      ? data.value
                      : workingStatus == false
                      ? "NW"
                      : ""}
                  </td>
                );
              })}
            </tr>
            <tr>
              <th colspan="56">Note:</th>
            </tr>
            <tr>
              <th colspan="7">Date</th>
              <th colspan="6">Time</th>
              <th colspan="12">Problem Details</th>
              <th colspan="11">Action</th>
              <th colspan="10">Prepared By</th>
              <th colspan="10">Verified By</th>
            </tr>
            {/* Problems Section */}
            {Object.keys(structuredData).map((key) => {
              const entry = structuredData[key];
              const renderProblemRow = (timeOfDay) =>
                entry[timeOfDay] &&
                entry.working_status.value &&
                entry[timeOfDay].problem &&
                entry[timeOfDay].action_taken;

              return (
                <>
                  {renderProblemRow("am") && (
                    <tr key={`${key}-am`}>
                      <td colSpan="7">{month + " " + key + ", " + year}</td>
                      <td colSpan="6">AM</td>
                      <td colSpan="12">{entry.am.problem}</td>
                      <td colSpan="11">{entry.am.action_taken}</td>
                      <td colSpan="10">{entry.am.checked_by}</td>
                      <td colSpan="10">{entry.am.approved_by}</td>
                    </tr>
                  )}
                  {renderProblemRow("pm") && (
                    <tr key={`${key}-pm`}>
                      <td colSpan="7">{month + " " + key + ", " + year}</td>
                      <td colSpan="6">PM</td>
                      <td colSpan="12">{entry.pm.problem}</td>
                      <td colSpan="11">{entry.pm.action_taken}</td>
                      <td colSpan="10">{entry.pm.checked_by}</td>
                      <td colSpan="10">{entry.pm.approved_by}</td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          columnGap: 5,
          padding: "0px 8px 0px 8px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            background: "#1B9A92",
            overflow: "hidden",
            "&:hover": {
              background: "#1B9A92",
            },
            padding: "0 0.5",
            color: "#fff",
          }}
          onClick={onCancel}
        >
          Close
        </Button>
        <Button
          variant="contained"
          sx={{
            background: "#1B9A92",
            overflow: "hidden",
            "&:hover": {
              background: "#1B9A92",
            },
            padding: "0 0.5",
            color: "#fff",
          }}
          onClick={() => {
            setLoading(true);
            downloadPdf();
          }}
        >
          {loading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            "Download"
          )}
        </Button>
      </div>
    </div>
  );
};
