import React, { createContext, useContext, useMemo, useReducer } from "react";
import {
  dashboardReducer,
  dashboardInitialState,
} from "../reducer/DashboardReducer";
import { useFetchFormsApprovalData, useFetchReportsApprovalData, useFetchLatestReportsData } from "../utilities";

const DashboardContext = createContext();

export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardContextProvider = ({ children }) => {
  const userId = localStorage.getItem("id");
  const [state, dispatch] = useReducer(dashboardReducer, dashboardInitialState);
  const { formsApprovalData } = useFetchFormsApprovalData();
  const { reportsApprovalData } = useFetchReportsApprovalData(userId);
  const { latestReportsData } = useFetchLatestReportsData();

  const value = useMemo(
    () => ({
      state,
      dispatch,
      formsApprovalData,
      reportsApprovalData,
      latestReportsData,
    }),
    [state, formsApprovalData, reportsApprovalData, latestReportsData]
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
