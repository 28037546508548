import { useParams } from "react-router-dom";
import { MainView } from "../../../components";
import { FormEditAnswerContextProvider } from "../../../context";
import { FormViewAnswer } from "./form-view-answer/FormViewAnswer";

export const FormEditAnswer = () => {
  const { formDesignId, formAnswerId } = useParams();

  return (
    <MainView>
      <FormEditAnswerContextProvider
        formDesignId={formDesignId}
        formAnswerId={formAnswerId}
      >
        <FormViewAnswer />
      </FormEditAnswerContextProvider>
    </MainView>
  );
};
