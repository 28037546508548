export const handleFormSubmission = (row, navigate) => {
  localStorage.setItem("form-submission-schema", JSON.stringify(row));
  navigate(`/form-submissions/${row.formDesignId}/${encodeURIComponent(row.name)}/${row.version}`);
};

export const handleReportNavigation = (row, navigate) => {
  console.log("Navigating to report with data:", row);
  const formName = row.formName ? row.formName : row.name;
  const approverRequired = row.isApproverRequired !== undefined ? row.isApproverRequired : 'undefined';
  const encodedFormName = encodeURIComponent(formName);
  navigate(`/form-report/${row.formDesignId}/${encodedFormName}/${approverRequired}/${row.customTemplate}`);
};
