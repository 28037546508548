import useAxios from "../utilities/useAxios";

const FB_SERVICE = `${process.env.REACT_APP_FORMS_BUILDER_SERVICE}`;

export const submitAnswer = async (data) => {
  return useAxios
    .post(`${FB_SERVICE}/form-answers`, data)
    .catch((error) => console.error(error));
};

export const requestFormAccess = async (data) => {
  return useAxios
    .post(`${FB_SERVICE}/form-viewer`, data)
    .catch((error) => console.error(error));
}

export const submitNewFormAnswer = async (data) => {
  return useAxios
    .post(`${FB_SERVICE}/painting-portal/submit-form-answer`, data)
    .catch((error) => console.error(error));
}