import {
    createContext,
    useContext,
    useMemo,
    useRef,
    useEffect,
    useReducer
  } from "react";
  
  import { useState } from "react";
  import {
    useFormAnswer,
    useFormDesign,
  } from "../utilities";
  
 import { formSubmitAnswerReducer, formSubmitAnswerActions,formSubmitAnswerInitialState } from "../reducer";

  const FormSubmitAnswerContext = createContext();
  
  export const useFormSubmitAnswerContext = () => {
    return useContext(FormSubmitAnswerContext);
  };
  
  export const FormSubmitAnswerContextProvider = ({
    children,
    formDesignId,
  }) => {
    const [formAnswerState, dispatchFormSubmitAnswer] = useReducer(formSubmitAnswerReducer, {
    ...formSubmitAnswerInitialState,
  });
  
    
    const { formDesignData, formSchema } = useFormDesign(
      `form-designs/form-schema/${formDesignId}`,
      formDesignId
    );

    const [actions, setActions] = useState({});
    const stateRef = useRef();

    useEffect(() => {
      Object.keys(formSubmitAnswerActions).forEach((key) => {
        setActions((curr) => ({
          ...curr,
          [key]: (...args) => {
            formSubmitAnswerActions[key](...args)( dispatchFormSubmitAnswer, stateRef.current);
          },
        }));
      });
    }, []);
  
    useEffect(() => {
      stateRef.current = formAnswerState;
    }, [formAnswerState]);
  
    const value = useMemo(
      () => ({
        formSchema,
        formDesignData,
        actions,
        formAnswerState,
        dispatchFormSubmitAnswer
      }),
      [formSchema, formDesignData, actions, formAnswerState, dispatchFormSubmitAnswer ]
    );
  
    return (
      <FormSubmitAnswerContext.Provider value={value}>
        {children}
      </FormSubmitAnswerContext.Provider>
    );
  };
  