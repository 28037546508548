import React from "react";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CustomPagination } from "../pagination/CustomPagination";
import { Typography } from "antd";
import { theme, useDialog } from "../../utilities";
import { ToolTipDialog } from "../dialog/ToolTipDialog";
import { FormsColumnSchema } from "../../constants";
import { useNavigate } from "react-router-dom";

const TableCellStyled = styled(TableCell)(() => ({
  textAlign: "left",
  fontSize: "0.90rem",
  padding: "20px 30px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:last-child": {
    width: "100px",
  },
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "left",
  },
  fontSize: ".95rem",
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "20px 30px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "&:last-child": {
    width: "150px",
  },
}));

export const FormsTable = ({
  data,
  handleChangePage,
  loading,
  formsPageNumber,
  pagination,
  handleFormSubmission,
}) => {
  const { show, dialogData, handleCloseDialog } = useDialog();
  const navigate = useNavigate();

  const handleNextPage = () => {
    handleChangePage(formsPageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(formsPageNumber - 1);
  };

  const totalRange = () => {
    const start = data?.length > 0 ?  pagination?.pageable?.pageNumber * pagination?.size + 1 : 0;
    const end =data?.length > 0 ?  Math.min(
      pagination?.pageable?.pageNumber * pagination?.size +
        pagination?.numberOfElements,
      pagination?.totalElements
    ) : 0;
    const total = data?.length > 0 ?  pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  return (
    <>
      <TableContainer
        sx={{
          height: "100%",
          margin: "0% 3%",
          width: "100%",
          overflowX: "auto",
          marginTop: "2%",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {FormsColumnSchema.map((column) => {
                return (
                  <TableColumnCellStyled key={column.id}>
                    {column.label}
                  </TableColumnCellStyled>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index}>
                <TableCellStyled>{row.name}</TableCellStyled>
                <TableCellStyled>
                  <Grid container spacing={1} justifyContent="flex-start">
                    {row.tags
                      ?.slice()
                      .sort((a, b) => a.localeCompare(b))
                      .map((item, index) => (
                        <Chip
                          key={index}
                          sx={{
                            color: "#000",
                            fontWeight: "normal",
                            fontSize: "0.85rem",
                            background: `${theme.palette.common.lightGrey}60`,
                          }}
                          label={item}
                        />
                      ))}
                  </Grid>
                </TableCellStyled>
                <TableCellStyled>
                  <Grid container gap={1} justifyContent={"flex-start"}>
                    {row.forApproval}
                  </Grid>
                </TableCellStyled>
                <TableCellStyled>
                  <Grid container gap={1} justifyContent={"flex-start"}>
                    <Tooltip title="View Form Submission" placement="top">
                      <IconButton
                        onClick={() => {
                          handleFormSubmission(row);
                        }}
                      >
                        <VisibilityIcon
                          sx={{ color: "#444444", fontSize: "25px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!loading && data.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}

        {dialogData && (
          <ToolTipDialog
            data={dialogData}
            open={show}
            handleClose={handleCloseDialog}
          />
        )}
      </TableContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
        margin="0% 3%"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={formsPageNumber ?? 1}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              disableFirst={pagination?.length === 0 || pagination?.first}
              disableNext={
                pagination?.length === 0 || pagination?.last || loading
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
            Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
