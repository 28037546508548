//[DAY/NIGHT]
// Updated Template One Forms
// [77, 1441] Degreasing Operation Checksheet
// [85, 1450] Pre-Degreasing Operation Checksheet
// [94, 1461] Phospating Operation Checksheet
// [64, 1425] Hot Water Operation Checksheet
// [139, 1470] Water Rinse 1 Operation Checksheet
// [150, 1477] Water Rinse 2 Operation Checksheet
// [157, 1488] Water Rinse 3 Operation Checksheet
// [169, 1498] Water Rinse 4 Dip Operation Checksheet
// [179, 1508] Water Rinse 5 Operation Checksheet
// [31, 1414] Surface Conditioning Operation Checksheet
export const templateOneForms = [
  77, 1441, 85, 1450, 64, 1425, 139, 1470, 150, 1477, 157, 1488, 169, 1498, 179,
  1508, 31, 1414, 94, 1461,
];

//[DAY/NIGHT]
// Updated Template Two Forms
// [1222, 1518] Anti-Static Air Pressure Data Sheet
// [48, 1573] Bake Oven Temperature Data Sheet
// [22, 1541] Di Water Rinse Conductivity Data Sheet
// [39, 1565] Dry Off Oven Temperature Data Sheet
// [1, 1528] Spray Booth Humidity Data Sheet
// [56, 1587] ABS Line 2 Spray Booth Temperature Data Sheet
// [1211, 1554] Surface Conditioning Conducvity Data Sheet

//Template 2 - version 1
// [NONE] ABS Line 2 - Painting Line Start-up Checking : Before Production Start
export const templateTwoForms = [
  1222, 1518, 48, 1573, 22, 1541, 39, 1565, 1, 1528, 56, 1587, 1211, 1554,
];

//[DAY/NIGHT]
// Standard Templates
export const templateThreeForms = [];

//Template 3 - version 1
// [112, 1402] ABS Line 2 - Air Balance Checksheet
export const templateThreeFormsV1 = [112, 1402];

//Template 3 - version 2
// [121, 1367] ABS Line 2 - Weighing Scale Calibration Checking Checklist
// [2073, 2079] ABS Line 2 - Sanding Gun and Buffing Gun Daily Condition Checking Checksheet
export const templateThreeFormsV2 = [121, 1367, 2073, 2079];

//Template 3 - version 3
// [129, 1394] ABS Line 2 - Spray Booth Start-up Checking
// [1716, 1748] ABS Line 2 - Technician/Foreman Daily Checksheet (Pretreatment / Airblow)
// [2059, 2067] ABS Line 2 - Technician/Foreman Daily Checksheet (Spray Booth)
export const templateThreeFormsV3 = [129, 1394, 1716, 1748, 2059, 2067];

//Template 3 - version 4
// [1052, 1624] ABS Painting Line 2 Airblow/Electrostatic/Paintbooth Autonomous Maintenance Check Sheet
export const templateThreeFormsV4 = [1052, 1624];

//Template 3 - version 5
// [2044, 2051] ABS Painting Line 2 Spray Booth Air Balance Condition Check Sheet A-K
// [1649, 1069] ABS Painting Line 2 Spray Booth Air Balance Condition Check Sheet L-U
export const templateThreeFormsV5 = [2044, 2051, 1649, 1069];

//Template 3 - version 6
// [1755, 1761] ABS Line 2 - Pretreatment Spray Nozzle Checking Checksheet
export const templateThreeFormsV6 = [1755, 1761];

//Template 4
//DAY/NIGHT
//ABS Line 2 Painting Preventive Maintenance Calendar
export const templateFourForms = [
  1616, 1599, 7659, 7668, 7536, 7550, 7602, 7609,
];

//Updated Template Five Form
// [102, 1377] - ABS Painting Line 2 Safety Equipment Condition Checking
export const templateFiveForms = [102, 1377];

//[DAY/NIGHT]
// Updated Template One Forms
// [77, 1441] Degreasing Operation Checksheet
// [85, 1450] Pre-Degreasing Operation Checksheet
// [94, 1461] Phospating Operation Checksheet
// [64, 1425] Hot Water Operation Checksheet
// [139, 1470] Water Rinse 1 Operation Checksheet
// [150, 1477] Water Rinse 2 Operation Checksheet
// [157, 1488] Water Rinse 3 Operation Checksheet
// [169, 1498] Water Rinse 4 Dip Operation Checksheet
// [179, 1508] Water Rinse 5 Operation Checksheet
// [31, 1414] Surface Conditioning Operation Checksheet
// [77, 1441, 85, 1450, 64, 1425, 139, 1470, 150, 1477, 157, 1488, 169, 1498, 179, 1508, 31, 1414]

// Updated Template Two Forms
// [1, 1528] Spray Booth Humidity Data Sheet
// [22, 1541] Di Water Rinse Conductivity Data Sheet
// [1211, 1554] Surface Conditioning Conducvity Data Sheet
// [1222, 1518] Anti-Static Air Pressure Data Sheet
// [48, 1573] Bake Oven Temperature Data Sheet
// [39, 1565] Dry Off Oven Temperature Data Sheet
// [NONE] ABS Line 2 Painting Line Start Up Checking: Pretreatment Tank Temperature Data Sheet
// [1, 1528, 22, 1541, 1211, 1554, 1222, 1518, 48, 1573, 39, 1565]

// Updated Template Three Forms
// [1052, 1624] ABS Painting Line 2 Airblow/Electrostatic/Paintbooth Autonomous Maintenance Check Sheet
// [1649, 1069] ABS Line 2 - Spray Booth Air Balance Condition Checksheet
// [NONE] ABS Line 2 - Technician/Foreman Daily Checksheet (Pretreatment / Airblow)
// [NONE] ABS Line 2 - Technician/Foreman Daily Checksheet (Spray Booth)
// [NONE] ABS Line 2 - Pretreatment Spray Nozzle Checking Checksheet
// [NONE] ABS Line 2 - Painting Line Start-up Checking : Before Production Start
// [NONE] ABS Line 2 - Airblow Condition Checksheet
// [NONE] ABS Line 2 - Anti Static Checksheet
// [112, 1402] ABS Line 2 - Air Balance Checksheet
// [129, 1394] ABS Line 2 - Spray Booth Start-up Checking
// [121, 1367] ABS Line 2 - Weighing Scale Calibration Checking Checklist
// [NONE] ABS Line 2 - Sanding Gun and Biffing Gun Daily Condition Checking Checksheet
// [1052, 1624, 1649, 1069, 112, 1402, 129, 1394, 121, 1367]

//Updated Template Five Form
// [102, 1377] - ABS Painting Line 2 Safety Equipment Condition Checking

//Weekly format for Tempalate Three
// ABS Line 2  Spray Booth and Backside After Maintenance Checksheet
// ABS Line 2  Paint Pump and Spray Gun After Maintenance Checksheet
// ABS Line 2  Mixing Area and Side Room After Maintenance Checksheet
// ABS Line 2  Air Blow, Anti-Static and Setting Rooms After Maintenance Checksheet
// ABS Line 2  Loading and Buffing Area After Maintenance Checksheet
// ABS Line 2  Pretreatment Booth, Tank, Strainer and Bag Filter After Maintenance Checksheet
// ABS Line 2  Air Shower #1&2  After Maintenance Checksheet
