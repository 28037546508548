import { createContext, useContext, useMemo } from "react";

import { useFormAnswer, useFormDesign } from "../utilities";

const FormEditAnswerContext = createContext();

export const useFormEditAnswerContext = () => {
  return useContext(FormEditAnswerContext);
};

export const FormEditAnswerContextProvider = ({
  children,
  formDesignId,
  formAnswerId,
}) => {
  const { formDesignData, formSchema } = useFormDesign(
    `form-designs/form-schema/${formDesignId}`,
    formDesignId
  );

  const { formAnswer, mutateFormAnswer } = useFormAnswer(
    `form-answers/${formAnswerId}`
  );

  const value = useMemo(
    () => ({
      formSchema,
      formDesignData,
      formAnswer,
      mutateFormAnswer,
    }),
    [formSchema, formDesignData, formAnswer, mutateFormAnswer]
  );

  return (
    <FormEditAnswerContext.Provider value={value}>
      {children}
    </FormEditAnswerContext.Provider>
  );
};
