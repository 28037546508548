import { Grid, Typography, styled } from "@mui/material";
import {
  FormMaintenanceTable,
  FormMaintenanceReportTable,
  MainView,
  PageComponent,
  PageSize,
  SearchField,
} from "../../../components";
import { useEffect, useState } from "react";
import { useFormMaintenanceContext } from "../../../context";
import { useAlert, useForm } from "../../../utilities";
import { MaintenanceForm } from "./maintenance-form/MaintenanceForm";
import { RESET_INITIAL_STATE, API_PENDING } from "../../../constants";
import { MaintenanceFormReports } from "./maintenance-form-reports/MaintenanceFormReports";

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: ".5%",
  padding: "1.5% 3%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto",
    minWidth: "100px",
  },
}));

const NavigationTypography = styled(Typography)(({ isSelected, theme }) => ({
  borderBottom: isSelected
    ? `8px solid ${theme.palette.common.default}`
    : "none",
  paddingBottom: theme.spacing(0.5),
  textAlign: "left",
  fontWeight: isSelected ? "bolder" : "none",
}));

export const FormMaintenance = () => {
  const [selectedNav, setSelectedNav] = useState("FORMS");

  const { handleAlertMessage, ...alertRest } = useAlert();

  const {
    formsData,
    formsKeyword,
    formsPageNumber,
    formsPageSize,
    optimizedSearchForms,
    mutateForms,
    actions: { editFormApprovers, editReportApprovers },
    formApproverState,
    dispatchFormApprover,
    maintenanceFormReports,
    mutateMaintenanceFormReports,
    reportsPageNumber,
    searchKeyword,
    reportsPageSize,
    sort,
    optimizedSearchFormReports,
    usersList,
  } = useFormMaintenanceContext();

  const {
    showModal: isFormOpen,
    handleDisplayData: showFormData,
    handleCloseModal: closeFormModal,
    formData,
  } = useForm(true);

  const {
    showModal: isFormReportsOpen,
    handleDisplayData: showFormReportsData,
    handleCloseModal: closeFormReportsModal,
    formData: formReportsData,
  } = useForm(true);

  const handleSuccess = () => {
    handleAlertMessage(formApproverState.successMessage, "success");
    closeFormModal();
    mutateForms();
    closeFormReportsModal();
    mutateMaintenanceFormReports();
    dispatchFormApprover({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    handleAlertMessage(formApproverState.errorMessage, "error");
    closeFormModal();
    closeFormReportsModal();
    dispatchFormApprover({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (formApproverState.successMessage) {
      handleSuccess();
    } else if (formApproverState.errorMessage) {
      handleError();
    }
  }, [formApproverState.success, formApproverState.error]);

  const handleNavigateForms = (name) => {
    setSelectedNav(name);
    optimizedSearchForms("", 1, 10);
  };

  const handleNavigateReports = (name) => {
    setSelectedNav(name);
  };

  const handleSearchFormsByKeyword = (keyword) => {
    optimizedSearchForms(keyword, formsPageNumber, formsPageSize);
  };

  const handleChangeFormsPageSize = (pageSize) => {
    optimizedSearchForms(formsKeyword, formsPageNumber, pageSize);
  };

  const handleChangeFormsPageNumber = (pageNumber) => {
    dispatchFormApprover({ type: API_PENDING });
    optimizedSearchForms(formsKeyword, pageNumber, formsPageSize);
  };

  const handleSearchReportsByKeyword = (keyword) => {
    optimizedSearchFormReports(
      keyword,
      reportsPageNumber,
      reportsPageSize,
      sort
    );
  };

  const handleChangeReportsPageSize = (pageSize) => {
    optimizedSearchFormReports(
      searchKeyword,
      reportsPageNumber,
      pageSize,
      sort
    );
  };

  const handleChangeReportsPageNumber = (pageNumber) => {
    dispatchFormApprover({ type: API_PENDING });
    optimizedSearchFormReports(
      searchKeyword,
      pageNumber,
      reportsPageSize,
      sort
    );
  };

  const handleSubmitForm = (values) => {
    editFormApprovers(values);
  };

  const handleSubmitReportApprovers = (values, formId) => {
    editReportApprovers(formId, values);
  };

  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
        isLoading={formApproverState.loading}
      >
        <GridContainer container gap={2}>
          <FirstColumn gap={2}>
            {selectedNav === "FORMS" ? (
              <>
                <SearchField
                  search={handleSearchFormsByKeyword}
                  keyword={formsKeyword}
                />
                <PageSize
                  pageSizeValue={formsPageSize}
                  handleChange={handleChangeFormsPageSize}
                />
              </>
            ) : (
              <>
                <SearchField
                  search={handleSearchReportsByKeyword}
                  keyword={searchKeyword}
                />
                <PageSize
                  pageSizeValue={reportsPageSize}
                  handleChange={handleChangeReportsPageSize}
                />
              </>
            )}
          </FirstColumn>
        </GridContainer>
        <Grid container gap={3} margin="0% 3%">
          <NavigationTypography
            isSelected={selectedNav === "FORMS"}
            onClick={() => {
              handleNavigateForms("FORMS");
            }}
          >
            Forms
          </NavigationTypography>
          <NavigationTypography
            isSelected={selectedNav === "REPORTS"}
            onClick={() => {
              handleNavigateReports("REPORTS");
            }}
          >
            Reports
          </NavigationTypography>
        </Grid>
        {selectedNav === "FORMS" ? (
          <Grid margin={"0.5% 3%"}>
            <FormMaintenanceTable
              data={formsData?.content ?? []}
              pagination={formsData}
              handleChangePage={handleChangeFormsPageNumber}
              pageNumber={formsPageNumber}
              handleView={showFormData}
            />
          </Grid>
        ) : (
          <Grid margin={"0.5% 3%"}>
            <FormMaintenanceReportTable
              data={maintenanceFormReports?.content ?? []}
              pagination={maintenanceFormReports}
              handleChangePage={handleChangeReportsPageNumber}
              pageNumber={reportsPageNumber}
              handleView={showFormReportsData}
            />
          </Grid>
        )}

        {isFormOpen && usersList && (
          <MaintenanceForm
            open={isFormOpen}
            onClose={closeFormModal}
            values={formData}
            handleSubmit={handleSubmitForm}
            usersList={usersList}
          />
        )}

        {isFormReportsOpen && usersList && (
          <MaintenanceFormReports
            open={isFormReportsOpen}
            onClose={closeFormReportsModal}
            values={formReportsData}
            handleSubmitReportApprovers={handleSubmitReportApprovers}
            handleAlertMessage={handleAlertMessage}
            usersList={usersList}
          />
        )}
      </PageComponent>
    </MainView>
  );
};
