import { Grid, IconButton, Typography, styled } from "@mui/material";
import {
  ConfirmationDialog,
  FormSubmissionTable,
  PageComponent,
  PageSize,
  SearchField,
  useConfirmation,
} from "../../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAlert, useForm } from "../../../utilities";
import { useNavigate } from "react-router-dom";
import { useFormSubmissionContext } from "../../../context";
import { useEffect, useState } from "react";
import { API_PENDING, RESET_INITIAL_STATE } from "../../../constants";
import { FormAnswerFilter } from "./form-answer-filter/FormAnswerFilter";
import { getExcelReport } from "../../../api";
import { StandardButton, ButtonText } from "../../../components";
import { Box } from "@mui/material";

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1.5% 2%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const FormTitle = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto",
    minWidth: "100px",
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

const flattenProperties = (properties) => {
  const result = [];

  const traverse = (obj, parentKey = "") => {
    for (const key in obj) {
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      const property = obj[key];

      if (property.type === "void" && property.properties) {
        traverse(property.properties, currentKey);
      } else {
        result.push({
          id: currentKey,
          ...property,
        });
      }
    }
  };

  traverse(properties);
  return result;
};

export const FormAnswer = ({ formName, formId }) => {
  const { handleAlertMessage, ...alertRest } = useAlert();
  const { openDialogue, handleCloseDialogue } = useConfirmation();
  const [toApprove, setToApprove] = useState({});

  let navigate = useNavigate();

  const handleView = (row) => {
    navigate(`/form-edit-answer/${row.formDesignId}/${row.id}`);
  };

  const handleSubmitForm = (row) => {
    navigate(`/form-submit-answer/${formDesignId}`);
  };

  const {
    formSubmissionState,
    dispatchFormSubmission,
    formAnswersData,
    mutateFormAnswersData,
    optimizedSearchFormAnswers,
    pageNumber,
    pageSize,
    version,
    keyword,
    filterSubmission,
    startDate,
    endDate,
    formDesignId,
    formDesignProperties,
    optimizedSearchFormProperties,
    formAccessData,
    formAnswerStatus,
    formApproverAccess,
    actions: { requestFormAccess, approveFormAnswer, submitAllFormAnswer },
  } = useFormSubmissionContext();

  const [selectedRowsStatus, setSelectedRowsStatus] = useState(new Map());
  const [data, setData] = useState([]);

  const handleRowStatusChange = (formAnswerId, status) => {
    setSelectedRowsStatus((prevStatus) => {
      const updatedStatus = new Map(prevStatus);
      updatedStatus.set(formAnswerId, status);
      return updatedStatus;
    });
  };

  // Handle formAnswersData changes
  useEffect(() => {
    if (!formAnswersData) return;

    const updatedData = formAnswersData.content.map((submission) => {
      const updatedStatus = selectedRowsStatus.get(submission.id);
      return {
        ...submission,
        isApprove:
          updatedStatus !== undefined ? updatedStatus : submission.isApprove,
      };
    });

    setData(updatedData);
    dispatchFormSubmission({ type: RESET_INITIAL_STATE });
  }, [formAnswersData]);

  // Handle selectedRowsStatus changes
  useEffect(() => {
    if (selectedRowsStatus.size === 0) return;

    const updatedData = data.map((submission) => {
      const updatedStatus = selectedRowsStatus.get(submission.id);
      return {
        ...submission,
        isApprove:
          updatedStatus !== undefined ? updatedStatus : submission.isApprove,
      };
    });

    setData(updatedData);
  }, [selectedRowsStatus]);

  const handleSubmitAllFormAnswers = () => {
    const userId = localStorage.getItem("id");

    const formattedApprovedAnswers = Array.from(
      selectedRowsStatus.entries()
    ).map(([formAnswerId, status]) => ({
      formAnswerId,
      status,
    }));

    submitAllFormAnswer(
      userId,
      formattedApprovedAnswers,
      setSelectedRowsStatus
    );
    dispatchFormSubmission({ type: RESET_INITIAL_STATE });
    mutateFormAnswersData();
  };

  const {
    showModal: showFilter,
    handleCloseModal: handleCloseFilter,
    handleAddData: handleShowFilter,
  } = useForm();

  const properties = formDesignProperties?.schema?.schema?.properties;
  const flattenedProperties = properties ? flattenProperties(properties) : [];

  const TableColumnSchema = flattenedProperties
    .sort((a, b) => a["x-index"] - b["x-index"])
    .filter((property) => property["x-component"] !== "Image")
    .filter(
      (property) =>
        ![
          "temperature_status",
          "left_side_pressure_status",
          "right_side_pressure_status",
          "date",
        ].includes(property.name)
    )
    .map((property) => {
      return { id: property.name ?? property.id, label: property.title };
    });

  const handleNavigateBack = () => {
    navigate("/forms");
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchFormAnswers(
      keyword,
      pageSize,
      pageNumber,
      filterSubmission,
      startDate,
      endDate,
      formDesignId,
      formAnswerStatus
    );
  };

  const handleChangePage = (pageNumber) => {
    dispatchFormSubmission({ type: API_PENDING });
    optimizedSearchFormAnswers(
      keyword,
      pageSize,
      pageNumber,
      filterSubmission,
      startDate,
      endDate,
      formDesignId,
      formAnswerStatus
    );
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchFormAnswers(
      keyword,
      pageSize,
      1,
      filterSubmission,
      startDate,
      endDate,
      formDesignId,
      formAnswerStatus
    );
  };

  const handleSubmitFilter = (filterValues, startDate, endDate, status) => {
    optimizedSearchFormAnswers(
      keyword,
      pageSize,
      1,
      filterValues,
      startDate,
      endDate,
      formDesignId,
      status
    );

    handleCloseFilter();
  };

  const handleClearFilter = () => {
    optimizedSearchFormAnswers(
      "",
      pageSize,
      pageNumber,
      {},
      null,
      null,
      formDesignId,
      "ALL"
    );
  };

  const handleDownloadExcel = () => {
    getExcelReport(
      keyword,
      version,
      pageSize,
      pageNumber,
      filterSubmission,
      startDate,
      endDate,
      formDesignId,
      formAnswerStatus
    );
  };

  useEffect(() => {
    if (formSubmissionState.successMessage) {
      handleAlertMessage(formSubmissionState.successMessage, "success");
    } else if (formSubmissionState.errorMessage) {
      handleAlertMessage(formSubmissionState.errorMessage, "error");
    }
    mutateFormAnswersData();
  }, [formSubmissionState.success, formSubmissionState.error]);

  const handleSetApproveUser = (values) => {
    const userId = localStorage.getItem("id");

    setToApprove({
      answerId: values.id,
      userId,
      status: values.isApprove === 2 ? 1 : 2,
    });
    handleCloseDialogue(true);
  };

  const handleSubmitConfirmation = () => {
    handleCloseDialogue(true);
  };

  return (
    <PageComponent
      showAlert={alertRest.showAlert}
      handleCloseAlert={alertRest.handleCloseAlert}
      alertSeverity={alertRest.alertSeverity}
      alertMessage={alertRest.alertMessage}
      isLoading={formSubmissionState.loading}
    >
      <GridContainer>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={handleNavigateBack}>
            <ArrowBackIcon sx={{ color: "black" }} />
          </IconButton>
          <Typography
            fontSize="24px"
            fontWeight="bolder"
            sx={{ marginLeft: "3px" }}
          >
            {formName}
          </Typography>
        </Box>
      </GridContainer>
      <GridContainer container gap={2}>
        <FirstColumn gap={1}>
          <SearchField search={handleSearchKeyword} keyword={keyword} />
          <PageSize
            pageSizeValue={pageSize}
            handleChange={handleChangePageSize}
          />
          <StandardButton onClick={handleShowFilter}>
            <ButtonText>Filter</ButtonText>
          </StandardButton>
          <StandardButton onClick={handleClearFilter}>
            <ButtonText>Clear Filter</ButtonText>
          </StandardButton>
        </FirstColumn>

        <SecondColumn gap={1}>
          <StandardButton onClick={handleSubmitForm}>
            <ButtonText>New Record</ButtonText>
          </StandardButton>
          {formApproverAccess?.isApprover && (
            <StandardButton
              onClick={handleSubmitConfirmation}
              disabled={selectedRowsStatus?.size <= 0}
            >
              <ButtonText>Submit</ButtonText>
            </StandardButton>
          )}
          <StandardButton onClick={handleDownloadExcel}>
            <ButtonText>Export</ButtonText>
          </StandardButton>
        </SecondColumn>
      </GridContainer>

      <Grid container>
        <FormSubmissionTable
          data={formAnswersData}
          filteredData={data}
          formApproverAccess={formApproverAccess?.isApprover}
          columnSchema={TableColumnSchema}
          loading={formSubmissionState.loading}
          pageNumber={pageNumber}
          handleChangePage={handleChangePage}
          handleView={handleView}
          handleSubmitForm={handleSubmitForm}
          selectedRowsStatus={selectedRowsStatus}
          onRowStatusChange={handleRowStatusChange}
          handleApprove={handleSetApproveUser}
          handleAlertMessage={handleAlertMessage}
          pagination={formAnswersData}
        />
      </Grid>

      {showFilter && (
        <FormAnswerFilter
          open={showFilter}
          onClose={handleCloseFilter}
          componentSchema={properties}
          filterValues={filterSubmission}
          handleSubmitFilter={handleSubmitFilter}
          startDate={startDate}
          endDate={endDate}
          formAnswerStatus={formAnswerStatus}
        />
      )}
      <ConfirmationDialog
        title={"Confirm Changes"}
        onClose={handleCloseDialogue}
        open={openDialogue}
        callback={handleSubmitAllFormAnswers}
        contentText={`Are you sure you want to submit your changes?`}
      />
    </PageComponent>
  );
};
