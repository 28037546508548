import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

export const useConfirmation = () => {
  const [openDialogue, setOpenDialogue] = useState(false);

  const handleCloseDialogue = (value) => {
    setOpenDialogue(value);
  };

  return {
    openDialogue,
    handleCloseDialogue,
  };
};

const StyledDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  color: "yellow",
  background: "#1B9A92",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledContent = styled(DialogContent)(({ theme }) => ({
  minHeight: 150,
  paddingTop: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  background: "#eeeeee",
}));

const StyledContentText = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.common.black,
}));

const ConfirmButton = styled(Button)(() => ({
  background: "#1B9A92",
  color: "white",
  "&:hover": {
    background: "#1B9A92",
    transform: "translateY(-5px)",
    boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
    transition: "0.3s",
  },
  width: "25%",
}));

const CancelButton = styled(Button)(() => ({
  background: "#9294ac",
  color: "white",
  "&:hover": {
    background: "#9294ac",
    transform: "translateY(-5px)",
    boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
    transition: "0.3s",
  },
  width: "25%",
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <StyledDialogTitle disableTypography {...other}>
      <Typography color={"#FFF"} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: "#FFF",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
};

export const ConfirmationDialog = ({
  onClose,
  open,
  title,
  callback,
  contentText = "Are you sure?",
}) => {
  const handleClose = () => {
    onClose(false);
  };

  const handleConfirm = () => {
    callback();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: "10px" },
      }}
    >
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <StyledContent>
        <StyledContentText variant="h6">{contentText}</StyledContentText>
      </StyledContent>
      <DialogActions
        sx={{
          background: "#eeeeee",
          paddingBottom: "20px",
          marginTop: "-20px",
        }}
      >
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
