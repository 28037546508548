import React from "react";
import styled from "styled-components";

const TemplateContainer = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  overflow-x: auto;
`;

const MainTable = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  font-size: 12px;
  table-layout: fixed;

  th {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    background-color: #f2f2f2;
    font-weight: bold;
  }

  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    font-weight: normal;
  }

  &.highlight th {
    background-color: yellow;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 8px;
    th,
    td {
      padding: 4px;
    }
  }

  @media (max-width: 480px) {
    font-size: 6px;
    th,
    td {
      padding: 2px;
    }
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    tbody tr td {
      display: flex;
      justify-content: space-between;
    }
  }
`;

function transformData(reportAnswers) {
  const tempObj = {};

  reportAnswers.forEach((report) => {
    const { answer, dateCreated } = report;
    const {
      check_item,
      number,
      check_result,
      action_taken,
      problem,
      standard,
      method,
    } = answer;

    const dateCreatedDay = new Date(dateCreated).getDate();
    const date = new Date(dateCreated);
    const dateStr = date.toISOString().split("T")[0];
    const timeStr = date.toTimeString().split(" ")[0];

    if (!tempObj[check_item]) {
      tempObj[check_item] = {};
    }

    if (!tempObj[check_item][number]) {
      tempObj[check_item][number] = {
        daily_values: {},
        details: {
          standard,
          method,
          problem,
          action_taken,
          date_created: dateStr,
          time_created: timeStr,
        },
      };
    }

    tempObj[check_item][number].daily_values[dateCreatedDay] = {
      daily_val: {
        val: check_result || "na",
        action_taken: action_taken || "no action",
        remarks: problem || "remarked.",
      },
    };

    tempObj[check_item][number].daily_values.num_working =
      (tempObj[check_item][number].daily_values.num_working || 0) + 1;
  });

  // Sort the data based on the "No" (number)
  Object.keys(tempObj).forEach((check_item) => {
    tempObj[check_item] = Object.keys(tempObj[check_item])
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .reduce((sortedObj, key) => {
        sortedObj[key] = tempObj[check_item][key];
        return sortedObj;
      }, {});
  });

  const output = Object.keys(tempObj).map((check_item) => {
    return { [check_item]: tempObj[check_item] };
  });

  return output;
}

export const TemplateThreeV2 = ({ reportData }) => {
  const structuredData = transformData(reportData.reportAnswers);

  let prevCheckItem = null;
  let checkItemRowSpan = 0;

  console.log("structured data v2 ", structuredData);

  return (
    <TemplateContainer>
      <MainTable>
        <thead>
          <tr>
            <th colSpan="3" rowSpan="3" style={{ textAlign: "center" }}>
              No
            </th>
            <th colSpan="10" rowSpan="3" style={{ textAlign: "center" }}>
              Check Item
            </th>
            <th colSpan="7" rowSpan="3" style={{ textAlign: "center" }}>
              Standard
            </th>
            <th colSpan="5" rowSpan="3" style={{ textAlign: "center" }}>
              Method
            </th>
          </tr>
          <tr>
            <th
              colSpan="62"
              style={{ textAlign: "center", backgroundColor: "#e7e7e7" }}
            >
              Date
            </th>
          </tr>
          <tr>
            {Array.from({ length: 31 }, (_, i) => (
              <th colSpan="2" key={i} style={{ textAlign: "center" }}>
                {i + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {structuredData.map((item, index) => {
            const check_item = Object.keys(item)[0];
            const data = item[check_item];

            return Object.keys(data).map((number, idx) => {
              const isSameCheckItem = check_item === prevCheckItem;
              if (!isSameCheckItem) {
                checkItemRowSpan = Object.keys(data).length;
                prevCheckItem = check_item;
              }

              return (
                <tr key={`${index}-${idx}`}>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    {number}
                  </td>
                  {!isSameCheckItem && (
                    <td
                      colSpan="10"
                      rowSpan={checkItemRowSpan}
                      style={{ textAlign: "center" }}
                    >
                      {check_item}
                    </td>
                  )}
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    {data[number].details.standard}
                  </td>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    {data[number].details.method}
                  </td>
                  {Array.from({ length: 31 }, (_, day) => {
                    const dayStr = String(day + 1)
                    return (
                      <td
                        colSpan="2"
                        key={day}
                        style={{
                          textAlign: "center",
                          textTransform: "uppercase",
                        }}
                      >
                        {data[number].daily_values[dayStr]
                          ? data[number].daily_values[dayStr].daily_val.val
                          : ""}
                      </td>
                    );
                  })}
                </tr>
              );
            });
          })}
          <tr>
            <th colSpan="87"></th>
          </tr>
          <tr>
            <th colSpan="10" style={{ textAlign: "center" }}>
              Date
            </th>
            <th colSpan="10" style={{ textAlign: "center" }}>
              Time
            </th>
            <th colSpan="38" style={{ textAlign: "center" }}>
              Problem Details
            </th>
            <th colSpan="29" style={{ textAlign: "center" }}>
              Action
            </th>
          </tr>
          {structuredData.map((item, index) => {
            const check_item = Object.keys(item)[0];
            const data = item[check_item];
            return Object.keys(data).map((number, idx) => (
              <tr key={`${index}-${idx}`}>
                <td colSpan="10">{data[number].details.date_created}</td>
                <td colSpan="10">{data[number].details.time_created}</td>
                <td colSpan="38">{data[number].details.problem}</td>
                <td colSpan="29">{data[number].details.action_taken}</td>
              </tr>
            ));
          })}
        </tbody>
      </MainTable>
    </TemplateContainer>
  );
};
