import React, { useState } from "react";
import { Grid, Card, CardContent, Typography, CircularProgress } from "@mui/material";
import { useDashboardContext } from "../../context/DashboardContext";
import {
  ApprovalSections,
  FilterSection,
  TemplateFive,
  TemplateThree,
  TemplateOneV2,
  TemplateTwo,
  TemplateOne,
  NoTemplateYet,
  TemplateThreeV1,
  TemplateThreeV2,
  TemplateThreeV3,
  TemplateThreeV4,
  TemplateThreeV5,
  TemplateThreeV6,
} from "../../components";
import { theme } from "../../utilities";
import {
  templateOneForms,
  templateTwoForms,
  templateThreeForms,
  templateFiveForms,
  templateThreeFormsV1,
  templateThreeFormsV2,
  templateThreeFormsV3,
  templateThreeFormsV4,
  templateThreeFormsV5,
  templateThreeFormsV6,
  templateFourForms,
} from "../../constants";
import { TemplateFour } from "../../components/dashboard-content/TemplateFour";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const useTemplateCheck = (selectedForm) => {
  return {
    isTemplateOneForm: templateOneForms.includes(selectedForm),
    isTemplateTwoForm: templateTwoForms.includes(selectedForm),
    isTemplateThreeForm: templateThreeForms.includes(selectedForm),
    isTemplateThreeFormV1: templateThreeFormsV1.includes(selectedForm),
    isTemplateThreeFormV2: templateThreeFormsV2.includes(selectedForm),
    isTemplateThreeFormV3: templateThreeFormsV3.includes(selectedForm),
    isTemplateThreeFormV4: templateThreeFormsV4.includes(selectedForm),
    isTemplateThreeFormV5: templateThreeFormsV5.includes(selectedForm),
    isTemplateThreeFormV6: templateThreeFormsV6.includes(selectedForm),
    isTemplateFourForm: templateFourForms.includes(selectedForm),
    isTemplateFiveForm: templateFiveForms.includes(selectedForm),
  };
};

export const DashboardContent = () => {
  const { state, formsApprovalData, reportsApprovalData, latestReportsData } =
    useDashboardContext();
  const { loading, error } = state;
  const [reportData, setReportData] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);
  const currentMonthIndex = new Date().getMonth();
  const [selectedMonth, setSelectedMonth] = useState(months[currentMonthIndex]);

  const {
    isTemplateOneForm,
    isTemplateTwoForm,
    isTemplateThreeForm,
    isTemplateThreeFormV1,
    isTemplateThreeFormV2,
    isTemplateThreeFormV3,
    isTemplateThreeFormV4,
    isTemplateThreeFormV5,
    isTemplateThreeFormV6,
    isTemplateFourForm,
    isTemplateFiveForm,
  } = useTemplateCheck(selectedForm);

  const noTemplateYet =
    !isTemplateOneForm &&
    !isTemplateTwoForm &&
    !isTemplateThreeForm &&
    !isTemplateThreeFormV1 &&
    !isTemplateThreeFormV2 &&
    !isTemplateThreeFormV3 &&
    !isTemplateThreeFormV4 &&
    !isTemplateThreeFormV5 &&
    !isTemplateThreeFormV6 &&
    !isTemplateFourForm &&
    !isTemplateFiveForm;

  const handleSelectForm = (form) => {
    setSelectedForm(form);
    setReportLoading(true); 
    setTimeout(() => {
      setReportLoading(false);
    }, 2000);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error loading data</Typography>;
  }

  return (
    <Grid container spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <ApprovalSections
          formsApprovalData={formsApprovalData}
          reportsApprovalData={reportsApprovalData}
          latestReportsData={latestReportsData}
        />
      </Grid>

      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <FilterSection
            setReportData={setReportData}
            handleSelectForm={handleSelectForm}
            months={months}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            disabled={reportLoading}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              {reportLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {isTemplateOneForm && reportData && (
                    <TemplateOne reportData={reportData} />
                  )}
                  {isTemplateTwoForm && reportData && (
                    <TemplateTwo reportData={reportData} />
                  )}
                  {isTemplateThreeForm && reportData && (
                    <TemplateThree reportData={reportData} />
                  )}
                  {isTemplateThreeFormV1 && reportData && (
                    <TemplateThreeV1 reportData={reportData} />
                  )}
                  {isTemplateThreeFormV2 && reportData && (
                    <TemplateThreeV2 reportData={reportData} />
                  )}
                  {isTemplateThreeFormV3 && reportData && (
                    <TemplateThreeV3 reportData={reportData} />
                  )}
                  {isTemplateThreeFormV4 && reportData && (
                    <TemplateThreeV4 reportData={reportData} />
                  )}
                  {isTemplateThreeFormV5 && reportData && (
                    <TemplateThreeV5 reportData={reportData} />
                  )}
                  {isTemplateThreeFormV6 && reportData && (
                    <TemplateThreeV6 reportData={reportData} />
                  )}
                  {isTemplateFourForm && reportData && (
                    <TemplateFour
                      reportData={reportData}
                      selectedMonth={selectedMonth}
                    />
                  )}
                  {isTemplateFiveForm && reportData && (
                    <TemplateFive reportData={reportData} />
                  )}
                  {noTemplateYet && <NoTemplateYet />}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
