import { useRef } from "react";
import { Formik } from "formik";
import { MainDialog } from "../../../../components";
import { FormikTouched } from "../../../../utilities";
import { Autocomplete, Button, Grid, TextField, styled } from "@mui/material";
import { FormReportMaintenanceValidationSchema } from "../../../../constants";

const SubmitButton = styled(Button)(() => ({
  fontSize: "0.90rem",
  padding: "0.8% 3%",
  color: "#ffffff",
  textTransform: "capitalize",
  background: "#1B9A92",
  "&:hover": {
    color: "#ffffff",
    textTransform: "capitalize",
    background: "#1B9A92",
  },
}));

export const MaintenanceFormReports = ({
  innerRef,
  values,
  handleSubmit,
  open,
  onClose,
  validateRef,
  setTouchedRef,
  handleSubmitReportApprovers,
  handleAlertMessage,
  usersList,
}) => {
  const verifiedByRef = useRef(null);
  const confirmedByRef = useRef(null);

  return (
    <Formik
      innerRef={innerRef}
      initialValues={{
        formName: values.formName,
        formId: values.formId,
        verifiedBy: values.verifiedBy?.fullname ?? "",
        confirmedBy: values.confirmedBy?.fullname ?? "",
      }}
      validationSchema={FormReportMaintenanceValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isValid,
        touched,
        setTouched,
        handleBlur,
        validateForm,
      }) => {
        if (validateRef) validateRef.current = validateForm;
        if (setTouchedRef) setTouchedRef.current = setTouched;

        return (
          <MainDialog
            title={values.formName}
            showDialog={open}
            handleClose={onClose}
            widthType={"sm"}
          >
            <FormikTouched />
            <Grid container rowSpacing={1.8} paddingX={2}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="verifiedBy"
                  name="verifiedBy"
                  value={values.verifiedBy}
                  options={[
                    ...new Set(usersList?.map((user) => user?.fullName)),
                  ]}
                  onChange={(e, newValue) => {
                    setFieldValue("verifiedBy", newValue);
                  }}
                  disableClearable
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return false;
                    }
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={verifiedByRef}
                      required
                      helperText={touched.verifiedBy && errors.verifiedBy}
                      error={Boolean(errors.verifiedBy && touched.verifiedBy)}
                      onBlur={handleBlur}
                      label="Checked By"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="confirmedBy"
                  name="confirmedBy"
                  value={values.confirmedBy}
                  options={[
                    ...new Set(usersList?.map((user) => user?.fullName)),
                  ]}
                  onChange={(e, newValue) => {
                    setFieldValue("confirmedBy", newValue);
                  }}
                  disableClearable
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return false;
                    }
                    return option === value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={confirmedByRef}
                      required
                      helperText={touched.confirmedBy && errors.confirmedBy}
                      error={Boolean(errors.confirmedBy && touched.confirmedBy)}
                      onBlur={handleBlur}
                      label="Approved By"
                    />
                  )}
                />
              </Grid>
              <Grid container marginTop={"3%"} justifyContent="flex-end">
                <SubmitButton
                  type="submit"
                  size="small"
                  onClick={() => {
                    if (isValid) {
                      let newData = {
                        verifiedBy: values.verifiedBy
                          ? usersList.filter(
                              (user) => user.fullName === values.verifiedBy
                            )[0]
                          : {},
                        confirmedBy: values.confirmedBy
                          ? usersList.filter(
                              (user) => user.fullName === values.confirmedBy
                            )[0]
                          : {},
                      };
                      console.log("NEW DATA", newData);
                      handleSubmitReportApprovers(newData, values.formId);
                    } else {
                      handleAlertMessage("Resolve all fields.", "error");
                    }
                  }}
                >
                  {values?.id ? "Update" : "Submit"}
                </SubmitButton>
              </Grid>
            </Grid>
          </MainDialog>
        );
      }}
    </Formik>
  );
};
