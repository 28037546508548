import * as Yup from "yup";

export const UserValidationSchema = Yup.object({
  fullname: Yup.string().required("Employee name is required"),
  status: Yup.string().required("Status is required"),
});

export const FormReportValidationSchema = Yup.object({
  month: Yup.string().required("Month is required"),
  year: Yup.string().required("Year is required"),
});

export const FormReportMaintenanceValidationSchema = Yup.object().shape({
  verifiedBy: Yup.string().notOneOf(
    [Yup.ref("confirmedBy")],
    "Verified by and Confirmed by cannot be the same"
  ),
  confirmedBy: Yup.string().notOneOf(
    [Yup.ref("verifiedBy")],
    "Verified by and Confirmed by cannot be the same"
  ),
});
