import React from "react";
import styled from "styled-components";

const TemplateContainer = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  overflow-x: auto;
`;

const MainTable = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  font-size: 12px;
  table-layout: fixed;

  th {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    background-color: #f2f2f2;
    font-weight: bold;
  }

  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    font-weight: normal;
  }

  &.highlight th {
    background-color: yellow;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 8px;
    th,
    td {
      padding: 4px;
    }
  }

  @media (max-width: 480px) {
    font-size: 6px;
    th,
    td {
      padding: 2px;
    }
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    tbody tr td {
      display: flex;
      justify-content: space-between;
    }
  }
`;

function transformData(reportAnswers) {
  const structuredData = reportAnswers.map((report) => {
    const { answer, dateCreated } = report;
    const date = new Date(dateCreated);
    const day = String(date.getDate()).padStart(2, "0");
    const dateStr = date.toISOString().split("T")[0];
    const timeStr = date.toTimeString().split(" ")[0];

    return {
      key: answer.number,
      value: {
        answers: {
          method: answer.method,
          number: answer.number,
          problem: answer.problem,
          standard: answer.standard,
          check_item: answer.check_item,
          action_taken: answer.action_taken,
          check_result: answer.check_results,
          working_status: answer.working_status,
          check_frequency: answer.check_frequency,
          problem: answer.problem,
          dateCreated: dateStr,
          timeCreated: timeStr,
        },
        daily_value: {
          [day]: {
            value: answer.check_results ?? answer.input,
            action_taken: answer.action_taken,
            remarks: answer.remarks,
            checkedBy: "Unknown", // Modify this according to your data if available
            approvedBy: "Unknown", // Modify this according to your data if available
          },
        },
      },
    };
  });

  return { structuredData };
}

export const TemplateThreeV4 = ({ reportData }) => {
  const { structuredData } = transformData(reportData.reportAnswers);

  // Filter out entries where problem is empty or "N/A"
  const filteredProblem = structuredData.filter(
    (entry) =>
      entry.value.answers.problem &&
      entry.value.answers.problem.trim() !== "" &&
      entry.value.answers.problem !== "N/A"
  );

  return (
    <TemplateContainer>
      <MainTable>
        <thead>
          <tr>
            <th colSpan="4" rowSpan="3" style={{ textAlign: "center" }}>
              No
            </th>
            <th colSpan="8" rowSpan="3" style={{ textAlign: "center" }}>
              Check Item
            </th>
            <th colSpan="7" rowSpan="3" style={{ textAlign: "center" }}>
              Standard
            </th>
            <th colSpan="5" rowSpan="3" style={{ textAlign: "center" }}>
              Method
            </th>
            <th colSpan="8" rowSpan="3" style={{ textAlign: "center" }}>
              Frequency of Checking
            </th>
          </tr>
          <tr>
            <th
              colSpan="62"
              style={{ textAlign: "center", backgroundColor: "#e7e7e7" }}
            >
              Date
            </th>
          </tr>
          <tr>
            {Array.from({ length: 31 }, (_, i) => (
              <th colSpan={"2"} key={i} style={{ textAlign: "start" }}>
                {i + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {structuredData.map((entry, index) => (
            <tr key={index}>
              <td colSpan={"4"} style={{ textAlign: "center" }}>
                {entry.value.answers.number}
              </td>
              <td colSpan="8" style={{ textAlign: "center" }}>
                {entry.value.answers.check_item}
              </td>
              <td colSpan="7" style={{ textAlign: "center" }}>
                {entry.value.answers.standard}
              </td>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {entry.value.answers.method}
              </td>
              <td colSpan="8" style={{ textAlign: "center" }}>
                {entry.value.answers.check_frequency}
              </td>
              {Array.from({ length: 31 }, (_, day) => {
                const dayStr = String(day + 1).padStart(2, "0");
                return (
                  <td
                    colSpan={"2"}
                    key={day}
                    style={{ textAlign: "start", textTransform: "uppercase" }}
                  >
                    {entry.value.daily_value[dayStr]
                      ? entry.value.daily_value[dayStr].value
                      : ""}
                  </td>
                );
              })}
            </tr>
          ))}
          <tr>
            <th colSpan="94"></th>
          </tr>
          <tr>
            <th colSpan="10" style={{ textAlign: "center" }}>
              Date
            </th>
            <th colSpan="10" style={{ textAlign: "center" }}>
              Time
            </th>
            <th colSpan="37" style={{ textAlign: "center" }}>
              Problem Details
            </th>
            <th colSpan="37" style={{ textAlign: "center" }}>
              Action
            </th>
          </tr>
          {filteredProblem.map((entry, index) => (
            <tr key={index}>
              <td colSpan="10">{entry.value.answers.dateCreated}</td>
              <td colSpan="10">{entry.value.answers.timeCreated}</td>
              <td colSpan="37">{entry.value.answers.problem}</td>
              <td colSpan="37">{entry.value.answers.action_taken}</td>
            </tr>
          ))}
        </tbody>
      </MainTable>
    </TemplateContainer>
  );
};
