import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { FormReportColumnSchema } from "../../constants";
import { CustomPagination } from "../pagination/CustomPagination";
import moment from "moment";
import { CsvSvgIcon } from "../svg-icons/CsvSvgIcon";
import { PdfCsvIcon } from "../svg-icons/PdfSvgIcon";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const TableColumnCellStyled = styled(TableCell)(() => ({
  textAlign: "left",
  fontSize: ".95rem",
  fontWeight: "bold",
  height: "5px",
  textTransform: "capitalize",
  padding: "20px 30px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  "&:last-child": {
    width: "150px",
  },
}));

const TableCellStyled = styled(TableCell)(() => ({
  textAlign: "left",
  fontSize: "0.90rem",
  padding: "20px 30px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:last-child": {
    width: "150px",
  },
}));

export const FormReportsTable = ({
  data,
  handleChangePage,
  loading,
  formsPageNumber,
  pagination,
  handleDownloadExcel,
  handleDownloadCustomReport,
  handleApproveReport,
  reportApproversData,
  isCustomReport,
}) => {
  const handleNextPage = () => {
    handleChangePage(formsPageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(formsPageNumber - 1);
  };

  const userId = parseInt(localStorage.getItem("id"));

  const isUserApprover = reportApproversData
    ?.filter((item) => item.userId === userId)
    .map((item) => ({ userId: item.userId, role: item.role }));

  //Verified By is changed to Checked By - BE will be implemented soon
  const userVerifiedBy = isUserApprover?.filter(
    (item) => item.role === "VERIFIED_BY"
  );

  //Confirmed By is changed to Approved By - BE will be implemented soon
  const userConfirmedBy = isUserApprover?.filter(
    (item) => item.role === "CONFIRMED_BY"
  );

  const getStatus = (row) => {
    if (row.status === 1) {
      return "PENDING";
    }
    if (row.status === 2) {
      if (
        (row.countVerifyBy > 0 && userVerifiedBy?.length > 0) ||
        (row.countConfirmedBy > 0 && userConfirmedBy?.length > 0) ||
        (row.countConfirmedBy > 0 && row.countVerifyBy > 0)
      ) {
        return "APPROVED";
      }
    }
    if (row.status === 3) {
      return "REJECTED";
    }
    return "PENDING";
  };

  const totalRange = () => {
    const start =
      data?.length > 0
        ? pagination?.pageable?.pageNumber * pagination?.size + 1
        : 0;
    const end =
      data?.length > 0
        ? Math.min(
            pagination?.pageable?.pageNumber * pagination?.size +
              pagination?.numberOfElements,
            pagination?.totalElements
          )
        : 0;
    const total = data?.length > 0 ? pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {FormReportColumnSchema.map((column) => (
                <TableColumnCellStyled key={column.id}>
                  {column.label}
                </TableColumnCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCellStyled>{row.monthYear}</TableCellStyled>
                <TableCellStyled>{row.remarks}</TableCellStyled>
                <TableCellStyled>{row.preparedBy}</TableCellStyled>
                <TableCellStyled>{getStatus(row)}</TableCellStyled>
                <TableCellStyled>
                  {moment(row.dateRegistered).format("MMMM D, YYYY")}
                </TableCellStyled>
                <TableCellStyled>
                  <Grid container gap={1} justifyContent={"flex-start"}>
                    {isCustomReport === "true" ? (
                      <Tooltip title="Download Custom Report" placement="top">
                        <IconButton
                          onClick={() => {
                            handleDownloadCustomReport(row);
                          }}
                        >
                          <PdfCsvIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    <Tooltip title="Download Answers as CSV" placement="top">
                      <IconButton
                        onClick={() => {
                          handleDownloadExcel(row);
                        }}
                      >
                        <CsvSvgIcon />
                      </IconButton>
                    </Tooltip>

                    {(row.status === 1 || row.status === 2) &&
                    row.countVerifyBy === 0 &&
                    userVerifiedBy?.length > 0 ? (
                      <>
                        <Tooltip title="Approve Report" placement="top">
                          <IconButton
                            onClick={() =>
                              handleApproveReport({
                                reportId: row.id,
                                status: 2,
                                approver: userVerifiedBy,
                              })
                            }
                          >
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Deny Report" placement="top">
                          <IconButton
                            onClick={() =>
                              handleApproveReport({
                                reportId: row.id,
                                status: 3,
                                approver: userVerifiedBy,
                              })
                            }
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : null}

                    {(row.status === 1 || row.status === 2) &&
                    row.countConfirmedBy === 0 &&
                    userConfirmedBy?.length > 0 ? (
                      <>
                        <Tooltip title="Approve Report" placement="top">
                          <IconButton
                            onClick={() =>
                              handleApproveReport({
                                reportId: row.id,
                                status: 2,
                                approver: userConfirmedBy,
                              })
                            }
                          >
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Deny Report" placement="top">
                          <IconButton
                            onClick={() =>
                              handleApproveReport({
                                reportId: row.id,
                                status: 3,
                                approver: userConfirmedBy,
                              })
                            }
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : null}
                  </Grid>
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!loading && data.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}
      </TableContainer>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
        // margin="0% 3%"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={formsPageNumber ?? 1}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              disableFirst={pagination?.length === 0 || pagination?.first}
              disableNext={
                pagination?.length === 0 || pagination?.last || loading
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
