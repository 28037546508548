import React from 'react';

const resultMap = {
  conducted: 'conducted',
  plan: 'plan',
  cancelled: 'cancelled',
  actual: 'actual',
  empty: 'empty'
};

const resultPriority = {
  cancelled: 1,
  conducted: 2,
  plan: 3,
  empty: 4 
};


function transformData(reportAnswers, processPriority = {}) {
  const transformedData = {};

  reportAnswers.forEach(report => {
    const { answer, dateModified } = report;
    const { Week, result, particular, process_name, equipment_name, maintenance_and_activities, day, variance, maintenance_frequency } = answer;

    // Create a unique key by combining maintenance_and_activities and process_name
    const activityKey = `${(maintenance_and_activities?.toLowerCase() || '')}_${(process_name?.toLowerCase() || '')}`;
    const weekKey = Week?.toUpperCase();

    if (!transformedData[activityKey]) {
      transformedData[activityKey] = {
        details: {
          standard: null,
          method: null,
          check_item: null,
          particular,
          equipment_name,
          process_name,
          maintenance_frequency
        }
      };
    }

    if (!transformedData[activityKey][weekKey]) {
      transformedData[activityKey][weekKey] = {};
    }

    const existingEntry = transformedData[activityKey][weekKey];
    const existingResultPriority = resultPriority[existingEntry.result === "cancelled pm schedule" ? "cancelled" : existingEntry.result] || Infinity;
    const newResultPriority = resultPriority[result === "cancelled pm schedule" ? "cancelled" : result] || Infinity;

    // Update if new entry has higher priority or is more recent
    if (newResultPriority < existingResultPriority || 
        (newResultPriority === existingResultPriority && new Date(existingEntry.dateModified) < new Date(dateModified))) {
      existingEntry.result = result === "cancelled pm schedule" ? "cancelled" : resultMap[result] || 'empty';
      existingEntry.dateModified = dateModified;
    }

    existingEntry[variance] = parseInt(day, 10);
  });


  // Sort the activity keys based on dynamic processPriority or alphabetically if priority is not provided
  const sortedTransformedData = {};
  Object.keys(transformedData)
    .sort((a, b) => {
      const processA = transformedData[a].details.process_name.toLowerCase();
      const processB = transformedData[b].details.process_name.toLowerCase();

      // Log process names and their priorities
      const priorityA = processPriority[processA] ?? Infinity;
      const priorityB = processPriority[processB] ?? Infinity;

      if (priorityA === Infinity && priorityB === Infinity) {
        return processA.localeCompare(processB);
      }

      return priorityA - priorityB;
    })
    .forEach(activityKey => {
      const sortedWeeks = {};
      Object.keys(transformedData[activityKey])
        .sort()
        .forEach(weekKey => {
          const entry = transformedData[activityKey][weekKey];
          if (entry.dateModified) {
            delete entry.dateModified;
          }
          sortedWeeks[weekKey] = entry;
        });
      sortedTransformedData[activityKey] = {
        ...transformedData[activityKey],
        ...sortedWeeks
      };
    });


  return sortedTransformedData;
}

export const TemplateFour = ({ reportData, selectedMonth }) => {
  const structuredData = transformData(reportData.reportAnswers);

  console.log("template 4 structured data ", structuredData);
  const title = "Default title";

  const month = selectedMonth;

  return (
    <div>
      <head>
        <meta charset="UTF-8" />
        <title>{title}</title>
        <style>
          {
            `@page {
              size: 14in 8.5in;
              margin: 0;
            }
            table {
              border-collapse: collapse;
              width: 100%;
              table-layout: fixed;
              font-size: 12px;
            }
            th {
              background-color: #f2f2f2;
              border: 1px solid black;
              font-weight: bold;
            }
            td {
              padding: 5px;
              border: 1px solid black;
              white-space: normal;
              font-size: 11px;
              font-family: Arial, Helvetica, sans-serif;
              text-align: left;
              font-weight: normal; 
            }
            .conducted,
            .plan,
            .cancelled,
            .empty {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              display: inline-block;
            }
            .conducted {
              background-color: black;
            }
            .plan {
              background-color: white;
              border: 1px solid black;
            }
            .empty {
              background-color: white;
            }
            .cancelled {
              width: 22px;
              height: 22px;
              background: url("https://www.shareicon.net/data/256x256/2016/02/18/721018_cancel_512x512.png") no-repeat center center;
              background-size: contain;
            }`
          }
        </style>
      </head>
      <body>

        <table>
          <tr>
            <th colSpan="7" rowSpan="3" style={{ textAlign: 'center' }}>Maintenance Activities</th>
            <th colSpan="7" rowSpan="3" style={{ textAlign: 'center' }}>Particular</th>
            <th colSpan="7" rowSpan="3" style={{ textAlign: 'center' }}>Process Name</th>
            <th colSpan="7" rowSpan="3" style={{ textAlign: 'center' }}>Equipment Name</th>
            <th colSpan="8" rowSpan="3" style={{ textAlign: 'center' }}>Maintenance Frequency</th>
          </tr>
          <tr>
            <th colSpan="20" rowSpan="1" style={{ textAlign: 'center', backgroundColor: '#e7e7e7' }}>{month}</th>
          </tr>
          <tr>
            <th colSpan="4" rowSpan="1" style={{ textAlign: 'center' }}>W1</th>
            <th colSpan="4" rowSpan="1" style={{ textAlign: 'center' }}>W2</th>
            <th colSpan="4" rowSpan="1" style={{ textAlign: 'center' }}>W3</th>
            <th colSpan="4" rowSpan="1" style={{ textAlign: 'center' }}>W4</th>
            <th colSpan="4" rowSpan="1" style={{ textAlign: 'center' }}>W5</th>
          </tr>
          {Object.entries(structuredData).map(([activityKey, data], index) => (
            <React.Fragment key={index}>
              <tr>
                <th colSpan="7" rowSpan="2" style={{ textAlign: 'center', textTransform: 'capitalize' }}>{activityKey.split("_")[0]}</th>
                <td colSpan="7" rowSpan="2" style={{ textAlign: 'center' }}>{data.details.particular}</td>
                <td colSpan="7" rowSpan="2" style={{ textAlign: 'center' }}>{data.details.process_name}</td>
                <td colSpan="7" rowSpan="2" style={{ textAlign: 'center' }}>{data.details.equipment_name}</td>
                <td colSpan="4" rowSpan="2" style={{ textAlign: 'center' }}>Weekly</td>
                <th colSpan="4" rowSpan="1" style={{ textAlign: 'center' }}>Plan</th>
                {['W1', 'W2', 'W3', 'W4', 'W5'].map(week => (
                  <React.Fragment key={`${week}-plan`}>
                    <td colSpan="2">
                      <span style={{ fontSize: 'x-small', textTransform: 'uppercase' }}>
                        {data[week]?.plan || ''}
                      </span>
                    </td>
                    <td colSpan="2" rowSpan="2">
                      <div className={data[week]?.result}></div>
                    </td>
                  </React.Fragment>
                ))}
              </tr>
              <tr>
                <th colSpan="4" rowSpan="1" style={{ textAlign: 'center' }}>Actual</th>
                {['W1', 'W2', 'W3', 'W4', 'W5'].map(week => (
                  <td colSpan="2" key={`${week}-actual`}>
                    <span style={{ fontSize: 'x-small', textTransform: 'uppercase' }}>
                      {data[week]?.actual || ''}
                    </span>
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </table>
      </body>
    </div>
  );
};