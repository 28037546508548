import useAxios from "../utilities/useAxios";

const FB_SERVICE = `${process.env.REACT_APP_FORMS_BUILDER_SERVICE}`;
const PAINTING_PORTAL_SERVICE = `${process.env.REACT_APP_PAINTING_PORTAL_SERVICE}`;
const PAINTING_PORTAL = "/painting-portal";

export const updateFormApprovers = async (data, formDesignId) => {
  return useAxios
    .put(
      `${FB_SERVICE}${PAINTING_PORTAL}/edit-form-approvers/${formDesignId}`,
      data
    )
    .catch((error) => console.error(error));
};

export const createPaintingUser = async (data) => {
  return useAxios
    .post(`${FB_SERVICE}${PAINTING_PORTAL}/create-user`, data)
    .catch((error) => console.error(error));
};

export const updateReportApprover = async (data, formId) => {
  return useAxios
    .put(
      `${PAINTING_PORTAL_SERVICE}/report/edit-report-approvers/${formId}`,
      data
    )
    .catch((error) => console.error(error));
};

export const updatePaintingUser = async (data) => {
  return useAxios
    .put(
      `${FB_SERVICE}${PAINTING_PORTAL}/update-user/${data.user.employeeId}`,
      data
    )
    .catch((error) => console.error(error));
};

//Report
export const generateReport = async (data) => {
  return useAxios
    .post(`${PAINTING_PORTAL_SERVICE}/report/generate-report`, data)
    .catch((error) => console.error(error));
};

export const approveSpecificReport = async (data) => {
  return useAxios
    .post(`${PAINTING_PORTAL_SERVICE}/report/edit-report-status`, data)
    .catch((error) => console.error(error));
};

export const generateReportAnswersToExcel = async (reportId, excelName) => {
  try {
    const response = await useAxios.get(
      `${PAINTING_PORTAL_SERVICE}/report/download-report/`,
      {
        params: {
          reportId: reportId,
        },
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.ms-excel",
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = excelName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export const generateCustomReport = async (
  reportId,
  pdfName,
  handleAlertMessage,
  formName
) => {
  try {
    const response = await useAxios.get(
      `${PAINTING_PORTAL_SERVICE}/report/download-custom-report`,
      {
        params: {
          reportId: reportId,
        },
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", pdfName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    handleAlertMessage("Failed to download Custom Report", "error");
    console.error("Error downloading Excel:", error);
  }
};
