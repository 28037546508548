export const PageTitles = [
  {
    path: "/",
    title: "Dashboard",
  },
  {
    path: "/forms-view",
    title: "Forms View",
  },
  {
    path: "/reports",
    title: "Reports",
  },
  {
    path: "/form-maintenance",
    title: "Form Management",
  },

  {
    path: "/user-maintenance",
    title: "User Management",
  },

  {
    path: "/viewer-maintenance",
    title: "Viewer Management",
  },
  {
    path: "/forms",
    title: "Forms",
  },
];
