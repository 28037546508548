import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  MainView,
  PageComponent,
  PageSize,
  SearchField,
} from "../../../components";
import { UserMaintenanceTable } from "../../../components/table/UserMaintenanceTable";
import Indicator from "../../../components/bar-indicator/BarIndicator";
import AddUserMaintenance from "./AddUserModal";
import { useUserMaintenanceContext } from "../../../context/UserMaintenanceContext";
import { handleUserStatus } from "../../../constants/TableStatus";
import { RESET_INITIAL_STATE, API_PENDING } from "../../../constants";
import { useAlert, useForm } from "../../../utilities";
import { FormBuilderCard } from "../../../components/card/FormBuilderCard";
import { UserForm } from "./user-form/UserForm";
import { UserFilter } from "./user-filter/UserFilter";
import { StandardButton, ButtonText } from "../../../components";

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1.5% 2%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto", 
    minWidth: "100px", 
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

const getColorBasedOnName = (name) => {
  switch (name) {
    case "Total Users":
      return "#34516F";
    case "Active Users":
      return "#379f86";
    case "Inactive Users":
      return "#FAAA4C";
    default:
      return "defaultColor";
  }
};

export const UserMaintenance = () => {
  const {
    usersData,
    usersPageNumber,
    usersPageSize,
    usersKeyword,
    usersState,
    dispatchUsers,
    optimizedSearchUsers,
    mutateUsers,
    userCardsData,
    mutateUserCardsData,
    userStatus,
    dateCreated,
    actions: { submitUser, editUser },
  } = useUserMaintenanceContext();

  const UserMaintenanceCardData = [
    { id: 1, name: "Total Users", value: userCardsData?.totalUsers },
    { id: 2, name: "Active Users", value: userCardsData?.activeUsers },
    { id: 3, name: "Inactive Users", value: userCardsData?.inactiveUsers },
  ];

  const {
    formData,
    showModal,
    handleCloseModal,
    handleDisplayData,
    handleAddData,
  } = useForm();

  const {
    showModal: showFilter,
    handleCloseModal: handleCloseFilter,
    handleAddData: handleShowFilter,
  } = useForm();

  const { handleAlertMessage, ...alertRest } = useAlert();

  useEffect(() => {
    if (usersData) {
      dispatchUsers({ type: RESET_INITIAL_STATE });
    }
  }, [usersData]);

  const handleSuccess = () => {
    handleAlertMessage(usersState.successMessage, "success");
    mutateUsers();
    mutateUserCardsData();
    handleCloseModal();
    dispatchUsers({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    handleAlertMessage(usersState.errorMessage, "error");
    handleCloseModal();
    dispatchUsers({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (usersState.successMessage) {
      handleSuccess();
    } else if (usersState.errorMessage) {
      handleError();
    }
  }, [usersState.success, usersState.error]);

  const handleSearchKeyword = (keyword) => {
    optimizedSearchUsers(keyword, 1, usersPageSize, userStatus, dateCreated);
  };

  const handleChangePage = (pageNumber) => {
    dispatchUsers({ type: API_PENDING });
    optimizedSearchUsers(
      usersKeyword,
      pageNumber,
      usersPageSize,
      userStatus,
      dateCreated
    );
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchUsers(usersKeyword, 1, pageSize, userStatus, dateCreated);
  };

  const handleSubmitFilter = (status, dateCreated) => {
    optimizedSearchUsers(usersKeyword, 1, usersPageSize, status, dateCreated);
    handleCloseFilter();
  };

  const handleClearFilter = () => {
    optimizedSearchUsers("", usersPageNumber, usersPageSize, "ALL", "");
  };

  const handleSubmit = (values) => {
    if (values.id) {
      editUser(values);
    } else {
      values.status = values?.status === "ACTIVE" ? 1 : 0;
      submitUser(values);
    }
  };

  return (
    <MainView>
      <PageComponent
        showAlert={alertRest.showAlert}
        handleCloseAlert={alertRest.handleCloseAlert}
        alertSeverity={alertRest.alertSeverity}
        alertMessage={alertRest.alertMessage}
  
      >
        <Grid container margin={"0% 2%"}>
          <FormBuilderCard
            data={UserMaintenanceCardData}
            getColorBasedOnName={getColorBasedOnName}
            name="Users"
            loading={usersState.loading}
          />
        </Grid>
        <GridContainer container gap={1}>
          <FirstColumn item gap={1}>
            <SearchField search={handleSearchKeyword} keyword={usersKeyword} />
            <PageSize
              handleChange={handleChangePageSize}
              pageSizeValue={usersPageSize}
            />
            <StandardButton onClick={handleShowFilter}>
              <ButtonText>Filter</ButtonText>
            </StandardButton>
            <StandardButton onClick={handleClearFilter}>
              <ButtonText>Clear Filter</ButtonText>
            </StandardButton>
          </FirstColumn>
          <SecondColumn item gap={1}>
            <StandardButton onClick={handleAddData}>
              <ButtonText>Create User</ButtonText>
            </StandardButton>
          </SecondColumn>
        </GridContainer>
        <GridContainer>
          <UserMaintenanceTable
            data={usersData?.content ?? []}
            handleStatus={handleUserStatus}
            handleDisplayData={handleDisplayData}
            pagination={usersData}
            usersPageNumber={usersPageNumber}
            handleChangePage={handleChangePage}
            loading={usersState.loading}
          />
        </GridContainer>

        {formData && (
          <UserForm
            open={showModal}
            onClose={handleCloseModal}
            values={formData}
            handleSubmit={handleSubmit}
            handleAlertMessage={handleAlertMessage}
          />
        )}

        {showFilter && (
          <UserFilter
            open={showFilter}
            onClose={handleCloseFilter}
            handleSubmitFilter={handleSubmitFilter}
            userStatus={userStatus}
            dateCreated={dateCreated}
          />
        )}

        {/* <AddUserMaintenance open={openModal} handleClose={handleCloseModal} /> */}
      </PageComponent>
    </MainView>
  );
};

export default UserMaintenance;
