import React, { useState, useEffect } from "react";
import { 
  Modal, 
  TextField, 
  Button, 
  Typography, 
  styled, 
  FormControl, 
  MenuItem, 
  Select, 
  InputLabel 
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ButtonText, StandardButton } from "../../../components";

// Styled Components
const ModalContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  width: 570,
  height: 410,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%", 
    height: "14.5%",
    backgroundColor: "#379F86", 
    zIndex: -1,
  },
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  padding: theme.spacing(0, 0, 2),
  color: "#ffffff",
  width: "100%",
  textAlign: "left",
}));

const CloseIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#379F86",
  borderRadius: "50%",
  width: 36,
  height: 36,
  cursor: "pointer",
}));



const AddUserMaintenance = ({ open, handleClose }) => {
  const [employeeID, setEmployeeID] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [status, setStatus] = useState("");
  
  const [employeeNameError, setEmployeeNameError] = useState(false);
  const [statusError, setStatusError] = useState(false);

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  const resetForm = () => {
    setEmployeeID(generateEmployeeID());
    setEmployeeName("");
    setStatus("");
    setEmployeeNameError(false);
    setStatusError(false);
  };

  const handleSubmit = () => {
    if (!employeeName.trim() || !status) {
      setEmployeeNameError(!employeeName);
      setStatusError(!status);
    } else {
      resetForm();
      console.log("Form submitted");
      handleClose();
    }
  };

  const generateEmployeeID = () => {
    const newID = Math.floor(1000 + Math.random() * 9000);
    if (employeeIDs.includes(newID)) {
      return generateEmployeeID();
    } else {
      setEmployeeIDs([...employeeIDs, newID]);
      return newID;
    }
  };

  const [employeeIDs, setEmployeeIDs] = useState([]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer>
        <ModalTitle>Create User</ModalTitle>
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon style={{ fontSize: 24, cursor: "pointer", color: "#ffffff" }} />
        </CloseIconWrapper>
        <TextField
          label="Employee ID"
          // value={employeeID}
          disabled
          fullWidth
          margin="normal"
          InputProps={{ style: { borderColor: "#379F86" } }}
        />
        <TextField
          label="Employee Name *"
          // value={employeeName}
          onChange={(e) => {
            setEmployeeName(e.target.value);
            setEmployeeNameError(false);
          }}
          fullWidth
          margin="normal"
          InputProps={{ style: { borderColor: "#379F86" } }}
          error={employeeNameError}
          helperText={employeeNameError && "The field value is required"}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Status *</InputLabel>
          <Select
            labelId="status-label"
            // value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setStatusError(false);
            }}
            error={statusError}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
          {statusError && <Typography variant="caption" color="error">The field value is required</Typography>}
        </FormControl>
        <StandardButton variant="contained" color="primary" onClick={handleSubmit}>
          <ButtonText>Submit</ButtonText>
        </StandardButton>
      </ModalContainer>
    </Modal>
  );
};

export default AddUserMaintenance;
