import { FormContextProvider } from "../context";
import {
  FormMaintenance,
  FormSubmission,
  Forms,
  GenerateReport,
  Reports,
  UserMaintenance,
} from "../pages";
import SendIcon from "@mui/icons-material/Send";
import { FormEditAnswer } from "../pages/form-submission/form-edit-answer/FormEditAnswer";
import { FormSubmitAnswer } from "../pages/form-submission/form-submit-answer/FormSubmitAnswer";
import { FormMaintenanceContextProvider } from "../context/FormMaintenanceContext";
import { ReportsContexProvider } from "../context";
import { UserMaintenanceContextProvider } from "../context/UserMaintenanceContext";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { MainFormsView } from "../pages";
import { Dashboard } from "../pages";
import { DashboardContextProvider } from "../context/DashboardContext";
import { CustomPdfReport } from "../components/pdf/CustomPdfReport";

export const routes = [
  {
    path: "/forms",
    component: (
      <FormContextProvider>
        <Forms />
      </FormContextProvider>
    ),
    text: "Forms",
    icon: <SendIcon color="#b9b6a7" />,
    role: ["ALL"],
  },

  {
    path: "/form-submissions/:formDesignId/:formName/:formVersion",
    component: <FormSubmission />,
    text: "Form Submissions",
    icon: <SendIcon color="#b9b6a7" />,
    role: ["SUPERADMIN", "PAINTING_PORTAL_ADMIN", "PAINTING_PORTAL_SUPERADMIN"],
  },
  {
    path: "/form-report/:formDesignId/:formName/:isApproverRequired/:isCustomReport",
    component: <GenerateReport />,
    text: "Form Report",
    icon: <SendIcon color="#b9b6a7" />,
    role: ["SUPERADMIN", "PAINTING_PORTAL_ADMIN", "PAINTING_PORTAL_SUPERADMIN"],
  },
  {
    path: "/form-edit-answer/:formDesignId/:formAnswerId",
    component: <FormEditAnswer />,
    text: "Form Edit Answer",
    icon: <SendIcon color="#b9b6a7" />,
    role: ["SUPERADMIN", "PAINTING_PORTAL_ADMIN", "PAINTING_PORTAL_SUPERADMIN"],
  },

  {
    path: "/form-submit-answer/:formDesignId",
    component: <FormSubmitAnswer />,
    text: "Form Submit Answer",
    icon: <SendIcon color="#b9b6a7" />,
    role: ["SUPERADMIN", "PAINTING_PORTAL_ADMIN", "PAINTING_PORTAL_SUPERADMIN"],
  },

  {
    path: "/reports",
    component: (
      <ReportsContexProvider>
        <Reports />
      </ReportsContexProvider>
    ),
    text: "Reports",
    role: ["SUPERADMIN", "PAINTING_PORTAL_ADMIN", "PAINTING_PORTAL_SUPERADMIN"],
  },

  {
    path: "/user-maintenance",
    component: (
      <UserMaintenanceContextProvider>
        <UserMaintenance />
      </UserMaintenanceContextProvider>
    ),
    text: "User Management",
    role: ["PAINTING_PORTAL_SUPERADMIN"],
  },

  {
    path: "/form-maintenance",
    component: (
      <FormMaintenanceContextProvider>
        <FormMaintenance />
      </FormMaintenanceContextProvider>
    ),
    text: "Form Management",
    role: ["PAINTING_PORTAL_ADMIN"],
  },

  {
    path: "/",
    component: (
      <DashboardContextProvider>
        <Dashboard />
      </DashboardContextProvider>
    ),
    text: "Dashboard",
    role: ["PAINTING_PORTAL_ADMIN", "PAINTING_PORTAL_SUPERADMIN"],
  },
];

export const AppRoutes = () => (
  <Routes>
    {routes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        element={
          route.role.includes("ALL") ? (
            route.component
          ) : (
            <ProtectedRoute
              component={() => route.component}
              allowedRoles={route.role}
            />
          )
        }
      />
    ))}
    <Route path="/forms-view/:formDesignId" element={<MainFormsView />} />
  </Routes>
);
