import { useRef } from "react";
import { Formik } from "formik";
import { MainDialog } from "../../../../components";
import { FormikTouched } from "../../../../utilities";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { StandardButton, ButtonText } from "../../../../components";

export const MaintenanceForm = ({
  innerRef,
  values,
  handleSubmit,
  open,
  onClose,
  validateRef,
  setTouchedRef,
  usersList,
}) => {
  const approverRef = useRef(null);
  return (
    <Formik
      innerRef={innerRef}
      initialValues={{ ...values }}
      onSubmit={handleSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isValid,
        touched,
        setTouched,
        handleBlur,
        validateForm,
      }) => {
        if (validateRef) validateRef.current = validateForm;
        if (setTouchedRef) setTouchedRef.current = setTouched;
        console.log("VALUES", values);
        return (
          <MainDialog
            title={values?.formDesignId ? "Update Form " : "Create Form"}
            showDialog={open}
            handleClose={onClose}
            widthType={"sm"}
          >
            <FormikTouched />
            <Grid container rowSpacing={1.8} paddingX={2}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  multiple
                  id="approvers"
                  name="approvers"
                  value={values?.approvers ?? []}
                  options={usersList ?? []}
                  onChange={(e, newValue) => {
                    setFieldValue("approvers", newValue);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return (
                      option?.employeeId === value?.employeeId &&
                      option?.id === value?.id
                    );
                  }}
                  getOptionLabel={(option) => option?.fullName || ""}
                  renderOption={(props, option) => (
                    <li {...props} key={option.employeeId}>
                      {option.fullName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={approverRef}
                      required
                      helperText={touched.approvers && errors.approvers}
                      error={Boolean(errors.approvers && touched.approvers)}
                      onBlur={handleBlur}
                      label="Approver"
                    />
                  )}
                />
              </Grid>

              <Grid container marginTop={"3%"} justifyContent="flex-end">
                <StandardButton
                  type="submit"
                  size="small"
                  disabled={!isValid}
                  onClick={(e) => {
                    handleSubmit(values);
                  }}
                >
                  <ButtonText>{values?.id ? "Update" : "Submit"}</ButtonText>
                </StandardButton>
              </Grid>
            </Grid>
          </MainDialog>
        );
      }}
    </Formik>
  );
};
