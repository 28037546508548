/* eslint-disable no-throw-literal */
import {
  approveFormAnswer,
  requestFormAccess,
  submitAllFormAnswers,
  submitNewFormAnswer,
} from "../api";
import {
  API_FAILURE,
  API_PENDING,
  API_SUCCESS,
  RESET_INITIAL_STATE,
} from "../constants";

export const formSubmissionInitialState = {
  loading: true,
  error: false,
  errorType: "",
  errorMessage: "",
  success: false,
  successMessage: "",
  result: {},
};

export const formSubmissionActions = {
  requestFormAccess: (values) => async (dispatch) => {
    dispatch({ type: API_PENDING });

    try {
      await requestFormAccess(values).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
      dispatch({
        type: API_SUCCESS,
        payload: "Succesfully submitted request access.",
      });
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },
  approveFormAnswer: (values) => async (dispatch) => {
    dispatch({ type: API_PENDING });
    try {
      await approveFormAnswer(values.answerId, values).then((response) => {
        if (!response) {
          throw {
            message: "Failed to send data. Please check your network.",
            type: "API/Network failure",
          };
        } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
          throw {
            message: "Failed to add form.",
            type: response.data.message,
          };
        } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
          throw {
            message: response.data.message,
          };
        }
      });
      if (values.status === 2) {
        dispatch({
          type: API_SUCCESS,
          payload: "Successfully Approved Form Answer",
        });
      } else {
        dispatch({
          type: API_SUCCESS,
          payload: "Successfully Unapproved Form Answer",
        });
      }
    } catch (error) {
      dispatch({ type: API_FAILURE, payload: error });
      console.error(error);
    }
  },

  submitAllFormAnswer:
    (userId, values, setSelectedRowsStatus) => async (dispatch) => {
      try {
        await submitAllFormAnswers(userId, values).then((response) => {
          if (!response) {
            throw {
              message: "Failed to send data. Please check your network.",
              type: "API/Network failure",
            };
          } else if (response.data.status === "INTERNAL_SERVER_ERROR") {
            throw {
              message: "Failed to update form answer status.",
              type: response.data.message,
            };
          } else if (response.data.httpStatus === "UNPROCESSABLE_ENTITY") {
            throw {
              message: response.data.message,
            };
          }
        });
        dispatch({
          type: API_SUCCESS,
          payload: "Successfully Updated Form Answers Status.",
        });
        setSelectedRowsStatus(new Map());
      } catch (error) {
        dispatch({ type: API_FAILURE, payload: error });
        console.error(error);
      }
    },
};

export const formSubmissionReducer = (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case API_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorType: "",
        errorMessage: "",
        success: false,
        successMessage: "",
      };
    case API_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorType: payload.type,
        errorMessage: payload.message,
      };
    case API_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        successMessage: payload,
      };
    case RESET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        successMessage: "",
        errorMessage: "",
      };
    default:
      return state;
  }
};
