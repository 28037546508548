import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { theme as customTheme } from "../../utilities";
import { useFetchReportNames } from "../../utilities";
import { fetchReportTemplate } from "../../utilities";

export const styles = {
  titleBar: {
    backgroundColor: `${customTheme.palette.common.lightGrey}`,
    color: "#3D3D3D",
    padding: "10px",
    borderRadius: "4px 4px 0 0",
    marginBottom: "10px",
    textAlign: "left",
  },
  card: {
    borderRadius: "10px",
    borderColor: "#3D3D3D",
  },
  cardContent: {
    textAlign: "left",
    color: "#1B9A92",
  },
  formControl: {
    minWidth: 200,
    marginRight: "10px",
    color: "#3D3D3D",
  },
  formControlFullWidth: {
    width: "100%",
    marginBottom: "10px",
    color: "#3D3D3D",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3D3D3D",
  },
  inputLabel: {
    color: "#3D3D3D",
  },
  menuItem: {
    fontWeight: "bold",
  },
};

const years = Array.from({ length: 8 }, (_, i) => 2023 + i);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 5 + 8, // 48px is the height of each item, 5 items + padding
    },
  },
};

const reportStatuses = ["APPROVED", "UNAPPROVED"];

const toProperCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const FilterSection = ({
  setReportData,
  handleSelectForm,
  months,
  selectedMonth,
  setSelectedMonth,
  disabled,
}) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const { reportNamesData, error } = useFetchReportNames();
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedReportStatus, setSelectedReportStatus] =
    useState("UNAPPROVED");
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    if (reportNamesData?.length > 0) {
      const randomReport =
        reportNamesData[Math.floor(Math.random() * reportNamesData.length)];
      setSelectedReport(randomReport);
      handleSelectForm(randomReport.formId);
    }
  }, [reportNamesData]);

  const handleReportChange = (event) => {
    const selected = reportNamesData.find(
      (report) => report.name === event.target.value
    );
    handleSelectForm(selected.formId);
    setSelectedReport(selected);
  };

  const handleReportStatusChange = (event) => {
    setSelectedReportStatus(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleFetchData = async () => {
    if (selectedReport) {
      try {
        const data = await fetchReportTemplate(
          selectedReport.formDesignId,
          selectedMonth,
          selectedYear,
          selectedReportStatus
        );
        setReportData(data);
      } catch (error) {
        console.error("Error fetching report template data:", error);
      }
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [selectedReport, selectedReportStatus, selectedMonth, selectedYear]);

  return (
    <Grid item xs={12}>
      <Card style={styles.card}>
        <CardContent style={styles.cardContent}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <Select
                  size="small"
                  value={selectedReport.name ?? ""}
                  onChange={handleReportChange}
                  MenuProps={MenuProps}
                  disabled={disabled}
                >
                  {reportNamesData
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((report) => (
                      <MenuItem
                        key={report.formDesignId}
                        value={report.name}
                        style={styles.menuItem}
                      >
                        {report.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth margin="normal">
                <Select
                  size="small"
                  value={selectedReportStatus ?? ""}
                  onChange={handleReportStatusChange}
                  MenuProps={MenuProps}
                  disabled={disabled}
                >
                  {reportStatuses?.map((status) => (
                    <MenuItem key={status} value={status}>
                      {toProperCase(status)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth margin="normal">
                <Select
                  size="small"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  MenuProps={MenuProps}
                  disabled={disabled}
                >
                  {months.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth margin="normal">
                <Select
                  size="small"
                  value={selectedYear}
                  onChange={handleYearChange}
                  MenuProps={MenuProps}
                  disabled={disabled}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
