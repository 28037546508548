import React from "react";
import styled from "styled-components";

const TemplateContainer = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  overflow-x: auto;
`;

const MainTable = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  font-size: 12px;

  th,
  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 8px;
    th,
    td {
      padding: 4px;
    }
  }

  @media (max-width: 480px) {
    font-size: 6px;
    th,
    td {
      padding: 2px;
    }
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    tbody tr td {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const TemplateTwoComponent = ({ data }) => {
  const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);

  let parameter = "";
  const humidityForms = [1, 1528];
  const temperatureForms = [48, 1573, 39, 1565, 56, 1587];
  const pressureForms = [1222, 1518];
  const conductivityForms = [22, 1541, 1211, 1554];

  const gridSchema = data.schema.schema;

  function getNestedValue(obj, path, defaultValue) {
    return path
      .split(".")
      .reduce((o, p) => (o && o[p] !== undefined ? o[p] : defaultValue), obj);
  }

  let specification = null;

  if (gridSchema != null) {
    let properties = getNestedValue(gridSchema, "properties", {});
    let grid = getNestedValue(properties, "grid", {});
    if (grid != null) {
      let gridProperties = getNestedValue(grid, "properties", {});
      let parametersAndSpec = getNestedValue(
        gridProperties,
        "parameters_and_specification",
        {}
      );
      if (parametersAndSpec != null) {
        specification = getNestedValue(parametersAndSpec, "description", null);
      }
    }
  }

  switch (true) {
    case humidityForms.includes(data.formId):
      parameter = "humidity";
      break;
    case temperatureForms.includes(data.formId):
      parameter = "temperature";
      break;
    case pressureForms.includes(data.formId):
      parameter = "pressure";
      break;
    case conductivityForms.includes(data.formId):
      parameter = "conductivity";
      break;
    default:
      parameter = "N/A";
  }

  const readings =
    data?.reportAnswers
      ?.map((answer) => {
        const date = new Date(answer.dateCreated);
        const day = date.getDate();
        const formattedDate = date.toLocaleDateString("en-CA");
        return {
          ...answer.answer,
          day,
          date: formattedDate,
          dateCreated: date,
        };
      })
      ?.sort((a, b) => a.dateCreated - b.dateCreated) || [];

  const getAnswersForDay = (day, time) => {
    return readings
      .filter(
        (item) =>
          item.day === day &&
          (item.working_status === "NW" ||
            item.time?.toUpperCase() === time?.toUpperCase())
      )
      .map((item) =>
        item.working_status === "NW" ? "NW" : item.parameters_and_specification
      )
      .join(", ");
  };

  return (
    <TemplateContainer>
      <MainTable>
        <thead>
          <tr>
            <th colSpan="13" rowSpan="4" style={{ textAlign: "center" }}>
              Parameter
            </th>
            <th colSpan="12" rowSpan="4" style={{ textAlign: "center" }}>
              Specification
            </th>
            <th
              colSpan="31"
              rowSpan="1"
              style={{ textAlign: "center", backgroundColor: "#e7e7e7" }}
            >
              Date
            </th>
          </tr>
          <tr>
            {/* <th colSpan="31" rowSpan="1" style={{ textAlign: "center", backgroundColor: "#e7e7e7" }}> */}
            {/* Blank row for custom input */}
            {/* </th> */}
          </tr>
          <tr>
            {daysInMonth.map((day) => (
              <th key={day} style={{ textAlign: "center" }}>
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan="13" style={{ textAlign: "center" }}>
              <span style={{ textTransform: "capitalize" }}>{parameter}</span> (AM)
            </th>
            <th colSpan="12" style={{ textAlign: "center" }}>
              {specification}
            </th>
            {daysInMonth.map((day) => (
              <td key={day}>
                <span style={{ fontSize: "x-small" }}>
                  {getAnswersForDay(day, "AM")}
                </span>
              </td>
            ))}
          </tr>
          <tr>
            <th colSpan="13" style={{ textAlign: "center" }}>
              <span style={{ textTransform: "capitalize" }}>{parameter}</span> (PM)
            </th>
            <th colSpan="12" style={{ textAlign: "center" }}>
              {specification}
            </th>
            {daysInMonth.map((day) => (
              <td key={day}>
                <span style={{ fontSize: "x-small" }}>
                  {getAnswersForDay(day, "PM")}
                </span>
              </td>
            ))}
          </tr>
          <tr>
            <th colSpan="56"></th>
          </tr>
          <tr>
            <th colSpan="16" style={{ textAlign: "center" }}>
              Date
            </th>
            <th colSpan="0" style={{ textAlign: "center" }}>
              Time
            </th>
            <th colSpan="28" style={{ textAlign: "center" }}>
              Problem Details
            </th>
            <th colSpan="12" style={{ textAlign: "center" }}>
              Action
            </th>
          </tr>
          {readings
            ?.filter(
              (reading) =>
                reading.problem &&
                reading.problem.trim() !== "" &&
                reading.problem !== "N/A"
            )
            .map((reading, idx) => (
              <tr key={idx}>
                <td colSpan="16" style={{ textAlign: "center" }}>
                  {reading.date}
                </td>
                <td colSpan="0" style={{ textAlign: "center" }}>
                  {reading.working_status === "NW"
                    ? "NW"
                    : reading.time?.toUpperCase()}
                </td>
                <td colSpan="28" style={{ textAlign: "center" }}>
                  {reading.problem}
                </td>
                <td colSpan="12" style={{ textAlign: "center" }}>
                  {reading.action_taken}
                </td>
              </tr>
            ))}
        </tbody>
      </MainTable>
    </TemplateContainer>
  );
};

export const TemplateTwo = ({ reportData }) => {
  return <TemplateTwoComponent data={reportData} />;
};
