import { useParams } from "react-router-dom";
import { MainView } from "../../../components";
import { FormSubmitAnswerContextProvider } from "../../../context";
import { FormSubmitNewAnswer } from "./form-submit-new-answer/FormSubmitNewAnswer";


export const FormSubmitAnswer = () => {
    const { formDesignId, formAnswerId } = useParams();
  
    return (
      <MainView>
        <FormSubmitAnswerContextProvider
          formDesignId={formDesignId}
        >
          <FormSubmitNewAnswer />
        </FormSubmitAnswerContextProvider>
      </MainView>
    );
  };
  