import React from 'react';
import styled from 'styled-components';

const TemplateContainer = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  overflow-x: auto;
`;

const MainTable = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  font-size: 12px;

  th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }

  &.highlight th {
    background-color: yellow;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 8px;
    th, td {
      padding: 4px;
    }
  }

  @media (max-width: 480px) {
    font-size: 6px;
    th, td {
      padding: 2px;
    }
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    tbody tr td {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const SecondaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;

  th, td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 8px;
    th, td {
      padding: 4px;
    }
  }

  @media (max-width: 480px) {
    font-size: 6px;
    th, td {
      padding: 2px;
    }
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    tbody tr td {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const isValidISODate = (dateString) => {
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
  return isoDatePattern.test(dateString);
};

const formatDateString = (dateString) => {
  if (isValidISODate(dateString)) {
    return dateString; 
  }
  const date = new Date(dateString);
  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  return formattedDate;
};

export const Template = ({ data }) => {
  const timeSlots = ["7:50", "10:00", "12:00", "2:00", "4:00", "OT"];

  // Group readings by date
  const groupedReadings = data?.reportAnswers?.reduce((acc, answer) => {
    const formattedDate = formatDateString(answer.dateCreated.split('T')[0]);
    const { working_status, left_side_pressure_value, right_side_pressure_value, temperature_value, tank, time, problem, action_taken } = answer.answer;

    const normalizedTime = time ? time.toUpperCase() : 'N/A';

    if (!acc[formattedDate]) {
      acc[formattedDate] = {};
    }

    acc[formattedDate][normalizedTime] = {
      working_status,
      left_side_pressure_value: working_status === 'NW' ? '' : left_side_pressure_value,
      right_side_pressure_value: working_status === 'NW' ? '' : right_side_pressure_value,
      temperature_value: working_status === 'NW' ? '' : temperature_value,
      tank: working_status === 'NW' ? '' : tank,
      problem,
      action_taken
    };

    return acc;
  }, {}) || {};

  console.log("TRACE GROUPED READINGS", groupedReadings);

  return (
    <TemplateContainer>
      <MainTable>
        <thead>
          <tr>
            <th rowSpan="2">Parameter and Date</th>
            <th colSpan="6">Left side</th>
            <th colSpan="6">Right Side</th>
            <th colSpan="6" className="highlight">Temperature</th>
            <th colSpan="6">Tank Level/Flowrate/Di Supply</th>
          </tr>
          <tr>
            {Array(4).fill().map((_, idx) => (
              <React.Fragment key={idx}>
                {timeSlots.map((time, timeIdx) => (
                  <th key={`time-${idx}-${timeIdx}`}>{time}</th>
                ))}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(groupedReadings)?.map(([date, readings], idx) => (
            <React.Fragment key={idx}>
              <tr>
                <td rowSpan="1">{date}</td>
                {timeSlots.map((time, timeIdx) => (
                  <td key={`left-${idx}-${timeIdx}`}>{readings[time]?.left_side_pressure_value || ''}</td>
                ))}
                {timeSlots.map((time, timeIdx) => (
                  <td key={`right-${idx}-${timeIdx}`}>{readings[time]?.right_side_pressure_value || ''}</td>
                ))}
                {timeSlots.map((time, timeIdx) => (
                  <td key={`temperature-${idx}-${timeIdx}`}>{readings[time]?.temperature_value || ''}</td>
                ))}
                {timeSlots.map((time, timeIdx) => (
                  <td key={`tank-${idx}-${timeIdx}`} style={{ textTransform: "uppercase" }}>{readings[time]?.tank || ''}</td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </MainTable>
      <SecondaryTable>
        <thead>
          <tr>
            <th>Problem Details</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(groupedReadings)?.flatMap(([date, readings], idx) =>
            Object.entries(readings).filter(([_, reading]) =>
              reading.problem && reading.problem.trim() !== '' && reading.problem !== 'N/A'
            ).map(([time, reading], subIdx) => (
              <tr key={`${idx}-${subIdx}`} className={reading.working_status === 'NW' ? 'highlight-nw' : ''}>
                <td>{reading.problem}</td>
                <td>{reading.action_taken}</td>
              </tr>
            ))
          )}
        </tbody>
      </SecondaryTable>
    </TemplateContainer>
  );
};

export const TemplateOne = ({ reportData }) => {
  return (
    <Template data={reportData} />
  );
};
