import { Grid, IconButton, Modal, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useAlert, useForm } from "../../../utilities";
import {
  CustomPdfModal,
  FormReportsTable,
  GenerateReportModal,
  PageComponent,
  PageSize,
  SearchField,
} from "../../../components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormReportContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { RESET_INITIAL_STATE, templateTwoForms } from "../../../constants";
import {
  generateCustomReport,
  generateReportAnswersToExcel,
} from "../../../api";
import { StandardButton, ButtonText } from "../../../components";
import { PdfTemplate } from "../../../components/pdf/PdfTemplate";
import useAxios from "../../../utilities/useAxios";

const PAINTING_PORTAL_SERVICE = `${process.env.REACT_APP_PAINTING_PORTAL_SERVICE}`;

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: "1%",
  padding: "1.5% 2%",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    padding: "1% 1% 0 1%",
  },
}));

const ReportTitle = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

const FirstColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(1),
    width: "100%",
    flexDirection: "column",
    alignItems: "stretch",
  },
  "& > *": {
    flex: "1 1 auto",
    minWidth: "100px",
  },
}));

const SecondColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
    gap: theme.spacing(0.5),
  },
}));

export const FormReport = (props) => {
  const { formName, formDesignId, isApproverRequired, isCustomReport } = props;

  const {
    formReportState,
    formReports,
    searchKeyword,
    reportsPageNumber,
    reportsPageSize,
    sort,
    optimizedSearchFormReports,
    mutateFormReports,
    dispatchFormReport,
    reportApproversData,
    actions: { generateReport, approveReport },
  } = useFormReportContext();

  const { handleAlertMessage, ...alertRest } = useAlert();
  const {
    formData: reportData,
    showModal: showReport,
    handleCloseModal: handleCloseReport,
    handleAddData: handleAddReport,
  } = useForm();

  const {
    formData: customReportData,
    showModal: showCustomReport,
    handleCloseModal: handleCloseCustomReport,
    handleDisplayData: handleDisplayCustomReport,
  } = useForm();

  let navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate("/reports");
  };

  useEffect(() => {
    if (formReports) {
      dispatchFormReport({ type: RESET_INITIAL_STATE });
    }
  }, [formReports]);

  const handleSuccess = () => {
    handleAlertMessage(formReportState.successMessage, "success");
    mutateFormReports();
    handleCloseReport();
    dispatchFormReport({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    handleAlertMessage(formReportState.errorMessage, "error");
    handleCloseReport();
    dispatchFormReport({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (formReportState.successMessage) {
      handleSuccess();
    } else if (formReportState.errorMessage) {
      handleError();
    }
  }, [formReportState.success, formReportState.error]);

  const handleChangePage = (pageNumber) => {
    optimizedSearchFormReports(
      searchKeyword,
      pageNumber,
      reportsPageSize,
      sort
    );
  };

  const handleApproveReport = (values) => {
    approveReport(values);
  };

  const handleSearchKeyword = (keyword) => {
    optimizedSearchFormReports(keyword, 1, reportsPageSize, sort);
  };

  const handleChangePageSize = (pageSize) => {
    optimizedSearchFormReports(searchKeyword, 1, pageSize, sort);
  };

  const handleSubmit = (values) => {
    generateReport(values, formDesignId, true);
  };

  const handleDownloadExcel = (row) => {
    const excelName = `Form_Answers_${formName}_${row.monthYear}.xlsx`;
    generateReportAnswersToExcel(row.id, excelName);
  };

  const handleDownloadCustomReport = (row) => {
    const pdfName = `${formName}_${row.monthYear}.pdf`;

    if (templateTwoForms.includes(row.formId)) {
      const fetchData = async () => {
        try {
          const response = await useAxios.get(
            `${PAINTING_PORTAL_SERVICE}/report/download-custom-report/${row.id}`
          );
          handleDisplayCustomReport(response?.data?.object);
        } catch (error) {
          handleAlertMessage("Failed to download Custom Report", "error");
          console.error("Error downloading Excel:", error);
        }
      };
      fetchData();
    } else {
      generateCustomReport(row.id, pdfName, handleAlertMessage, formName);
    }
  };

  return (
    <PageComponent
      showAlert={alertRest.showAlert}
      handleCloseAlert={alertRest.handleCloseAlert}
      alertSeverity={alertRest.alertSeverity}
      alertMessage={alertRest.alertMessage}
      isLoading={formReportState.loading}
    >
      <GridContainer>
        <ReportTitle item xs={12} gap={1}>
          <IconButton onClick={handleNavigateBack}>
            <ArrowBackIcon sx={{ color: "black" }} />
          </IconButton>
          <Typography fontSize="24px" fontWeight="bolder">
            {formName}
          </Typography>
        </ReportTitle>
      </GridContainer>
      <GridContainer container gap={1}>
        <FirstColumn item xs={12} sm={8} gap={2}>
          <SearchField search={handleSearchKeyword} keyword={searchKeyword} />
          <PageSize
            pageSizeValue={reportsPageSize}
            handleChange={handleChangePageSize}
          />
        </FirstColumn>
        <SecondColumn item xs={12} sm={3} gap={1}>
          <StandardButton onClick={handleAddReport}>
            <ButtonText>Generate Report</ButtonText>
          </StandardButton>
        </SecondColumn>
      </GridContainer>
      <GridContainer sx={{ padding: ".5% 2%" }}>
        <FormReportsTable
          data={formReports?.content ?? []}
          formsPageNumber={reportsPageNumber}
          pagination={formReports}
          loading={formReportState.loading}
          handleChangePage={handleChangePage}
          handleDownloadExcel={handleDownloadExcel}
          handleDownloadCustomReport={handleDownloadCustomReport}
          handleApproveReport={handleApproveReport}
          reportApproversData={reportApproversData}
          isCustomReport={isCustomReport}
        />
      </GridContainer>

      {showReport && (
        <GenerateReportModal
          open={showReport}
          onClose={handleCloseReport}
          handleSubmit={handleSubmit}
          values={reportData}
          handleAlertMessage={handleAlertMessage}
        />
      )}
      {customReportData && (
        <CustomPdfModal
          open={showCustomReport}
          onClose={() => {
            handleCloseCustomReport();
          }}
        >
          <PdfTemplate
            data={customReportData}
            onCancel={() => {
              handleCloseCustomReport();
            }}
          />
        </CustomPdfModal>
      )}
    </PageComponent>
  );
};
