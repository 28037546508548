import { Button, Typography, styled } from "@mui/material";

export const StandardButton = styled(Button)(({ theme, disabled }) => ({
  background: disabled ? theme.palette.common.lightGrey : "#1B9A92",
  color: "#fff",
  padding: "0.5rem 1rem",
  textTransform: "capitalize",
  whiteSpace: "nowrap", 
  overflow: "hidden",  
  "&:hover": {
    background: "#1B9A92",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "0.25rem 0.5rem",
    fontSize: "0.875rem",
  },
  [theme.breakpoints.up('md')]: {
    padding: "0.5rem 1rem",
    fontSize: "1rem",
  },
  [theme.breakpoints.up('lg')]: {
    padding: "0.75rem 1.5rem",
    fontSize: "1.125rem",
  },
}));

export const ButtonText = styled(Typography)(() => ({
  fontSize: "0.7rem",
}));
