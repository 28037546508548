/* eslint-disable no-unused-vars */
import "antd/dist/antd.less";
import React, { useEffect, useMemo, useState } from "react";
import { createForm } from "@formily/core";
import { createSchemaField } from "@formily/react";
import {
  Form,
  FormItem,
  DatePicker,
  Cascader,
  Editable,
  Input,
  NumberPicker,
  Switch,
  Password,
  PreviewText,
  Radio,
  Reset,
  Select,
  Space,
  Submit,
  TimePicker,
  Transfer,
  TreeSelect,
  FormGrid,
  FormLayout,
  FormTab,
  FormCollapse,
  ArrayTable,
  ArrayCards,
  FormButtonGroup,
} from "@formily/antd";
import { Card, Slider, Rate, Image } from "antd";
import { Checkbox, Text, Upload } from "../forms-builder/components";
import { MainView, MessageDialog, PageComponent } from "../../components";
import { Box, Typography } from "@mui/material";
import { useFormsViewContext, useGlobalContext } from "../../context";
import { fetchEmployees, fetchSections, useAlert } from "../../utilities";
import { RESET_INITIAL_STATE } from "../../constants";

const SchemaField = createSchemaField({
  components: {
    Space,
    FormGrid,
    FormLayout,
    FormTab,
    FormCollapse,
    ArrayTable,
    ArrayCards,
    FormItem,
    DatePicker,
    Checkbox,
    Cascader,
    Editable,
    Input,
    Image,
    Text,
    NumberPicker,
    Switch,
    Password,
    PreviewText,
    Radio,
    Reset,
    Select,
    Submit,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload,
    Card,
    Slider,
    Rate,
  },
  scope: {
    fetchEmployees: async (field) => {
      try {
        const options = await fetchEmployees();
        field.dataSource = options;
      } catch (error) {
        console.log(error);
      } finally {
        field.loading = false;
      }
    },
    fetchSections: async (field) => {
      try {
        const options = await fetchSections();
        field.dataSource = options;
      } catch (error) {
        console.log(error);
      } finally {
        field.loading = false;
      }
    },
  },
});

export const FormsView = () => {
  const userId = localStorage.getItem("id");
  const { formsView } = useGlobalContext();
  const {
    formsViewState,
    dispatchFormsView,
    actions: { submitFormAnswers },
    formDesignData,
  } = useFormsViewContext();

  const { handleAlertMessage, ...alertRest } = useAlert();
  const [showDialog, setDialog] = useState(false);

  const qrCodeValues =
    JSON.parse(localStorage.getItem("form_initial_answer")) ?? {};

  const form = useMemo(() => createForm(), []);

  if (Object.keys(qrCodeValues)?.length > 0 && formDesignData) {
    const initialValues = {};
    Object?.keys(formDesignData?.schema?.schema?.properties)?.forEach((key) => {
      initialValues[key] = qrCodeValues[key];
    });

    form.setInitialValues(initialValues);
  }

  const { form: formProps, schema } = formDesignData?.schema ?? {};

  const handleSuccess = () => {
    handleShowDialog();
    dispatchFormsView({ type: RESET_INITIAL_STATE });
  };

  const handleError = () => {
    handleAlertMessage(formsViewState.errorMessage, "error");
    dispatchFormsView({ type: RESET_INITIAL_STATE });
  };

  useEffect(() => {
    if (formsViewState.successMessage) {
      handleSuccess();
    } else if (formsViewState.errorMessage) {
      handleError();
    }
  }, [formsViewState.success, formsViewState.error]);

  const onFinish = (values) => {
    submitFormAnswers({
      formDesignId: formsView.formDesignId,
      userId,
      design: values,
    });
  };

  console.log("traceeeeeee", formsView);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleShowDialog = () => {
    setDialog(true);
  };

  const handleCloseDialog = () => {
    setDialog(false);
    window.location.reload(false);
  };

  return (
    <PageComponent
      showAlert={alertRest.showAlert}
      handleCloseAlert={alertRest.handleCloseAlert}
      alertSeverity={alertRest.alertSeverity}
      alertMessage={alertRest.alertMessage}
      isLoading={formsViewState.loading}
    >
      <MessageDialog
        open={showDialog}
        onClose={handleCloseDialog}
        title={formsView?.successHeader ?? "Success Title"}
        content={formsView?.successMessage ?? "Your Form Has been Submitted"}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          marginTop: "2%",
        }}
      >
        {schema != null ? (
          <Form
            {...formProps}
            form={form}
            onAutoSubmitFailed={onFinishFailed}
            onAutoSubmit={onFinish}
            style={{ flex: 1 }}
          >
            <SchemaField schema={schema} />
            <FormButtonGroup.FormItem>
              <Submit
                size="large"
                style={{
                  backgroundColor: "#1B9A92",
                  borderColor: "#1B9A92",
                  width: "100px",
                  marginTop: "2%",
                }}
              >
                Submit
              </Submit>
            </FormButtonGroup.FormItem>
          </Form>
        ) : (
          <Box>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Form is Currently Empty
              </Typography>
              <br></br>
              <Typography variant="body1">
              It looks like this form has no content. Please coordinate with the admin to create or edit the form in the Forms Builder app before proceeding.
              </Typography>
            </Box>
        )}
      </Box>
    </PageComponent>
  );
};
