import React from "react";
import { Grid, Card, CardContent, Typography, useMediaQuery, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { motion } from 'framer-motion';
import { theme } from "../../utilities";
import { handleFormSubmission, handleReportNavigation } from "../../utilities/formUtils";
import { useFetchFormsApprovalData, useFetchReportsApprovalData, useFetchLatestReportsData } from "../../utilities";

const styles = {
  firstTitleBar: {
    padding: "10px",
    marginBottom: "10px",
    textAlign: "left",
    background: "#34516F",
  },
  secondTitleBar: {
    padding: "10px",
    marginBottom: "10px",
    textAlign: "left",
    background: "#379f86",
  },
  thirdTitleBar: {
    padding: "10px",
    marginBottom: "10px",
    textAlign: "left",
    background: "#FAAA4C",
  },
  titleText: {
    fontWeight: "bold",
    color: "#FFF",
  },
  cardContent: {
    textAlign: "left",
    backgroundColor: "white",
    color: "#3D3D3D",
    height: "100%",
  },
  linkItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none",
    color: "#1B9A92",
    overflow: "hidden",
    cursor: "pointer",
    padding: "10px",
    borderRadius: "4px",
    transition: "background 0.3s",
    height: "20%",
  },
  linkItemHover: {
    backgroundColor: "#333",
    color: "#FFF",
  },
  truncateText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  card: {
    height: "250px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px", 
  },
  cardContentWrapper: {
    flex: 1,
    overflowY: "auto",
  },
  chevronIcon: {
    color: "black", 
    "&:hover": {
      color: "white"
    }
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeOut" } },
};

const textVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 0.5, duration: 0.5 } },
};

export const ApprovalSections = ({ formsApprovalData, reportsApprovalData, latestReportsData }) => {
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const getGridSize = () => {
    if (isSmallScreen) return 12;
    if (isMediumScreen) return 12;
    return 4;
  };

  const handleFormClick = (form) => {
    handleFormSubmission(form, navigate);
  };

  const handleReportClick = (report) => {
    handleReportNavigation(report, navigate);
  };

  const renderContent = (data, handleClick) => {
    // if (!data) {
    //   return <CircularProgress />;
    // }

    if (data?.length === 0 || data === undefined) {
      return <Typography>No results found</Typography>;
    }

    return data.map((item, index) => (
      <motion.div
        initial="hidden"
        animate="visible"
        variants={textVariants}
        onClick={() => handleClick(item)}
        key={index}
        style={styles.linkItem}
        whileHover={{ backgroundColor: theme.palette.accent.secondary, color: "#FFF" }}
      >
        <Typography style={styles.truncateText}>{item.title}</Typography>
        <ChevronRight style={styles.chevronIcon} />
      </motion.div>
    ));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={getGridSize()} md={getGridSize()}>
        <motion.div initial="hidden" animate="visible" variants={cardVariants}>
          <Card style={styles.card}>
            <div style={styles.firstTitleBar}>
              <Typography variant="h6" style={styles.titleText}>Form Approval</Typography>
            </div>
            <CardContent style={{ ...styles.cardContent, ...styles.cardContentWrapper }}>
              {renderContent(formsApprovalData, handleFormClick)}
            </CardContent>
          </Card>
        </motion.div>
      </Grid>
      <Grid item xs={12} sm={getGridSize()} md={getGridSize()}>
        <motion.div initial="hidden" animate="visible" variants={cardVariants}>
          <Card style={styles.card}>
            <div style={styles.secondTitleBar}>
              <Typography variant="h6" style={styles.titleText}>Reports Approval</Typography>
            </div>
            <CardContent style={{ ...styles.cardContent, ...styles.cardContentWrapper }}>
              {renderContent(reportsApprovalData, handleReportClick)}
            </CardContent>
          </Card>
        </motion.div>
      </Grid>
      <Grid item xs={12} sm={getGridSize()} md={getGridSize()}>
        <motion.div initial="hidden" animate="visible" variants={cardVariants}>
          <Card style={styles.card}>
            <div style={styles.thirdTitleBar}>
              <Typography variant="h6" style={styles.titleText}>Latest Reports</Typography>
            </div>
            <CardContent style={{ ...styles.cardContent, ...styles.cardContentWrapper }}>
              {renderContent(latestReportsData, handleReportClick)}
            </CardContent>
          </Card>
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default ApprovalSections;
