import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Tooltip,
  Grid,
} from "@mui/material";
import { FormMaintenanceReportColumnSchema } from "../../constants";
import { CustomPagination } from "../pagination/CustomPagination";

import EditIcon from "@mui/icons-material/Edit";
import { theme } from "../../utilities";

const TableCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "left",
  },
  fontSize: "0.90rem",
  padding: "20px 20px",
  width: "auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  borderBottom: "1px solid #e0e0e0",
  "&:last-child": {
    width: "150px",
  },
  textTransform: "capitalize",
}));

const TableColumnCellStyled = styled(TableCell)(() => ({
  "&:first-child": {
    textAlign: "left",
  },
  fontSize: ".95rem",
  fontWeight: "bold",
  height: "5px",
  textTransform: "capitalize",
  padding: "20px 20px",
  width: "auto",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f7f7f7",
  "&:last-child": {
    width: "150px",
  },
}));

export const FormMaintenanceReportTable = ({
  data,
  pagination,
  handleChangePage,
  pageNumber,
  handleView,
  loading,
}) => {
  const handleNextPage = () => {
    handleChangePage(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    handleChangePage(pageNumber - 1);
  };

  const totalRange = () => {
    const start = data?.length > 0 ?  pagination?.pageable?.pageNumber * pagination?.size + 1 : 0;
    const end =data?.length > 0 ?  Math.min(
      pagination?.pageable?.pageNumber * pagination?.size +
        pagination?.numberOfElements,
      pagination?.totalElements
    ) : 0;
    const total = data?.length > 0 ?  pagination?.totalElements : 0;
    return { start, end, total };
  };

  const { start, end, total } = totalRange();

  return (
    <>
      <TableContainer
        sx={{
          height: "100%",
          width: "100",
          overflowX: "auto",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {FormMaintenanceReportColumnSchema.map((column) => (
                <TableColumnCellStyled key={column.id}>
                  {column.label}
                </TableColumnCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow>
                <TableCellStyled>{row.formName}</TableCellStyled>
                <TableCellStyled>{row.description}</TableCellStyled>
                <TableCellStyled>
                  {row.verifiedBy ? (
                    <Chip
                      key={index}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",
                        background: `${theme.palette.common.lightGrey}60`,
                      }}
                      label={row.verifiedBy.fullname}
                    />
                  ) : (
                    <Chip
                      key={index}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",

                        background: `${theme.palette.common.lightGrey}60`,
                      }}
                      label={"UNASSIGNED"}
                    />
                  )}
                </TableCellStyled>
                <TableCellStyled>
                  {row.confirmedBy ? (
                    <Chip
                      key={index}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",
                        background: `${theme.palette.common.lightGrey}60`,
                      }}
                      label={row.confirmedBy.fullname}
                    />
                  ) : (
                    <Chip
                      key={index}
                      sx={{
                        color: "#000",
                        fontWeight: "normal",

                        background: `${theme.palette.common.lightGrey}60`,
                      }}
                      label={"UNASSIGNED"}
                    />
                  )}
                </TableCellStyled>
                <TableCellStyled>
                  <Tooltip title="Update Report Approver" placement="top">
                    <IconButton
                      onClick={() => {
                        handleView(row);
                      }}
                    >
                      <EditIcon sx={{ color: "#444444", fontSize: "25px" }} />
                    </IconButton>
                  </Tooltip>
                </TableCellStyled>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {data?.length === 0 && (
          <Box marginTop={2}>
            <Typography textAlign={"center"}>No Data Available</Typography>
          </Box>
        )}
      </TableContainer>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="0px 20px"
        backgroundColor="#ffffff"
        width="100%"
        borderRadius="2px"
        // margin="0% 3%"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <CustomPagination
              pageNumber={pageNumber || 1}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              disableFirst={pagination?.first}
              disableNext={pagination?.last}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "5px",
            }}
          >
            <Typography style={{ color: "#8E8E8E" }}>
              Showing {start} to {end} of {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
