import { useFormEditAnswerContext } from "../../../../context";
import "antd/dist/antd.less";
import React, { useEffect, useMemo, useState } from "react";
import { createForm } from "@formily/core";
import { createSchemaField } from "@formily/react";
import {
  Form,
  FormItem,
  DatePicker,
  Cascader,
  Editable,
  Input,
  NumberPicker,
  Switch,
  Password,
  PreviewText,
  Radio,
  Reset,
  Select,
  Space,
  Submit,
  TimePicker,
  Transfer,
  TreeSelect,
  FormGrid,
  FormLayout,
  FormTab,
  FormCollapse,
  ArrayTable,
  ArrayCards,
  FormButtonGroup,
} from "@formily/antd";
import { Card, Slider, Rate, Image } from "antd";
import { Checkbox, Text, Upload } from "../../../forms-builder/components";
import { fetchEmployees, fetchSections } from "../../../../utilities";
import { MessageDialog, PageComponent } from "../../../../components";
import { Box, Typography } from "@mui/material";
import { updateFormAnswer } from "../../../../api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const SchemaField = createSchemaField({
  components: {
    Space,
    FormGrid,
    FormLayout,
    FormTab,
    FormCollapse,
    ArrayTable,
    ArrayCards,
    FormItem,
    DatePicker,
    Checkbox,
    Cascader,
    Editable,
    Input,
    Image,
    Text,
    NumberPicker,
    Switch,
    Password,
    PreviewText,
    Radio,
    Reset,
    Select,
    Submit,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload,
    Card,
    Slider,
    Rate,
  },
  scope: {
    fetchEmployees: async (field) => {
      try {
        const options = await fetchEmployees();
        field.dataSource = options;
      } catch (error) {
        console.log(error);
      } finally {
        field.loading = false;
      }
    },
    fetchSections: async (field) => {
      try {
        const options = await fetchSections();
        field.dataSource = options;
      } catch (error) {
        console.log(error);
      } finally {
        field.loading = false;
      }
    },
  },
});

export const FormViewAnswer = () => {
  const form = useMemo(() => createForm(), []);
  const [showDialog, setShowDialog] = useState(false); // State to control dialog visibility
  const { formSchema, formDesignData, formAnswer, mutateFormAnswer } =
    useFormEditAnswerContext();
  const handleGoBack = () => {
    window.history.back();
  };
  const formName = formDesignData?.form?.name;

  const formProps = formDesignData?.schema?.form;

  useEffect(() => {
    if (formAnswer?.answer != null) {
      form.setInitialValues(formAnswer?.answer);
    }
  }, [formAnswer]);
  const handleSuccess = () => {
    setShowDialog(true); // Show dialog on success
  };

  const handleError = () => {};

  const onFinish = async (values) => {
    const userId = localStorage.getItem("id");
    try {
      const payload = {
        formDesignId: formDesignData.id,
        userId,
        answer: values,
        dateCreated: "",
      };
      await updateFormAnswer(formAnswer?.id, payload);
      handleSuccess();
      mutateFormAnswer();
    } catch (error) {
      handleError();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    window.location.reload(false);
  };

  return (
    <PageComponent isLoading={false}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          marginTop: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "150px",
            marginBottom: "75px",
          }}
        >
          <ArrowBackIcon onClick={handleGoBack} style={{ cursor: "pointer" }} />

          <Typography
            variant="h5"
            style={{
              marginLeft: "10px",
              marginBottom: "0px",
              fontWeight: "bold",
            }}
          >
            {formName}{" "}
          </Typography>
        </div>

        {formSchema != null ? (
          <Form
            {...formProps}
            form={form}
            onAutoSubmitFailed={onFinishFailed}
            onAutoSubmit={onFinish}
            style={{ flex: 1 }}
          >
            <SchemaField schema={formSchema} />

            <FormButtonGroup.FormItem>
              <Submit
                size="large"
                style={{
                  backgroundColor: "#1B9A92",
                  borderColor: "#1B9A92",
                  width: "100px",
                  marginTop: "2%",
                }}
              >
                Submit
              </Submit>
            </FormButtonGroup.FormItem>
          </Form>
        ) : (
          <Box>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Form is Currently Empty
            </Typography>
            <br></br>
            <Typography variant="body1">
              It looks like this form has no content. Please coordinate with the
              admin to create or edit the form in the Forms Builder app before
              proceeding.
            </Typography>
          </Box>
        )}
      </Box>
      <MessageDialog
        open={showDialog}
        onClose={handleCloseDialog}
        title="Submission has been Updated"
        // title= {formDesignData?.form?.sucessHeader ?? "Success Title"}
        // content= {formDesignData?.form?.successMessage ?? "Success Title"}
      ></MessageDialog>
    </PageComponent>
  );
};
