import React from 'react';
import styled from 'styled-components';

const TemplateContainer = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  overflow-x: auto; 
`;

const MainTable = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  font-size: 12px; 
  table-layout: fixed;

  th {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    background-color: #f2f2f2;
    font-weight: bold;
  }

  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    font-weight: normal;
  }

  &.highlight th {
    background-color: yellow;
  }

  @media (max-width: 1200px) {
    font-size: 10px;  
  }

  @media (max-width: 768px) {
    font-size: 8px;  
    th, td {
      padding: 4px; 
    }
  }

  @media (max-width: 480px) {
    font-size: 6px;  
    th, td {
      padding: 2px;  
    }
    thead {
      display: none;  
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    tbody tr td {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const transformData = (reportAnswers) => {
  const transformedData = {};

  reportAnswers.forEach(entry => {
    const { answer, dateCreated } = entry;
    const date = new Date(dateCreated);
    const day = date.getUTCDate();
    const time = answer.check_frequency; // Time from check_frequency field
    const dateStr = date.toISOString().split('T')[0]; 
    const timeStr = date.toTimeString().split(' ')[0];
    const key = answer.number;

    // Initialize the key if it doesn't exist
    if (!transformedData[key]) {
      transformedData[key] = {
        answers: {
          standard: answer.standard,
          method: answer.method,
          check_item: answer.check_item,
          problem: answer.problem,
          action_taken: answer.action_taken,
          date_created: dateStr,
          time_created: timeStr
        },
        daily_value: {}
      };
    }

    // Initialize the time slot if not already present
    if (!transformedData[key].daily_value[day]) {
      transformedData[key].daily_value[day] = {
        time_value: {},
        num_working: 0
      };
    }

    // Set the time_value based on check_frequency
    transformedData[key].daily_value[day].time_value[time] = {
      action_taken: answer.action_taken,
      value: answer.check_results,
      remarks: "null"
    };

    // Update num_working
    transformedData[key].daily_value[day].num_working += answer.working_status === "W" ? 1 : 0;
  });

  return transformedData;
};

export const TemplateThreeV5 = ({ reportData }) => {
  const structuredData = transformData(reportData.reportAnswers);

  console.log("template v5 structure ", structuredData)

  return (
    <TemplateContainer>
      <MainTable>
        <thead>
          <tr>
            <th colSpan="6" rowSpan="3" style={{ textAlign: 'center' }}>Air Balancer</th>
            <th colSpan="7" rowSpan="3" style={{ textAlign: 'center' }}>Area</th>
            <th colSpan="7" rowSpan="3" style={{ textAlign: 'center' }}>Standard</th>
            <th colSpan="5" rowSpan="3" style={{ textAlign: 'center' }}>Method</th>
            <th colSpan="5" rowSpan="3" style={{ textAlign: 'center' }}>Time</th>
          </tr>
          <tr>
            <th colSpan="62" rowSpan="1" style={{ textAlign: 'center', backgroundColor: '#e7e7e7' }}>
              Date
            </th>
          </tr>
          <tr>
            {[...Array(31)].map((_, i) => (
              <th key={i} colSpan="2" style={{ textAlign: 'center' }}>{i + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(structuredData).map(([key, entry]) => (
            <React.Fragment key={key}>
              <tr>
                <th
                  colSpan="6"
                  rowSpan="2"
                  style={{ textAlign: 'center', textTransform: 'capitalize' }}
                >
                  {key}
                </th>
                <th
                  colSpan="7"
                  rowSpan="2"
                  style={{ textAlign: 'center' }}
                >
                  {entry.answers.check_item}
                </th>
                <th
                  colSpan="7"
                  rowSpan="2"
                  style={{ textAlign: 'center' }}
                >
                  {entry.answers.standard}
                </th>
                <th
                  colSpan="5"
                  rowSpan="2"
                  style={{ textAlign: 'center' }}
                >
                  {entry.answers.method}
                </th>
                <th colSpan="5" rowSpan="1" style={{ textAlign: 'center' }}>8:00</th>
                {[...Array(31)].map((_, day) => (
                  <td key={day} colSpan={"2"} style={{ textAlign: "start", textTransform: "uppercase" }}>
                    {entry.daily_value[day + 1]?.time_value['8:00']?.value || ''}
                  </td>
                ))}
              </tr>
              <tr>
                <th colSpan="5" rowSpan="1" style={{ textAlign: 'center' }}>12:00</th>
                {[...Array(31)].map((_, day) => (
                  <td key={day} colSpan={"2"} style={{ textAlign: "start", textTransform: "uppercase" }}>
                    {entry.daily_value[day + 1]?.time_value['12:00']?.value || ''}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
          <tr>
            <th colSpan="8" style={{ textAlign: 'center' }}>Date</th>
            <th colSpan="8" style={{ textAlign: 'center' }}>Time</th>
            <th colSpan="38" style={{ textAlign: 'center' }}>Problem Details</th>
            <th colSpan="38" style={{ textAlign: 'center' }}>Action</th>
          </tr>
          {Object.entries(structuredData).map(([key, entry]) => (
            <tr>
              <td colSpan="8">{entry.answers.date_created}</td>
              <td colSpan="8">{entry.answers.time_created}</td>
              <td colSpan="38">{entry.answers.problem}</td>
              <td colSpan="38">{entry.answers.action_taken}</td>
            </tr>
          ))}
        </tbody>
      </MainTable>
    </TemplateContainer>
  );
};

//   const { structuredData } = transformData(reportData.reportAnswers);

//   return (
//     <TemplateContainer>
//       <MainTable>
//       <thead>
//             <tr>
//               <th colSpan="13" style={{ textAlign: 'right' }}>Area: Station A</th>
//               <th colSpan="11" style={{ textAlign: 'right' }}>Association Name: Just a test</th>
//               <th colSpan="12" style={{ textAlign: 'right' }}>Month/Year: September, 2024</th>
//               <th colSpan="10" style={{ textAlign: 'right' }}>Shift: 8:00 AM - 5:00 PM</th>
//               <th colSpan="10" style={{ textAlign: 'right' }}>Group: Sample Group</th>
//             </tr>
//             <tr>
//               <th colSpan="6" rowSpan="3" style={{ textAlign: 'center' }}>Air Balancer</th>
//               <th colSpan="7" rowSpan="3" style={{ textAlign: 'center' }}>Area</th>
//               <th colSpan="6" rowSpan="3" style={{ textAlign: 'center' }}>Standard</th>
//               <th colSpan="3" rowSpan="3" style={{ textAlign: 'center' }}>Method</th>
//               <th colSpan="3" rowSpan="3" style={{ textAlign: 'center' }}>Time</th>
//             </tr>
//             <tr>
//               <th colSpan="31" rowSpan="1" style={{ textAlign: 'center', backgroundColor: '#e7e7e7' }}>
//                 Date
//               </th>
//             </tr>
//             <tr>
//               {[...Array(31)].map((_, i) => (
//                 <th key={i} colSpan="1" style={{ textAlign: 'center' }}>{i + 1}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {structuredData.map((entry, index) => (
//               <React.Fragment key={index}>
//                 <tr>
//                   <th
//                     colSpan="6"
//                     rowSpan="2"
//                     style={{ textAlign: 'center', textTransform: 'capitalize' }}
//                   >
//                     {entry.key}
//                   </th>
//                   <th
//                     colSpan="7"
//                     rowSpan="2"
//                     style={{ textAlign: 'center' }}
//                   >
//                     {entry.value.answers.check_item}
//                   </th>
//                   <th
//                     colSpan="6"
//                     rowSpan="2"
//                     style={{ textAlign: 'center' }}
//                   >
//                     {entry.value.answers.standard}
//                   </th>
//                   <th
//                     colSpan="3"
//                     rowSpan="2"
//                     style={{ textAlign: 'center' }}
//                   >
//                     {entry.value.answers.method}
//                   </th>
//                   <th colSpan="3" rowSpan="1" style={{ textAlign: 'center' }}>8:00</th>
//                   {[...Array(31)].map((_, day) => (
//                     <td key={day}>
//                       {entry.value.daily_value[getFormattedDate(day + 1)]?.time_value['8:00']?.value || ''}
//                     </td>
//                   ))}
//                 </tr>
//                 <tr>
//                   <th colSpan="3" rowSpan="1" style={{ textAlign: 'center' }}>12:00</th>
//                   {[...Array(31)].map((_, day) => (
//                     <td key={day}>
//                       {entry.value.daily_value[getFormattedDate(day + 1)]?.time_value['12:00']?.value || ''}
//                     </td>
//                   ))}
//                 </tr>
//               </React.Fragment>
//             ))}
//             <tr>
//               <th colSpan="56" rowSpan="1">
//                 Illustration <br />
//                 <img
//                   src="https://staging.api.yamahamotorphilippines.com.ph/uploaded-files/png/Gx3DNO28ZUjhrNy.png"
//                   alt="Image 1"
//                   style={{ maxWidth: '100%', height: '80%' }}
//                 />
//               </th>
//             </tr>
//             <tr>
//               <th colSpan="56">Note:</th>
//             </tr>
//             <tr>
//               <th colSpan="6" style={{ textAlign: 'left' }}>Date</th>
//               <th colSpan="4" style={{ textAlign: 'center' }}>Time</th>
//               <th colSpan="11" style={{ textAlign: 'center' }}>Problem Details</th>
//               <th colSpan="11" style={{ textAlign: 'center' }}>Action</th>
//               <th colSpan="12" style={{ textAlign: 'center' }}>Conducted by:</th>
//               <th colSpan="12" style={{ textAlign: 'center' }}>Verified by:</th>
//             </tr>
//             {Object.entries(problemData).map(([key, value]) =>
//               value.map((tempThree, idx) => (
//                 <tr key={idx}>
//                   <td colSpan="6" style={{ textAlign: 'center' }}>
//                     {`${month} ${key}, ${year}`}
//                   </td>
//                   <td colSpan="4" style={{ textAlign: 'center' }}>{tempThree.time}</td>
//                   <td colSpan="11" style={{ textAlign: 'center' }}>{tempThree.problem}</td>
//                   <td colSpan="11" style={{ textAlign: 'center' }}>{tempThree.action}</td>
//                   <td colSpan="12" style={{ textAlign: 'center' }}>{tempThree.checkedBy}</td>
//                   <td colSpan="12" style={{ textAlign: 'center' }}>{tempThree.approvedBy}</td>
//                 </tr>
//               ))
//             )}
//           </tbody>
//       </MainTable>
//     </TemplateContainer>
//   );
// };


// import React from 'react';

// export const TemplateThreeV5 = ({
//   title,
//   formName,
//   verifiedBy,
//   confirmedBy,
//   preparedBy,
//   structuredData,
//   problemData,
//   month,
//   year,
// }) => {
//   const getFormattedDate = (day) => day < 10 ? `0${day}` : day;

//   return (
//     <div>
//       <head>
//         <meta charset="UTF-8" />
//         <style>
//           {`
//             @page {
//               size: 14in 8.5in;
//               margin: 0;
//             }
//             table {
//               border-collapse: collapse;
//               width: 100%;
//               table-layout: fixed;
//             }
//             th,
//             td {
//               padding: 5px;
//               border: 1px solid #ddd;
//               white-space: normal;
//               font-size: 11px;
//               font-weight: normal;
//               font-family: Arial, Helvetica, sans-serif;
//               text-align: left;
//             }
//           `}
//         </style>
//       </head>
//       <body>
//         <h3 style={{ textAlign: 'center' }}>Yamaha Motor Philippines, Inc</h3>
//         <table>
//           <thead>
//             <tr>
//               <th colSpan="13" style={{ textAlign: 'right' }}>Area: Station A</th>
//               <th colSpan="11" style={{ textAlign: 'right' }}>Association Name: Just a test</th>
//               <th colSpan="12" style={{ textAlign: 'right' }}>Month/Year: September, 2024</th>
//               <th colSpan="10" style={{ textAlign: 'right' }}>Shift: 8:00 AM - 5:00 PM</th>
//               <th colSpan="10" style={{ textAlign: 'right' }}>Group: Sample Group</th>
//             </tr>
//             <tr>
//               <th colSpan="6" rowSpan="3" style={{ textAlign: 'center' }}>Air Balancer</th>
//               <th colSpan="7" rowSpan="3" style={{ textAlign: 'center' }}>Area</th>
//               <th colSpan="6" rowSpan="3" style={{ textAlign: 'center' }}>Standard</th>
//               <th colSpan="3" rowSpan="3" style={{ textAlign: 'center' }}>Method</th>
//               <th colSpan="3" rowSpan="3" style={{ textAlign: 'center' }}>Time</th>
//             </tr>
//             <tr>
//               <th colSpan="31" rowSpan="1" style={{ textAlign: 'center', backgroundColor: '#e7e7e7' }}>
//                 Date
//               </th>
//             </tr>
//             <tr>
//               {[...Array(31)].map((_, i) => (
//                 <th key={i} colSpan="1" style={{ textAlign: 'center' }}>{i + 1}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {structuredData.map((entry, index) => (
//               <React.Fragment key={index}>
//                 <tr>
//                   <th
//                     colSpan="6"
//                     rowSpan="2"
//                     style={{ textAlign: 'center', textTransform: 'capitalize' }}
//                   >
//                     {entry.key}
//                   </th>
//                   <th
//                     colSpan="7"
//                     rowSpan="2"
//                     style={{ textAlign: 'center' }}
//                   >
//                     {entry.value.answers.check_item}
//                   </th>
//                   <th
//                     colSpan="6"
//                     rowSpan="2"
//                     style={{ textAlign: 'center' }}
//                   >
//                     {entry.value.answers.standard}
//                   </th>
//                   <th
//                     colSpan="3"
//                     rowSpan="2"
//                     style={{ textAlign: 'center' }}
//                   >
//                     {entry.value.answers.method}
//                   </th>
//                   <th colSpan="3" rowSpan="1" style={{ textAlign: 'center' }}>8:00</th>
//                   {[...Array(31)].map((_, day) => (
//                     <td key={day}>
//                       {entry.value.daily_value[getFormattedDate(day + 1)]?.time_value['8:00']?.value || ''}
//                     </td>
//                   ))}
//                 </tr>
//                 <tr>
//                   <th colSpan="3" rowSpan="1" style={{ textAlign: 'center' }}>12:00</th>
//                   {[...Array(31)].map((_, day) => (
//                     <td key={day}>
//                       {entry.value.daily_value[getFormattedDate(day + 1)]?.time_value['12:00']?.value || ''}
//                     </td>
//                   ))}
//                 </tr>
//               </React.Fragment>
//             ))}
//             <tr>
//               <th colSpan="56" rowSpan="1">
//                 Illustration <br />
//                 <img
//                   src="https://staging.api.yamahamotorphilippines.com.ph/uploaded-files/png/Gx3DNO28ZUjhrNy.png"
//                   alt="Image 1"
//                   style={{ maxWidth: '100%', height: '80%' }}
//                 />
//               </th>
//             </tr>
//             <tr>
//               <th colSpan="56">Note:</th>
//             </tr>
//             <tr>
//               <th colSpan="6" style={{ textAlign: 'left' }}>Date</th>
//               <th colSpan="4" style={{ textAlign: 'center' }}>Time</th>
//               <th colSpan="11" style={{ textAlign: 'center' }}>Problem Details</th>
//               <th colSpan="11" style={{ textAlign: 'center' }}>Action</th>
//               <th colSpan="12" style={{ textAlign: 'center' }}>Conducted by:</th>
//               <th colSpan="12" style={{ textAlign: 'center' }}>Verified by:</th>
//             </tr>
//             {Object.entries(problemData).map(([key, value]) =>
//               value.map((tempThree, idx) => (
//                 <tr key={idx}>
//                   <td colSpan="6" style={{ textAlign: 'center' }}>
//                     {`${month} ${key}, ${year}`}
//                   </td>
//                   <td colSpan="4" style={{ textAlign: 'center' }}>{tempThree.time}</td>
//                   <td colSpan="11" style={{ textAlign: 'center' }}>{tempThree.problem}</td>
//                   <td colSpan="11" style={{ textAlign: 'center' }}>{tempThree.action}</td>
//                   <td colSpan="12" style={{ textAlign: 'center' }}>{tempThree.checkedBy}</td>
//                   <td colSpan="12" style={{ textAlign: 'center' }}>{tempThree.approvedBy}</td>
//                 </tr>
//               ))
//             )}
//           </tbody>
//         </table>
//       </body>
//     </div>
//   );
// };