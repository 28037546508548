import React from "react";
import styled from "styled-components";

const TemplateContainer = styled.div`
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  overflow-x: auto;
`;

const MainTable = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  font-size: 12px;
  table-layout: fixed;

  th {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    background-color: #f2f2f2;
    font-weight: bold;
  }

  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
    font-weight: normal;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 768px) {
    font-size: 8px;
    th,
    td {
      padding: 4px;
    }
  }

  @media (max-width: 480px) {
    font-size: 6px;
    th,
    td {
      padding: 2px;
    }
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    tbody tr td {
      display: flex;
      justify-content: space-between;
    }
  }
`;

function transformData(reportAnswers) {
  const groupedData = {};

  reportAnswers?.forEach((report) => {
    const { answer, dateCreated } = report;
    const date = new Date(dateCreated);
    const day = String(date.getDate()).padStart(2, "0");
    const dateStr = date.toISOString().split("T")[0];
    const timeStr = date.toTimeString().split(" ")[0];

    const key = answer.check_item;

    if (!groupedData[key]) {
      groupedData[key] = {
        check_item: answer.check_item,
        standard: answer.standard,
        method: answer.method,
        daily_values: {},
        problems: [],
      };
    }

    if (!groupedData[key].daily_values[day]) {
      groupedData[key].daily_values[day] = {};
    }

    groupedData[key].daily_values[day][answer.time] = {
      value: answer.check_result ?? answer.input,
      action_taken: answer.action_taken,
      remarks: answer.remarks,
      checkedBy: "Unknown",
      approvedBy: "Unknown",
      time: answer.time,
    };

    groupedData[key].problems.push({
      dateCreated: dateStr,
      timeCreated: timeStr,
      problem: answer.problem,
      action_taken: answer.action_taken,
    });
  });

  return Object.values(groupedData);
}

const toProperCase = (str) => {
  if (typeof str !== "string") return "";
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const TemplateThreeV1 = ({ reportData }) => {
  const structuredData = transformData(reportData.reportAnswers);
  const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);

  const renderDailyValue = (dailyValues, time) => {
    return daysInMonth.map((day) => {
      const dayStr = String(day).padStart(2, "0");
      return (
        <td
          colSpan={"2"}
          key={day}
          style={{ textAlign: "center", textTransform: "uppercase" }}
        >
          {dailyValues[dayStr] && dailyValues[dayStr][time]
            ? dailyValues[dayStr][time].value
            : ""}
        </td>
      );
    });
  };

  const readings =
    reportData?.reportAnswers
      ?.map((answer) => {
        const date = new Date(answer.dateCreated);
        const day = date.getDate();
        const formattedDate = date.toLocaleDateString("en-CA");
        return {
          ...answer.answer,
          day,
          date: formattedDate,
          dateCreated: date,
        };
      })
      ?.sort((a, b) => a.dateCreated - b.dateCreated) || [];

  return (
    <TemplateContainer>
      <MainTable>
        <thead>
          <tr>
            <th colSpan={"10"} rowSpan="3" style={{ textAlign: "center" }}>
              Check Item
            </th>
            <th colSpan={"7"} rowSpan="3" style={{ textAlign: "center" }}>
              Standard
            </th>
            <th colSpan={"7"} rowSpan="3" style={{ textAlign: "center" }}>
              Method
            </th>
            <th colSpan={"10"} rowSpan="3" style={{ textAlign: "center" }}>
              Frequency of Checking
            </th>
          </tr>
          <tr>
            <th
              colSpan="62"
              style={{
                backgroundColor: "#e7e7e7",
              }}
            >
              Date
            </th>
          </tr>
          <tr>
            {daysInMonth.map((day) => (
              <th colSpan="2" key={day} style={{ textAlign: "center" }}>
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {structuredData.map((entry, index) => (
            <React.Fragment key={index}>
              <tr>
                <td colSpan="10" rowSpan="3" style={{ textAlign: "center" }}>
                  {toProperCase(entry.check_item)}
                </td>
                <td colSpan="7" rowSpan="3" style={{ textAlign: "center" }}>
                  {entry.standard}
                </td>
                <td colSpan="7" rowSpan="3" style={{ textAlign: "center" }}>
                  {entry.method}
                </td>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  8:00
                </td>
                {renderDailyValue(entry.daily_values, "08:00")}
              </tr>
              <tr>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  12:00
                </td>
                {renderDailyValue(entry.daily_values, "12:00")}
              </tr>
              <tr>
                <td colSpan="10" style={{ textAlign: "center" }}>
                  3:00
                </td>
                {renderDailyValue(entry.daily_values, "15:00")}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
        <tr>
          <th colSpan="96"></th>
        </tr>
        <tr>
          <th colSpan="20" style={{ textAlign: "center" }}>
            Date
          </th>
          <th colSpan="10" style={{ textAlign: "center" }}>
            Time
          </th>
          <th colSpan="40" style={{ textAlign: "center" }}>
            Problem Details
          </th>
          <th colSpan="26" style={{ textAlign: "center" }}>
            Action
          </th>
        </tr>
        {readings
          ?.filter(
            (reading) =>
              reading.problem &&
              reading.problem.trim() !== "" &&
              reading.problem !== "N/A"
          )
          .map((reading, idx) => (
            <tr key={idx}>
              <td colSpan="20" style={{ textAlign: "center" }}>
                {reading.date}
              </td>
              <td colSpan="10" style={{ textAlign: "center" }}>
                {reading.working_status === "NW"
                  ? "NW"
                  : reading.time?.toUpperCase()}
              </td>
              <td colSpan="40" style={{ textAlign: "center" }}>
                {reading.problem}
              </td>
              <td colSpan="26" style={{ textAlign: "center" }}>
                {reading.action_taken}
              </td>
            </tr>
          ))}
      </MainTable>
    </TemplateContainer>
  );
};
