import useSWR from "swr";
import useAxios from "./useAxios";
import { useState, useEffect, useCallback } from "react";

const FB_SERVICE = `${process.env.REACT_APP_FORMS_BUILDER_SERVICE}`;
const PAINTING_SERVICE = `${process.env.REACT_APP_PAINTING_PORTAL_SERVICE}`;

export const useFormsData = (url) => {
  const [formsData, setFormsData] = useState([]);
  const [formsPageNumber, setFormsPageNumber] = useState(1);
  const [formsPageSize, setFormsPageSize] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sort, setSorting] = useState("date_modified,desc");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`, {
        params: {
          page: formsPageNumber,
          size: formsPageSize,
          keyword: searchKeyword,
        },
      });
      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (keyword, pageNumber, pageSize, sorting) => {
    setFormsPageNumber(pageNumber);
    setFormsPageSize(pageSize);
    setSearchKeyword(keyword);
    setSorting(sorting);

    useAxios
      .get(`${FB_SERVICE}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          sort,
        },
      })
      .then((res) => res.data)
      .then((json) => setFormsData(json?.object ?? []));
  };

  const optimizedSearchForms = useCallback(debounce(handleChange), []);

  const { data: forms, mutate: mutateForms, error } = useSWR(`${url}`, fetcher);

  useEffect(() => {
    mutateForms();
  }, [formsPageNumber, formsPageSize, searchKeyword, sort]);

  return {
    formsData,
    forms,
    searchKeyword,
    formsPageNumber,
    formsPageSize,
    sort,
    optimizedSearchForms,
    mutateForms,
    error,
  };
};

export const useFormReportsData = (url, formDesignId) => {
  const [reportsData, setReportsData] = useState([]);
  const [reportsPageNumber, setReportsPageNumber] = useState(1);
  const [reportsPageSize, setReportsPageSize] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sort, setSorting] = useState("date_modified,desc");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${PAINTING_SERVICE}/${url}`, {
        params: {
          page: reportsPageNumber,
          size: reportsPageSize,
          keyword: searchKeyword,
          formDesignId: formDesignId,
          sort: sort,
        },
      });
      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (keyword, pageNumber, pageSize, sorting) => {
    setReportsPageNumber(pageNumber);
    setReportsPageSize(pageSize);
    setSearchKeyword(keyword);
    setSorting(sorting);

    useAxios
      .get(`${PAINTING_SERVICE}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          formDesignId: formDesignId,
          sorting,
        },
      })
      .then((res) => res.data)
      .then((json) => setReportsData(json?.object ?? []));
  };

  const optimizedSearchFormReports = useCallback(debounce(handleChange), []);

  const {
    data: formReports,
    mutate: mutateFormReports,
    error,
  } = useSWR(`${url}`, fetcher);

  useEffect(() => {
    mutateFormReports();
  }, [reportsPageNumber, reportsPageSize, searchKeyword, sort]);

  return {
    reportsData,
    formReports,
    searchKeyword,
    reportsPageNumber,
    reportsPageSize,
    sort,
    optimizedSearchFormReports,
    mutateFormReports,
    error,
  };
};

export const useFormReportApprover = (url, formDesignId) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${PAINTING_SERVICE}/${url}`, {
        params: {
          formDesignId: formDesignId,
        },
      });
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: reportApproversData } = useSWR(url, fetcher);

  return { reportApproversData };
};

export const useFormSubmissionsData = (url, formVersion, designId) => {
  const [keyword, setKeyword] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [version, setVersion] = useState(formVersion);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formDesignId, setFormDesignId] = useState(designId);
  const [formAnswerStatus, setFormAnswerStatus] = useState("ALL");

  const [filterSubmission, setFilterSubmission] = useState({});

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`, {
        params: {
          keyword,
          page: pageNumber,
          size: pageSize,
          formDesignId,
          sort: "",
          startDate,
          endDate,
          version,
          formAnswerStatus: formAnswerStatus,
          ...filterSubmission,
        },
      });
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (
    keyword,
    pageSize,
    pageNumber,
    filterSubmission,
    startDate,
    endDate,
    formDesignId,
    status
  ) => {
    setKeyword(keyword);
    setPageSize(pageSize);
    setPageNumber(pageNumber);
    setVersion(version);
    setFilterSubmission(filterSubmission);
    setStartDate(startDate);
    setEndDate(endDate);
    setFormDesignId(formDesignId);
    setFormAnswerStatus(status);
    useAxios
      .get(`${FB_SERVICE}/${url}`, {
        params: {
          keyword,
          page: pageNumber,
          size: pageSize,
          formDesignId,
          sort: "",
          startDate,
          endDate,
          version,
          formAnswerStatus: status,
          ...filterSubmission,
        },
      })
      .then((res) => res.data);
  };

  const optimizedSearchFormAnswers = useCallback(debounce(handleChange), []);

  const { data: formAnswersData, mutate: mutateFormAnswersData } = useSWR(
    url,
    fetcher,
    { revalidateOnFocus: false }
  );

  // useEffect(() => {
  //   mutateFormAnswersData();
  // }, [
  //   pageSize,
  //   pageNumber,
  //   version,
  //   keyword,
  //   filterSubmission,
  //   startDate,
  //   endDate,
  //   formDesignId,
  //   formAnswerStatus,
  // ]);

  return {
    formAnswersData,
    mutateFormAnswersData,
    optimizedSearchFormAnswers,
    pageNumber,
    pageSize,
    version,
    keyword,
    filterSubmission,
    startDate,
    endDate,
    formDesignId,
    formAnswerStatus,
  };
};

export const useFormVersionsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`, {
        params: {
          page: 0,
          size: 9999,
        },
      });
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: formVersionsData } = useSWR(url, fetcher);

  return { formVersionsData };
};

export const useFormDesignProperties = (url, id) => {
  const [formSchemaId, setDesignId] = useState(id);

  const fetcher = async () => {
    try {
      const response = await useAxios.get(
        `${FB_SERVICE}/${url}/${formSchemaId}`
      );
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (formDesignId) => {
    setDesignId(formDesignId);
    useAxios
      .get(`${FB_SERVICE}/${url}/${formDesignId}`)
      .then((res) => res.data);
  };

  const optimizedSearchFormProperties = useCallback(debounce(handleChange), []);

  const { data: formDesignProperties, mutate: mutateFormDesignProperties } =
    useSWR(url, fetcher);

  useEffect(() => {
    mutateFormDesignProperties();
  }, [formSchemaId]);

  return {
    formDesignProperties,
    mutateFormDesignProperties,
    optimizedSearchFormProperties,
    formSchemaId,
  };
};

export const useCheckFormAccess = (url, userId, formDesignId) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`, {
        params: {
          formDesignId,
          userId,
        },
      });
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: formAccessData } = useSWR(url, fetcher);

  return { formAccessData };
};

export const useCheckFormApprover = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: formApproverAccess } = useSWR(url, fetcher);

  return { formApproverAccess };
};

export const useFormDesign = (url, formDesignId) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`, {});
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: formDesignData } = useSWR(url, fetcher);

  const formSchema = formDesignData?.schema?.schema;
  for (const key in formSchema?.properties) {
    if (formSchema.properties[key]["x-component"] === "Upload") {
      formSchema.properties[key]["x-component-props"][
        "action"
      ] = `${FB_SERVICE}/files/upload/${formDesignId}`;
      formSchema.properties[key]["x-component-props"][
        "delete"
      ] = `${FB_SERVICE}/files/delete-file`;
    }
  }
  return { formSchema, formDesignData };
};

export const useFormAnswer = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`, {});
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: formAnswer, mutate: mutateFormAnswer } = useSWR(url, fetcher);

  console.log("FORM ANSWER HERE", formAnswer?.answer);
  return { formAnswer, mutateFormAnswer };
};

export const useUserMaintenanceData = (url) => {
  const [usersKeyword, setUsersKeyword] = useState("");
  const [usersPageNumber, setUsersPageNumber] = useState(1);
  const [usersPageSize, setUsersPageSize] = useState(10);
  const [userStatus, setUserStatus] = useState("ALL");
  const [dateCreated, setDateCreated] = useState("");
  const [sort] = useState("id");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`, {
        params: {
          page: usersPageNumber,
          size: usersPageSize,
          keyword: usersKeyword,
          statusFilter: userStatus,
          dateCreated: dateCreated,
          sort: sort,
        },
      });
      return response.data.object ?? [];
    } catch (e) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (keyword, pageNumber, pageSize, status, dateCreated) => {
    setUsersPageNumber(pageNumber);
    setUsersPageSize(pageSize);
    setUsersKeyword(keyword);
    setUserStatus(status);
    setDateCreated(dateCreated);

    useAxios
      .get(`${FB_SERVICE}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          statusFilter: status,
          dateCreated: dateCreated,
          sort: sort,
        },
      })
      .then((res) => res.data);
  };

  const optimizedSearchUsers = useCallback(debounce(handleChange), []);

  const {
    data: usersData,
    mutate: mutateUsers,
    error,
  } = useSWR(`${url}`, fetcher);

  useEffect(() => {
    mutateUsers();
  }, [
    usersPageNumber,
    usersPageSize,
    usersKeyword,
    userStatus,
    dateCreated,
    sort,
  ]);

  return {
    usersData,
    usersKeyword,
    usersPageNumber,
    usersPageSize,
    userStatus,
    dateCreated,
    sort,
    optimizedSearchUsers,
    mutateUsers,
  };
};

export const useFormsMaintenanceData = (url) => {
  const [formsPageNumber, setFormsPageNumber] = useState(1);
  const [formsPageSize, setFormsPageSize] = useState(10);
  const [formsKeyword, setFormsKeyword] = useState("");
  const [sort] = useState("date_modified,desc");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`, {
        params: {
          page: formsPageNumber,
          size: formsPageSize,
          keyword: formsKeyword,
        },
      });
      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (keyword, pageNumber, pageSize) => {
    setFormsPageNumber(pageNumber);
    setFormsPageSize(pageSize);
    setFormsKeyword(keyword);

    useAxios
      .get(`${FB_SERVICE}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
        },
      })
      .then((res) => res.data);
  };

  const optimizedSearchForms = useCallback(debounce(handleChange), []);

  const {
    data: formsData,
    mutate: mutateForms,
    error,
  } = useSWR(`${url}`, fetcher, { revalidateOnFocus: false });

  useEffect(() => {
    mutateForms();
  }, [formsPageNumber, formsPageSize, formsKeyword, sort]);

  return {
    formsData,
    formsKeyword,
    formsPageNumber,
    formsPageSize,
    sort,
    optimizedSearchForms,
    mutateForms,
  };
};

export const useUserCardsData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };
  const { data: userCardsData, mutate: mutateUserCardsData } = useSWR(
    url,
    fetcher
  );

  return { userCardsData, mutateUserCardsData };
};

export const useUserListData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };
  const { data: usersData } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
  });

  return { usersData };
};

export const useEmployeesData = (url) => {
  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${FB_SERVICE}/${url}`);
      return response?.data?.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const { data: employeesData } = useSWR(url, fetcher);

  return { employeesData };
};

export const useMaintenanceFormReportsData = (url) => {
  const [maintenanceReportsData, setMaintenanceReportsData] = useState([]);
  const [reportsPageNumber, setReportsPageNumber] = useState(1);
  const [reportsPageSize, setReportsPageSize] = useState(10);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sort, setSorting] = useState("date_modified,desc");

  const fetcher = async () => {
    try {
      const response = await useAxios.get(`${PAINTING_SERVICE}/${url}`, {
        params: {
          page: reportsPageNumber,
          size: reportsPageSize,
          keyword: searchKeyword,
        },
      });

      return response.data.object ?? [];
    } catch (error) {
      console.error(error);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleChange = (keyword, pageNumber, pageSize, sorting) => {
    setReportsPageNumber(pageNumber);
    setReportsPageSize(pageSize);
    setSearchKeyword(keyword);
    setSorting(sorting);

    useAxios
      .get(`${PAINTING_SERVICE}/${url}`, {
        params: {
          page: pageNumber,
          size: pageSize,
          keyword: keyword,
          sorting,
        },
      })
      .then((res) => res.data)
      .then((json) => setMaintenanceReportsData(json?.object ?? []));
  };

  const optimizedSearchFormReports = useCallback(debounce(handleChange), []);

  const {
    data: maintenanceFormReports,
    mutate: mutateMaintenanceFormReports,
    error,
  } = useSWR(`${url}`, fetcher, { revalidateOnFocus: false });

  useEffect(() => {
    mutateMaintenanceFormReports();
  }, [reportsPageNumber, reportsPageSize, searchKeyword, sort]);

  return {
    maintenanceReportsData,
    maintenanceFormReports,
    searchKeyword,
    reportsPageNumber,
    reportsPageSize,
    sort,
    optimizedSearchFormReports,
    mutateMaintenanceFormReports,
    error,
  };
};
