import { Formik } from "formik";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useRef } from "react";
import { ButtonText, MainDialog, StandardButton } from "../../../../components";
import { FormikTouched } from "../../../../utilities";
import { useGlobalContext } from "../../../../context";
import { UserValidationSchema } from "../../../../constants";

export const UserForm = ({
  innerRef,
  values,
  handleSubmit,
  handleAlertMessage,
  open,
  onClose,
  validateRef,
  setTouchedRef,
}) => {
  const { employeesData } = useGlobalContext();
  const employeeNameRef = useRef(null);
  const statusRef = useRef(null);

  const sortedEmployeeNames = employeesData
    ?.map((item) => item.fullname) 
    .sort((a, b) => a.localeCompare(b));

 const userId = parseInt(localStorage.getItem("userId"));  
  return (
    <Formik
      innerRef={innerRef}
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={UserValidationSchema}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        isValid,
        touched,
        setTouched,
        handleBlur,
        validateForm,
      }) => {
        if (validateRef) validateRef.current = validateForm;
        if (setTouchedRef) setTouchedRef.current = setTouched;

        return (
          <MainDialog
            title={values?.id ? "Update User " : "Create User"}
            showDialog={open}
            handleClose={onClose}
            widthType={"sm"}
          >
            <FormikTouched />
            <Grid container rowSpacing={1.8} paddingX={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  type="text"
                  variant="outlined"
                  id="employeeId"
                  name="employeeId"
                  value={
                    (values.employeeId =
                      employeesData
                        ?.find((item) => item.fullname === values.fullname)
                        ?.employeeId ?? "")
                  }
                  label="Employee Id"
                  size="small"
                  fullWidth
                  disabled
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="fullname"
                  name="fullname"
                  value={values?.fullname ?? ""}
                  options={sortedEmployeeNames}
                  onChange={(e, newValue) => {
                    setFieldValue("fullname", newValue);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return false;
                    }
                    return option === value;
                  }}
                  disabled={values?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      shrink
                      size="small"
                      ref={employeeNameRef}
                      required
                      helperText={touched.fullname && errors.fullname}
                      error={Boolean(errors.fullname && touched.fullname)}
                      onBlur={handleBlur}
                      label="Employee Name"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  id="status"
                  name="status"
                  disableClearable
                  value={
                    values.id
                      ? values.status === 1
                        ? "ACTIVE"
                        : "INACTIVE"
                      : values.status ?? ""
                  }
                  onChange={(e, newValue) => {
                    setFieldValue("status", newValue);
                  }}
                  options={["ACTIVE", "INACTIVE"]}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") {
                      return false;
                    }
                    return option === value;
                  }}
                  disabled={values.employeeId === userId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      ref={statusRef}
                      required
                      helperText={touched.status && errors.status}
                      error={Boolean(errors.status && touched.status)}
                      onBlur={handleBlur}
                      label="Status"
                    />
                  )}
                />
              </Grid>

              <Grid container marginTop={"3%"} justifyContent="flex-end">
                <StandardButton
                  type="submit"
                  size="small"
                  onClick={(e) => {
                    isValid
                      ? handleSubmit(values)
                      : handleAlertMessage(
                          "Please resolve all fields",
                          "error"
                        );
                  }}
                >
                 <ButtonText>{values?.id ? "Update" : "Submit"}</ButtonText> 
                </StandardButton>
              </Grid>
            </Grid>
          </MainDialog>
        );
      }}
    </Formik>
  );
};
