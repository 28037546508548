import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { useFormsData } from "../utilities";
import {
  reportsActions,
  reportsInitialState,
  reportsReducer,
} from "../reducer";
import { RESET_INITIAL_STATE } from "../constants";

const ReportsContext = createContext();

export const useReportsContext = () => {
  return useContext(ReportsContext);
};

export const ReportsContexProvider = ({ children }) => {
  const {
    forms,
    searchKeyword,
    formsPageNumber,
    formsPageSize,
    sort,
    optimizedSearchForms,
    mutateForms,
  } = useFormsData("painting-portal/search-forms?");

  const [reportState, dispatchReport] = useReducer(reportsReducer, {
    ...reportsInitialState,
  });

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    Object.keys(reportsActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          reportsActions[key](...args)(dispatchReport, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    mutateForms();
    dispatchReport({type: RESET_INITIAL_STATE})
  }, [
    searchKeyword,
    formsPageNumber,
    formsPageSize,
    sort,
    optimizedSearchForms,
  ]);

  useEffect(() => {
    stateRef.current = reportState;
  }, [reportState]);

  const value = useMemo(
    () => ({
      forms,
      searchKeyword,
      formsPageNumber,
      formsPageSize,
      sort,
      optimizedSearchForms,
      mutateForms,
      actions,
      reportState,
      dispatchReport,
    }),
    [
      forms,
      searchKeyword,
      formsPageNumber,
      formsPageSize,
      sort,
      optimizedSearchForms,
      mutateForms,
      actions,
      reportState,
      dispatchReport,
    ]
  );

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
};
