import React from "react";
import { Grid, Typography, styled } from "@mui/material";

const IndicatorContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const IndicatorBar = styled(Grid)(({ theme, color }) => ({
  position: "relative",
  height: "130px",
  // width: "415px",
  width: "345px",
  background: color || "#34516F",
  borderRadius: "20px",
  boxShadow: "0px 4px 4px 0px #00000040",
  margin: "0 10px",
}));

const IndicatorText = styled(Typography)(({ theme, color }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  fontSize: "38px",
  fontWeight: 500,
  lineHeight: "40px",
  letterSpacing: "0.01em",
  textAlign: "center",
  fontFamily: "Manrope",
  color: color || "#fff",
  whiteSpace: "nowrap", 
}));

const Indicator = ({ text, count, color }) => {
  return (
    <IndicatorContainer>
      <IndicatorBar color={color}>
        <IndicatorText>{`${count} ${text}`}</IndicatorText>
      </IndicatorBar>
    </IndicatorContainer>
  );
};

export default Indicator;
