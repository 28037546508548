import React from "react";
import { Modal, Box } from "@mui/material";

export const CustomPdfModal = ({ open, onClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiModal-paper": {
          width: "88%",
          maxWidth: "1000px",
          height: "100%",
          maxHeight: "800px", 
          margin: "auto",
          overflow: "auto",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // padding: 2,
        }}
      >
        <Box
          sx={{
            width: "95%",
            maxWidth: "95%",
            height: "100%",
            maxHeight: "100%",
            overflow: "auto",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingX: 2
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
