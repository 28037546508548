import { useParams } from "react-router-dom";
import { FormReport } from "./form-report/FormReport";
import { MainView } from "../../components";
import { FormReportContextProvider } from "../../context";

export const GenerateReport = () => {
  const { formDesignId, formName, isApproverRequired, isCustomReport } =
    useParams();
  const decodedFormName = decodeURIComponent(formName);
  return (
    <MainView>
      <FormReportContextProvider formDesignId={formDesignId}>
        <FormReport
          formName={decodedFormName}
          formDesignId={formDesignId}
          isApproverRequired={isApproverRequired}
          isCustomReport={isCustomReport}
        />
      </FormReportContextProvider>
    </MainView>
  );
};
