import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  formReportActions,
  formReportInitialState,
  formReportReducer,
} from "../reducer";
import { useFormReportApprover, useFormReportsData } from "../utilities";

const FormReportContext = createContext();

export const useFormReportContext = () => {
  return useContext(FormReportContext);
};

export const FormReportContextProvider = ({ children, formDesignId }) => {
  const {
    formReports,
    searchKeyword,
    reportsPageNumber,
    reportsPageSize,
    sort,
    optimizedSearchFormReports,
    mutateFormReports,
  } = useFormReportsData("report/search-reports?", formDesignId);

  const { reportApproversData } = useFormReportApprover(
    "report/check-report-approver?",
    formDesignId
  );

  const [formReportState, dispatchFormReport] = useReducer(formReportReducer, {
    ...formReportInitialState,
  });

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    Object.keys(formReportActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          formReportActions[key](...args)(dispatchFormReport, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = formReportState;
  }, [formReportState]);

  const value = useMemo(
    () => ({
      formReportState,
      actions,
      dispatchFormReport,
      formReports,
      searchKeyword,
      reportsPageNumber,
      reportsPageSize,
      sort,
      reportApproversData,
      optimizedSearchFormReports,
      mutateFormReports,
    }),
    [
      formReportState,
      actions,
      dispatchFormReport,
      formReports,
      searchKeyword,
      reportsPageNumber,
      reportsPageSize,
      sort,
      reportApproversData,
      optimizedSearchFormReports,
      mutateFormReports,
    ]
  );

  return (
    <FormReportContext.Provider value={value}>
      {children}
    </FormReportContext.Provider>
  );
};
