import useAxios from "../utilities/useAxios";

const FB_SERVICE = `${process.env.REACT_APP_FORMS_BUILDER_SERVICE}`;

export const deleteFile = async (fileName) => {
  return useAxios
    .delete(`${FB_SERVICE}/files/delete-file/${fileName}`)
    .catch((error) => console.error(error));
};

export const uploadFile = async (url, data) => {
  return await useAxios.post(url, data).catch((error) => console.error(error));
};

export const newDeleteFile = async (url, fileName) => {
  return await useAxios
    .delete(`${url}/${fileName}`)
    .catch((error) => console.error(error));
};
