import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { useUserCardsData, useUserMaintenanceData } from "../utilities";
import {
  userMaintenanceActions,
  userMaintenanceIntialState,
  userMaintenanceReducer,
} from "../reducer";
import { RESET_INITIAL_STATE, API_PENDING } from "../constants";

const UserMaintenanceContext = createContext();

export const useUserMaintenanceContext = () => {
  return useContext(UserMaintenanceContext);
};

export const UserMaintenanceContextProvider = ({ children }) => {
  const {
    usersData,
    usersKeyword,
    usersPageNumber,
    usersPageSize,
    mutateUsers,
    optimizedSearchUsers,
    userStatus,
    dateCreated,
  } = useUserMaintenanceData("painting-portal/search-painting-users");

  const { userCardsData, mutateUserCardsData } = useUserCardsData(
    "users/painting-user-cards"
  );

  useEffect(() => {
    mutateUsers();
    dispatchUsers({type: RESET_INITIAL_STATE})
  }, [
    usersData,
    usersKeyword,
    usersPageNumber,
    usersPageSize,
    userStatus,
    dateCreated,
  ]);

  const [usersState, dispatchUsers] = useReducer(userMaintenanceReducer, {
    ...userMaintenanceIntialState,
    pageUsers: usersData,
  });

  const [actions, setActions] = useState({});
  const stateRef = useRef();

  useEffect(() => {
    dispatchUsers({ type: API_PENDING });
    mutateUserCardsData().finally(() => {
      dispatchUsers({ type: RESET_INITIAL_STATE });
    });
  }, []);

  useEffect(() => {
    Object.keys(userMaintenanceActions).forEach((key) => {
      setActions((curr) => ({
        ...curr,
        [key]: (...args) => {
          userMaintenanceActions[key](...args)(dispatchUsers, stateRef.current);
        },
      }));
    });
  }, []);

  useEffect(() => {
    stateRef.current = usersState;
  }, [usersState]);

  const value = useMemo(
    () => ({
      usersData,
      usersPageNumber,
      usersPageSize,
      usersKeyword,
      usersState,
      dispatchUsers,
      optimizedSearchUsers,
      mutateUsers,
      actions,
      userCardsData,
      mutateUserCardsData,
      userStatus,
      dateCreated,
    }),
    [
      usersData,
      usersPageNumber,
      usersPageSize,
      usersKeyword,
      usersState,
      dispatchUsers,
      optimizedSearchUsers,
      mutateUsers,
      actions,
      userCardsData,
      mutateUserCardsData,
      userStatus,
      dateCreated,
    ]
  );

  return (
    <UserMaintenanceContext.Provider value={value}>
      {children}
    </UserMaintenanceContext.Provider>
  );
};
