import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, CssBaseline, Grid } from "@mui/material";
import { AppBar } from "../app-bar/AppBar";
import { Drawer } from "../drawer/Drawer";
import { useGlobalContext } from "../../context";

const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    BackgroundColor: "#e7ebee",
    minHeight: "100vh",
    flexGrow: 1,
    width: "80%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const StyledContent = styled(Grid)(() => ({
  padding: "5px 15px 5px 15px",
}));

export const MainView = (props) => {
  const { children } = props;
  const { open, handleDrawerOpen, handleDrawerClose } = useGlobalContext();

  return (
    <Box sx={{ display: "flex", backgroundColor: "#e7ebee" }}>
      <CssBaseline />
      <AppBar handleDrawerOpen={handleDrawerOpen} open={open} />
      <Drawer open={open} handleDrawerClose={handleDrawerClose} />
      <Main open={open}>
        <DrawerHeader />
        <StyledContent>{children}</StyledContent>
      </Main>
    </Box>
  );
};
