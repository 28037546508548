import { useEffect, useState } from "react";
import {
  ButtonText,
  CustomDateRangePicker,
  MainDialog,
  StandardButton,
} from "../../../../components";
import {
  Autocomplete,
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import dayjs from "dayjs";

export const FormAnswerFilter = ({
  open,
  onClose,
  componentSchema,
  filterValues,
  handleSubmitFilter,
  startDate,
  endDate,
  formAnswerStatus,
}) => {

  
  const handlefilterSchemaWithEnum = (schema) => {
    let filtered = [];

    Object.values(schema).forEach((item) => {
      if (item.enum) {
        filtered.push(item);
      }
      // If the item has properties (like nested components in a grid), check them recursively
      if (item.properties) {
        filtered = filtered.concat(handlefilterSchemaWithEnum(item.properties));
      }
    });

    return filtered;
  };

  const filteredSchema = handlefilterSchemaWithEnum(componentSchema);

  const [formValues, setFormValues] = useState(filterValues);
  const [newStartDate, setStartDate] = useState(startDate);
  const [newEndDate, setEndDate] = useState(endDate);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(formAnswerStatus);

  useEffect(() => {
    setFormValues(filterValues);
  }, [filterValues]);

  useEffect(() => {}, []);

  const handleInputChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleChangeStartDate = (date) => {
    const dateFormatted = date ? dayjs(date).format("MM/DD/YYYY") : null;
    setStartDate(dateFormatted);
    validateDates(dateFormatted, newEndDate);
  };

  const handleChangeEndDate = (date) => {
    const dateFormatted = date ? dayjs(date).format("MM/DD/YYYY") : null;
    setEndDate(dateFormatted);
    validateDates(newStartDate, dateFormatted);
  };

  const validateDates = (start, end) => {
    if (start && end && start > end) {
      setError("End date cannot be earlier than start date");
    } else {
      setError(null);
    }
  };

  const handleValidateBeforeSubmission = () => {
    if ((newStartDate && !newEndDate) || (!newStartDate && newEndDate)) {
      setError("Both start and end dates should have value");
    } else {
      handleSubmitFilter(formValues, newStartDate, newEndDate, status);
    }
  };

  const renderComponent = (item) => {
    switch (item["x-component"]) {
      case "Select":
        return (
          <>
            <FormLabel component="legend" style={{ color: "black" }}>
              {item.title}
            </FormLabel>
            <Autocomplete
              key={item.name}
              value={formValues[item.name]}
              options={item.enum.map((option) => option.value)}
              onChange={(event, value) => handleInputChange(item.name, value)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </>
        );
      case "Radio.Group":
        return (
          <FormControl key={item.name} component="fieldset">
            <FormLabel component="legend" style={{ color: "black" }}>
              {item.title}
            </FormLabel>
            <RadioGroup
              name={item.name}
              value={formValues[item.name] || ""}
              onChange={(event) =>
                handleInputChange(item.name, event.target.value)
              }
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {item.enum.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    style={{ flex: 1 }}
                  />
                ))}
              </div>
            </RadioGroup>
          </FormControl>
        );
      default:
        return null;
    }
  };

  return (
    <MainDialog
      title={"Filter Form Answer"}
      showDialog={open}
      handleClose={onClose}
      widthType={"xs"}
    >
      <Grid container gap={2} padding={2} justifyContent="center">
        <Grid item xs={12} md={12}>
          <CustomDateRangePicker
            error={error}
            startDate={newStartDate}
            endDate={newEndDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
        </Grid>
        {filteredSchema.map((item) => (
          <Grid item xs={12} md={12} key={item.name}>
            {renderComponent(item)}
          </Grid>
        ))}
        <Grid item xs={12} md={12}>
          <Autocomplete
            value={status}
            disableClearable={true}
            onChange={(e, newValue) => {
              setStatus(newValue);
            }}
            options={["ALL", "APPROVED", "UNAPPROVED"]}
            isOptionEqualToValue={(option, value) => {
              if (value === "") {
                return false;
              }
              return option === value;
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Status" />
            )}
          />
        </Grid>
        <Grid container margin={"3% 0% 0% 3%"} justifyContent="flex-end">
          <StandardButton
            onClick={() => {
              handleValidateBeforeSubmission();
            }}
            disabled={!!error}
          >
            <ButtonText>Submit</ButtonText>
          </StandardButton>
        </Grid>
      </Grid>
    </MainDialog>
  );
};
