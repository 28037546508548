import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ToolTipDialog = ({ data, open, handleClose }) => {

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Additional Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="description" marginBottom={"10%"}>
            {data.description}
          </DialogContentText>
          <DialogContentText>Date created: {moment(data.dateCreated).format('MMM DD, YYYY') }</DialogContentText>
          <DialogContentText>Date modified: {moment(data.dateModified).format('MMM DD, YYYY')}</DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}