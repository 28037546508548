import useAxios from "../utilities/useAxios";

const FB_SERVICE = `${process.env.REACT_APP_FORMS_BUILDER_SERVICE}`;

export const getExcelReport = async (
  keyword,
  version,
  pageSize,
  pageNumber,
  filterSubmission,
  startDate,
  endDate,
  formDesignId,
  formAnswerStatus
) => {
  try {
    const response = await useAxios.get(
      `${FB_SERVICE}/painting-portal/download-excel/`,
      {
        params: {
          keyword,
          version,
          page: pageNumber,
          size: pageSize,
          formDesignId,
          sort: "",
          startDate,
          endDate,
          formAnswerStatus: formAnswerStatus,
          ...filterSubmission,
        },
        responseType: "arraybuffer",
      }
    );

    // Create a Blob from the response data
    const blob = new Blob([response.data], {
      type: "application/vnd.ms-excel",
    });

    // Create a download link and trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = "Form_Answers_Report.xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export const updateFormAnswer = async (id, updatedData) => {
  console.log("TRACE payload", updatedData);
  return useAxios
    .put(`${FB_SERVICE}/painting-portal/edit-form-answer/${id}`, updatedData)
    .catch((error) => console.error(error));
};

export const approveFormAnswer = async (id, data) => {
  return useAxios
    .put(`${FB_SERVICE}/painting-portal/approve-form-answer/${id}`, data)
    .catch((error) => console.error(error));
};

export const submitAllFormAnswers = async (userId, data) => {
  return useAxios
    .put(`${FB_SERVICE}/painting-portal/approve-form-answers/${userId}`, data)
    .catch((error) => console.error(error));
};
